import * as React from "react"
import '../grid.css'
import { motion } from "framer-motion"

import { Link } from 'react-router-dom'
import { NavBar } from '../Components/NavBar'

import { Grid, Row, Col } from "react-flexbox-grid"
//adding support for IBM Plex Mono as a font.
import evalFile from '../Assets/Curology/Final_Intern_Evaluation.pdf'


//file imports
import Wordmark from '../Assets/Curology/curology-logo-naive.jpeg'


//all file imports
import EarlyHighlightsPrototype from '../Assets/Curology/Timeline/Prototype-animation-1.mov'
import FinalHighlightsPrototype from '../Assets/Curology/Timeline/PT-highlights-final-animation.mov'
import HighlightsFlow from '../Assets/Curology/Timeline/Highlights-flow-board.png'
import HighlightSpec from '../Assets/Curology/Timeline/Highlights-spec.png'
import HighlightsComponent from '../Assets/Curology/Timeline/Highlights-card-component.png'
//src/Assets/Curology/Timeline/PT/Provider-panel/OpenNav.png
import ProviderPanel from '../Assets/Curology/Timeline/PT/Provider-panel/OpenNav.png'
import CommentCard from '../Assets/Curology/Timeline/Cards/Questionnaire-card.png'
import UploadCard from '../Assets/Curology/Timeline/Cards/Survey.png'
import Accordion from '../Assets/Curology/Subscription-cadence/accordion-component-radiance.png'
import StandardInputs from '../Assets/Curology/Subscription-cadence/text-input-radiance.png'
import Iconography from '../Assets/Curology/Subscription-cadence/Icon-documentation-radiance.png'
import OptionButtons from '../Assets/Curology/Subscription-cadence/option-buttons-radiance.png'
import SegmentedControlComponent from '../Assets/Curology/Segmented-control.png'
import SegmentedPrototype from '../Assets/Curology/Segmented-control-prototype.mov'

import useAnalyticsEventTracker from '../useAnalyticsEventTracker';

import "fontsource-jost"

//adding CSS styles inline, as we do
const styles = {
    Page: { backgroundColor: "#27292D" },

    TopTitle: {
        fontFamily: `"IBM Plex Mono", monospace`,
        color: "rgba(255, 255, 255, 0.9)",
        fontSize: "1.25em",
        letterSpacing: "0.0001em",
        lineHeight: 1.2,
        fontWeight: 500,
        fontStyle: "normal",

    } as const,

    HeroDescription: {
        fontFamily: `"Inter", sans-serif`,
        color: "rgba(255, 255, 255, 0.9)",
        fontSize: "1.25em",
        letterSpacing: "0.00625em",
        lineHeight: 1.65,

    } as const,

    HeroDescriptionBold: {
        fontFamily: `"Inter", sans-serif`,
        color: "rgba(255, 255, 255, 0.9)",
        fontSize: "1.25em",
        letterSpacing: "0.00625em",
        lineHeight: 1.65,
        fontWeight: 700,

    } as const,

    HeroContainer: {
        width: 424,
        height: 74,
        marginTop: 64,
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-evenly",
        alignItems: "center",
        overflow: "visible",
        background: "#27292D",
    } as const,


    Style: {
        marginTop: 72,
        width: "100%",
    } as const,

    // styles for text and images, etc. 
    PhotoContainer: {
        maxWidth: "100%",
        overflow: "hidden",
        borderRadius: 4,
        margin: "24px 24px 24px 24px",

    } as const,

    ProjectIntro: {
        fontFamily: `"IBM Plex Mono", monospace`,
        color: "rgba(255, 255, 255, 0.9)",
        fontSize: "1.563rem",
        letterSpacing: "0.00625em",
        lineHeight: 1.2,
        fontWeight: 700,
        alignText: "center",
        paddingLeft: 0,
        paddingBottom: 32,
        paddingTop: 16,
    } as const,

    ProjectHeaderContainer: {
        width: "100%",
        height: 64,
        paddingBottom: 48,
        alignItems: "center",
        margin: "24px 24px 24px 24px",

    } as const,

    OverviewChip: {
        boxSizing: "border-box",
        width: "40%",
        height: 24,
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-evenly",
        alignItems: "center",
        padding: 16,
        overflow: "hidden",
        backgroundColor: "#343434",
        borderRadius: 4,
        fontFamily: `"IBM Plex Mono", monospace`,
        color: "#FFF",
        fontSize: "0.9em",
        letterSpacing: "0.00625em",
        lineHeight: 1.2,
        fontWeight: 700,
        fontStyle: "normal",
        textAlign: "center",
        marginBottom: "16px",
    } as const,

    OverviewChipType: {
        boxSizing: "border-box",
        width: "40%",
        height: 24,
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-evenly",
        alignItems: "center",
        padding: 16,
        overflow: "hidden",
        backgroundColor: "#332E54",
        borderRadius: 4,
        fontFamily: `"IBM Plex Mono", monospace`,
        color: "#FFF",
        fontSize: "0.9em",
        letterSpacing: "0.00625em",
        lineHeight: 1.2,
        fontWeight: 700,
        fontStyle: "normal",
        textAlign: "center",
        marginBottom: "16px",
    } as const,
    OverviewChipWrapper: {
        boxSizing: "border-box",
        width: "100%",
        height: 24,
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-evenly",
        alignItems: "left",
        padding: "16px 16px 16px 0px",
        overflow: "visible",
        backgroundColor: "rgba(255, 255, 255,0)",
        borderRadius: 4,
        fontFamily: `"Inter", sans-serif`,
        color: "#FFF",
        fontSize: 16,
        letterSpacing: "0.00625em",
        lineHeight: 1.2,
        fontWeight: 700,
        fontStyle: "normal",
        textAlign: "center",
        marginBottom: "16px",
    } as const,

    OverviewTitle: {
        fontFamily: `"IBM Plex Mono", monospace`,
        color: "rgba(255, 255, 255, 0.9)",
        fontSize: "1.563rem",
        letterSpacing: "0.00625em",
        lineHeight: 1.2,
        fontWeight: 700,

    } as const,

    OverviewText: {
        fontFamily: `"Inter", sans-serif`,
        color: "rgba(255, 255, 255, 0.9)",
        fontSize: "1.25rem",
        letterSpacing: "0.00625em",
        lineHeight: 1.2,
        fontWeight: 400,

    } as const,

    SectionHeader: {
        fontFamily: `"IBM Plex Mono", monospace`,
        color: "rgba(255, 255, 255, 0.9)",
        fontSize: "1.563rem",
        letterSpacing: "0.00625em",
        lineHeight: 1.2,
        fontWeight: 700,
        minWidth: "400px",
    },

    SectionBlurb: {
        fontFamily: `"IBM Plex Mono", monospace`,
        color: "rgba(255, 255, 255, 0.9)",
        fontSize: "1rem",
        letterSpacing: "0.00625em",
        lineHeight: 1.2,
        fontWeight: 400,
        minWidth: "340px",
    },

    SectionTitle: {
        fontFamily: `"IBM Plex Mono", monospace`,
        color: "rgba(255, 255, 255, 0.9)",
        fontSize: "1.25rem",
        letterSpacing: "0.00625em",
        lineHeight: 1.2,
        fontWeight: 700,

    },

    SectionText: {

    },

    PhotoContainerProject: {
        maxWidth: "100%",
        maxHeight: "900px",
        overflow: "visible",
        margin: "24px 24px 0px 0px"
    } as const,


    PhotoContainerBoard: {
        maxWidth: "100%",
        maxHeight: "900px",
        overflow: "visible",
        margin: "24px 24px 0px 0px",
        background: "white"
    } as const,



    ImageCaption: {
        fontFamily: `"Inter", sans-serif`,
        color: "rgba(255, 255, 255, 0.6)",
        fontSize: "1.0rem",
        letterSpacing: "0.00625em",
        lineHeight: 1.2,
        fontWeight: 600,
    } as const,



}

//variants for inline links 

const InlineLink = {

    initial: {
        textDecoration: "none",

        opacity: "75%"
    },
    hover: {
        textDecoration: "underline",

        opacity: "100%"
    },


} as const


// variants for motion div (TITLE) -

const titleVariants = {
    initial: {
        opacity: 1,
        x: 0,
        fontSize: "3.815em",
    },
    in: {
        opacity: 1,
        x: 0,
        transition: {
            type: "ease",
            ease: [0.22, 1, 0.36, 1],
            duration: 0.75,
            x: { stiffness: 100, velocity: -100 },
        },
        fontSize: "1.563em",
    },
    out: {
        opacity: 1,
        x: "100vw",
    },
}



function CurologyTimeline(): JSX.Element {
    const gaEventTracker = useAnalyticsEventTracker('Curology other work')

    return (
        <Grid fluid>
            <Row top="lg" around="lg">
                <Col lg={5} lgOffset={1}>
                    <motion.div
                        style={styles.Style}
                        initial="initial"
                        animate="in"
                        exit="out"
                        variants={titleVariants}
                    >
                        <p style={styles.TopTitle}>Chaitanya Prashant</p>

                    </motion.div>

                </Col>
                <Col lg={1} lgOffset={2} xs={3} xsOffset={2} style={{ marginTop: 48 }}>
                    <Link to='/' onClick={()=> gaEventTracker('Home page top nav')}>
                        <NavBar
                            Active={false}
                        />
                    </Link>
                </Col>
                <Col lg={1} xs={4} style={{ marginTop: 48 }}>
                    <Link to='/Pages/About' onClick={()=> gaEventTracker('About page top nav')}>
                        <NavBar
                            Active={false}
                            Text="About Me"
                        />

                    </Link>
                </Col>
            </Row>


            <motion.div>

                <Row start="lg" style={{ marginTop: 64 }}>
                    <Col xs={10} md={6} mdOffset={3} lg={6} lgOffset={3}>
                        <motion.div style={styles.ProjectHeaderContainer}>
                            <motion.div style={{ width: "100%", textAlign: "center" }}>
                                <p style={styles.ProjectIntro}>Additional work at Curology</p>
                            </motion.div>
                            <motion.div
                                style={styles.OverviewChipWrapper}
                            >
                                <motion.div
                                    style={styles.OverviewChip}
                                >Internship</motion.div>
                                <motion.div
                                    style={styles.OverviewChipType}
                                >Summer 2020</motion.div>
                            </motion.div>

                        </motion.div>

                    </Col>
                </Row>


                <Row start="lg" style={{ marginTop: 64 }}>
                    <Col xs={10} lg={6} lgOffset={3}>
                        <motion.div
                        >
                            <img style={styles.PhotoContainer} src={Wordmark} alt="Wordmark" />
                        </motion.div>

                    </Col>
                </Row>
            </motion.div>




            <Row start="lg">
                <Col xs={10} xsOffset={1} lg={5} lgOffset={1}>
                    <motion.div>
                        <p style={styles.OverviewTitle}>Overview</p>
                        <p style={styles.OverviewText}>Assorted work at Curology that I was able to contribute to, apart from my primary project.</p>
                    </motion.div>
                    <motion.div>
                        <p style={styles.OverviewTitle}>Role</p>
                        <p style={styles.OverviewText}>Product Designer (support) - Provider Timeline</p>
                        <p style={styles.OverviewText}>Product Designer (primary) - Radiance UI component design and development</p>

                    </motion.div>
                    

                </Col>
                <Col xs={10} xsOffset={1} lg={5} lgOffset={1}>
                    <motion.div>
                        <p style={styles.OverviewTitle}>Responsibilities</p>
                        <p style={styles.OverviewText}>Interaction + visual design, prototyping and product thinking.</p>
                    </motion.div>
                    <motion.div>
                        <p style={styles.OverviewTitle}>Timeline</p>
                        <p style={styles.OverviewText}>Spread throughout the 13 week internship period (summerwide)</p>
                    </motion.div>
                </Col>

            </Row>

            <Row start="lg" style={{ marginTop: "72px" }}>
                <Col xs={12} lg={2} lgOffset={1}>
                    <motion.div>
                        <p style={styles.SectionHeader}>Introduction</p>
                        <p style={styles.SectionBlurb}>Below is some of the assorted work I did, either serving in a support role or minor project work, throughout my 3 months as a product design intern at Curology.</p>

                    </motion.div>
                </Col>
                <Col xs={12} lg={6} lgOffset={2}>
                    <motion.div>
                        <p style={styles.HeroDescription}>Some of the areas I was able to contribute to were,</p>
                        <p style={styles.HeroDescription}>
                            <ul>
                                <li>Supporting our primary designer, Ida on our Provider Timeline redesign / MVP. This included the highlights feature, as well as supporting work on building out the design system for our provider side. The provider timeline is the vertical in our product used by our internal providers, serving as a hub from where they prescribe, chat with patients, and can ask for support from the rest of the Curology team if needed.</li>
                                <li>Contributed a component to our design system, Radiance. There was a specific need for the component that scaled, at the time, to four ongoing initiatives within Curology (three on the patient side, and one on the provider side). Thus, this was a short, though high priority, endeavor.</li>
                            </ul>
                        </p>

                        
                    </motion.div>
                </Col>
            </Row>
            <Row start="lg" style={{ marginTop: "72px" }}>
                <Col xs={12} lg={2} lgOffset={1}>
                    <motion.div>
                        <p style={styles.SectionHeader}>Highlights</p>
                        <p style={styles.SectionBlurb}>Feature meant to provide added context to our providers using the timeline, with a focus on aiding discoverability and navigability.</p>

                    </motion.div>

                </Col>
                <Col xs={12} lg={6} lgOffset={2}>
                    <motion.div>
                        <p style={styles.SectionTitle}>Goals</p>
                        <p style={styles.HeroDescription}>Give a high-level overview of important events throughout a patient’s journey.</p>
                        <p style={styles.HeroDescription}>An easy way for providers to navigate the timeline w/out having to scroll.</p>
                        <p style={styles.HeroDescription}>Strengthening the visual relationship between highlights and specific timeline events, making these more discoverable for providers.</p>
                        <p style={styles.SectionTitle}>Prototyping in Framer</p>
                        <p style={styles.HeroDescription}>Prototyping in Framer unlocked a lot of possibilities for us, as we were able to iterate with speed and confidence. We were able to hone in on the interaction design of the feature, and the quick validation cycles led to us making an informed decision over our design decisions.</p>
                        <Row>
                            <Col xs={12} lg={12}>
                            <video style={styles.PhotoContainerProject} src={EarlyHighlightsPrototype} controls></video>                                       
                             <p style={styles.ImageCaption}>Early iteration of the feature</p>

                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} lg={12}>
                            <video style={styles.PhotoContainerProject} src={FinalHighlightsPrototype} controls></video>                                        
                            <p style={styles.ImageCaption}>Final highlights prototype</p>

                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} lg={12}>
                                <img style={styles.PhotoContainerBoard} src={HighlightsFlow} alt="HighlightsFlow" />
                                <p style={styles.ImageCaption}>Shipped highlights flow</p>

                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} lg={12}>
                                <img style={styles.PhotoContainerBoard} src={HighlightSpec} alt="HighlightSpec" />
                                <p style={styles.ImageCaption}>Highlights animation spec (for handoff)</p>

                            </Col>
                        </Row>

                    </motion.div>

                </Col>

            </Row>

            <Row start="lg" style={{ marginTop: "72px" }}>
                <Col xs={12} lg={2} lgOffset={1}>
                    <motion.div>
                        <p style={styles.SectionHeader}>Admin Design System</p>
                        <p style={styles.SectionBlurb}>Working hand in hand alongside Ida, we were able to design the first components of the admin design system. We split up tasks in regards to components, and I was happy to wear the hat of production designer as well, doing visual QA and improving my visual design skills with regards to internal tools and complex, feature-heavy products.</p>
                    </motion.div>
                </Col>
                <Col xs={12} lg={6} lgOffset={2}>
                    <motion.div>
                        <p style={styles.SectionTitle}>Components I specifically contributed to, with both design support, thinking, and visual QA.</p>
                        <Row>
                            <Col xs={12} lg={12}>
                                <img style={styles.PhotoContainerProject} src={HighlightsComponent} alt="HighlightsComponent" />
                                <p style={styles.ImageCaption}>Shipped highlights component (also part of the feature I was responsible for)</p>

                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} lg={12}>
                                <img style={styles.PhotoContainerProject} src={ProviderPanel} alt="ProviderPanel" />
                                <p style={styles.ImageCaption}>Provider panel (navigation)</p>

                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} lg={12}>
                                <img style={styles.PhotoContainerProject} src={CommentCard} alt="CommentCard" />
                                <p style={styles.ImageCaption}>Signup questionnaire - comment card (Provider)</p>

                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} lg={12}>
                                <img style={styles.PhotoContainerProject} src={UploadCard} alt="UploadCard" />
                                <p style={styles.ImageCaption}>Photo upload card</p>

                            </Col>
                        </Row>

                    </motion.div>
                </Col>
            </Row>

            <Row start="lg" style={{ marginTop: "72px" }}>
                <Col xs={12} lg={2} lgOffset={1}>
                    <motion.div>
                        <p style={styles.SectionHeader}>Radiance UI</p>
                        <p style={styles.SectionBlurb}>I was also able to work on education and documentation with my co-intern at Curology, Andrew. What started out as a quick internal audit within our design team, we took a note of the pain points each designer was facing. We also had the goal of increasing adoption within the wider company, especially as to the anatomy of a component and some basic guidelines for consumption. To this end, Andrew and I iterated on the documentation, ensuring that we added context in terms of example usage.</p>
                    </motion.div>
                </Col>
                <Col xs={12} lg={6} lgOffset={2}>
                    <motion.div>

                        <p style={styles.HeroDescription}>Our intent here was to make sure that no matter if you were on the design team, front end engineering, or marketing, they could consume / see our components with the right context.</p>
                        <p style={styles.HeroDescription}>Some of the components I was directly able to contribute to, are down below.</p>
                        <Row>
                            <Col xs={12} lg={12}>
                                <img style={styles.PhotoContainerProject} src={Accordion} alt="Accordion" />
                                <p style={styles.ImageCaption}>Accordion component</p>

                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} lg={12}>
                                <img style={styles.PhotoContainerProject} src={StandardInputs} alt="StandardInputs" />
                                <p style={styles.ImageCaption}>Standard inputs / form labels</p>

                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} lg={12}>
                                <img style={styles.PhotoContainerProject} src={Iconography} alt="Iconography" />
                                <p style={styles.ImageCaption}>Iconography guidelines</p>

                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} lg={12}>
                                <img style={styles.PhotoContainerProject} src={OptionButtons} alt="OptionButtons" />
                                <p style={styles.ImageCaption}>Option Buttons</p>

                            </Col>
                        </Row>


                        <p style={styles.HeroDescription}>I was also able to work on a performant segmented control for our design system (new component). Our approach to building out the segmented controls for consumption by designers themselves, started off with using Figma’s grid layout, and we wanted to make sure that it was scalable across a wide variety of screens (mobile, desktop, tablet).</p>
                        <Row>
                            <Col xs={12} lg={12}>
                                <img style={styles.PhotoContainerProject} src={SegmentedControlComponent} alt="SegmentedControlComponent" />
                                <p style={styles.ImageCaption}>Segmented control component</p>

                            </Col>
                        </Row>

                        <p style={styles.HeroDescription}>We built out the animation in a robust way that ties into our company’s motion branding by our creative marketing team, utilizing our motion team’s custom bezier curve. The intent was to unify product and creative brand, so that even a granular interaction is delightful, and still feels unique to Curology.</p>
                        <Row>
                            <Col xs={12} lg={12}>
                            <video style={styles.PhotoContainerProject} src={SegmentedPrototype} controls></video>                                        
                                <p style={styles.ImageCaption}>Segmented control prototype</p>

                            </Col>
                        </Row>


                    </motion.div>
                </Col>
            </Row>



            <Row start="lg" style={{ marginTop: "72px" }}>
                <Col xs={12} lg={2} lgOffset={1}>
                    <motion.div>
                        <p style={styles.SectionHeader}>Reflections on the summer</p>
                    </motion.div>
                </Col>
                <Col xs={12} lg={6} lgOffset={2}>
                    <motion.div>
                    <p style={styles.HeroDescription}>This summer was easily one of the most rewarding of my professional career, as I was able to grow immensely as a visual designer and gained the skill of tying early research to business goals.</p>
                        <p style={styles.HeroDescription}>From a craft standpoint, I truly appreciated the Curology design teams' consistent culture of design crit. Informal, yet focused, these sessions helped me as a designer even if I weren't directly showing my work. The culture of crit really helped hone my design eye and begin to look for asking the right questions, as well as establishing the right context, something that is so critical for young designers like myself.</p>

                        <p style={styles.HeroDescription}>Another fantastic point that I had throughout the summer, definitely was the realization that the work we do as designers, at whatever fidelity, is not the artifact, but the communicative tool through which the artifact is driven. This was an important point I discussed with my manager, as it helped me not be as "married" or "wedded" to a solution.</p>
                        <p style={styles.HeroDescription}>
                            My manager, Ben, had some great stuff to say about our summer,
                        </p>
                        <motion.a initial="initial" whileHover="hover" variants={InlineLink} style={styles.HeroDescription} href={evalFile} target = "_blank" onClick={()=> gaEventTracker('Download intern eval')}>read my intern evaluation here!</motion.a>

                    </motion.div>
                </Col>
            </Row>



            <Row start="lg" style={{ marginTop: 32 }}>
                <Col xs={12} lg={5} lgOffset={1}>

                    <motion.div style={{ paddingBottom: 64 }}>
                        <motion.a initial="initial" whileHover="hover" variants={InlineLink} style={styles.SectionBlurb} href="/" onClick={()=> gaEventTracker('Home page footer')}>Go back home</motion.a>

                    </motion.div>

                </Col>
            </Row>
        </Grid>



    )
}

export default CurologyTimeline;