import * as React from "react"
import '../grid.css'
import { motion } from "framer-motion"

import { Link } from 'react-router-dom'
import { NavBar } from '../Components/NavBar'

import { Grid, Row, Col } from "react-flexbox-grid"
//adding support for IBM Plex Mono as a font.
import AboutImage from '../Assets/Chai-About.jpeg'
import resumeFile from '../Assets/Chaitanya-resume-2024-25.pdf'
import useAnalyticsEventTracker from '../useAnalyticsEventTracker';


//adding CSS styles inline, as we do
const styles = {
    Page: { backgroundColor: "#27292D" },

    TopTitle: {
        fontFamily: `"IBM Plex Mono", monospace`,
        color: "rgba(255, 255, 255, 0.9)",
        fontSize: "1.25em",
        letterSpacing: "0.0001em",
        lineHeight: 1.2,
        fontWeight: 500,
        fontStyle: "normal",

    } as const,

    HeroDescription: {
        fontFamily: `"Inter", sans-serif`,
        color: "rgba(255, 255, 255, 0.9)",
        fontSize: "1.25em",
        letterSpacing: "0.00625em",
        lineHeight: 1.65,

    } as const,

    HeroLink: {
        fontFamily: `"Inter", sans-serif`,
        color: "rgba(255, 255, 255, 0.9)",
        fontSize: "1em",
        letterSpacing: "0.00125em",
        lineHeight: 1.65,

    } as const,

    FooterText: {
        fontFamily: `"IBM Plex Mono", monospace`,
        color: "rgba(255, 255, 255, 0.9)",
        fontSize: "1.25em",
        letterSpacing: "0.00125em",
        lineHeight: 1.65,
    } as const,


    HeroContainer: {
        width: 424,
        height: 74,
        marginTop: 64,
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-evenly",
        alignItems: "center",
        overflow: "visible",
        background: "#27292D",
    } as const,

    Style: {
        marginTop: 72,
        width: "100%",
    } as const,

    // styles for text and images, etc. 
    PhotoContainer: {
        maxWidth: "100%",
        maxHeight: "900px",
        overflow: "hidden",
        borderRadius: 4,
        margin: "24px 24px 24px 0px",

    } as const,

    ProjectIntro: {
        fontFamily: `"Inter", sans-serif`,
        color: "rgba(255, 255, 255, 0.9)",
        fontSize: "2.441rem",
        letterSpacing: "0.00625em",
        lineHeight: 1.2,
        fontWeight: 700,
    },

    OverviewChip: {
        boxSizing: "border-box",
        width: "50%",
        height: 24,
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-evenly",
        alignItems: "center",
        padding: 16,
        overflow: "hidden",
        backgroundColor: "#343434",
        borderRadius: 4,
        fontFamily: `"IBM Plex Mono", monospace`,
        color: "#FFF",
        fontSize: "1em",
        letterSpacing: "0.00625em",
        lineHeight: 1.2,
        fontWeight: 700,
        fontStyle: "normal",
        textAlign: "center",
        marginBottom: "16px",
    } as const,

    OverviewChipType: {
        boxSizing: "border-box",
        width: "40%",
        height: 24,
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-evenly",
        alignItems: "center",
        padding: 16,
        overflow: "hidden",
        backgroundColor: "#84AF43",
        borderRadius: 4,
        fontFamily: `"IBM Plex Mono", monospace`,
        color: "#FFF",
        fontSize: "1em",
        letterSpacing: "0.00625em",
        lineHeight: 1.2,
        fontWeight: 700,
        fontStyle: "normal",
        textAlign: "center",
        marginBottom: "16px",
    } as const,
    OverviewChipWrapper: {
        boxSizing: "border-box",
        width: "100%",
        height: 24,
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-evenly",
        alignItems: "center",
        padding: 16,
        overflow: "visible",
        backgroundColor: "rgba(255, 255, 255,0)",
        borderRadius: 4,
        fontFamily: `"IBM Plex Mono", monospace`,
        color: "#FFF",
        fontSize: 16,
        letterSpacing: "0.00625em",
        lineHeight: 1.2,
        fontWeight: 700,
        fontStyle: "normal",
        textAlign: "center",
        marginBottom: "16px",
    } as const,

    OverviewTitle: {
        fontFamily: `"Inter", sans-serif`,
        color: "rgba(255, 255, 255, 0.9)",
        fontSize: "1.563rem",
        letterSpacing: "0.00625em",
        lineHeight: 1.2,
        fontWeight: 700,

    } as const,

    OverviewText: {
        fontFamily: `"Inter", sans-serif`,
        color: "rgba(255, 255, 255, 0.9)",
        fontSize: "1.25rem",
        letterSpacing: "0.00625em",
        lineHeight: 1.2,
        fontWeight: 400,

    } as const,

    OverviewList: {
        fontFamily: `"Inter", sans-serif`,
        color: "rgba(255, 255, 255, 0.9)",
        fontSize: "1.25rem",
        letterSpacing: "0.00625em",
        lineHeight: 1.2,
        fontWeight: 400,
        listStyleType: "outside",
        marginBottom: 16,
        padding: 0,

    } as const,

    SectionHeader: {
        fontFamily: `"IBM Plex Mono", monospace`,
        color: "rgba(255, 255, 255, 0.9)",
        fontSize: "1.563rem",
        letterSpacing: "0.00625em",
        lineHeight: 1.2,
        fontWeight: 700,
        minWidth: "400px",
    },

    SectionBlurb: {
        fontFamily: `"IBM Plex Mono", monospace`,
        color: "rgba(255, 255, 255, 0.9)",
        fontSize: "1rem",
        letterSpacing: "0.00625em",
        lineHeight: 1.2,
        fontWeight: 400,
        minWidth: "400px",

    },

    SectionTitle: {
        fontFamily: `"Inter", sans-serif`,
        color: "rgba(255, 255, 255, 0.9)",
        fontSize: "1.25rem",
        letterSpacing: "0.00625em",
        lineHeight: 1.2,
        fontWeight: 700,

    },

    SectionText: {

    },

    PhotoContainerProject: {
        maxWidth: "100%",
        maxHeight: "900px",
        overflow: "visible",
        margin: "24px 24px 24px 0px"
    } as const,



    ImageCaption: {
        fontFamily: `"Inter", sans-serif`,
        color: "rgba(255, 255, 255, 0.6)",
        fontSize: "1rem",
        letterSpacing: "0.00625em",
        lineHeight: 1.2,
        fontWeight: 400,
    }

}


// variants for motion div (TITLE) -



const InlineLink = {

    initial: {
        textDecoration: "underline",

        opacity: "75%"
    },
    hover: {
        textDecoration: "underline",

        opacity: "100%"
    },


} as const


const titleVariants = {
    initial: {
        opacity: 1,
        x: 0,
        fontSize: "3.815em",
    },
    in: {
        opacity: 1,
        x: 0,
        transition: {
            type: "ease",
            ease: [0.22, 1, 0.36, 1],
            duration: 0.75,
            x: { stiffness: 100, velocity: -100 },
        },
        fontSize: "1.563em",
    },
    out: {
        opacity: 1,
        x: "100vw",
    },
}



function About(): JSX.Element {
    const gaEventTracker = useAnalyticsEventTracker('About Page')

    return (
        <Grid fluid>
            <Row top="lg" around="lg">
                <Col lg={5} lgOffset={1}>
                    <motion.div
                        style={styles.Style}
                        initial="initial"
                        animate="in"
                        exit="out"
                        variants={titleVariants}
                    >
                        <p style={styles.TopTitle}>Chaitanya Prashant</p>

                    </motion.div>

                </Col>
                <Col lg={1} lgOffset={2} style={{ marginTop: 64 }}>
                    <Link to='/' onClick={()=> gaEventTracker('Homepage top nav')}>
                        <NavBar
                            Active={false}
                        />

                    </Link>
                </Col>
                <Col lg={1} style={{ marginTop: 64 }}>
                    <Link to='/Pages/About' onClick={()=> gaEventTracker('About page')}>
                        <NavBar
                            Active={true}
                            Text="About Me"
                        />
                    </Link>
                </Col>
            </Row>



            <Row start="lg" style={{marginTop: 64}}>
                <Col lg={4} lgOffset={1}>
                    <motion.div
                    >
                        <img style={styles.PhotoContainer} src={AboutImage} alt="Wizehire Dashboard" />
                    </motion.div>

                </Col>
                <Col xs={10} xsOffset={1} lg={4} lgOffset={2}>
                    <motion.div>
                        <p style={styles.OverviewTitle}>Where I'm from</p>
                        <p style={styles.OverviewText}>New Delhi, India</p>
                    </motion.div>
                    <motion.div>
                        <p style={styles.OverviewTitle}>Current Location</p>
                        <p style={styles.OverviewText}>San Diego, CA (but open to relocate!)</p>
                    </motion.div>
                    <motion.div>
                        <p style={styles.OverviewTitle}>In a previous life I have</p>
                        <p style={styles.OverviewText}>
                        <ul>
                            <li>Been a UX engineer at CBRE (through contract with RTS), where I was chiefly responsible for designing and implementing a mock API virtualization service within our proprietary internal developer marketplace, Dev-X. I did this in React and Typescript, collaborating with a backend developer who wrote our backend microservices in Go.</li>
                            <li>Worked as a product design intern at Curology, where my work spanned design systems, as well as internal tools to help our providers serve patients better, and growth design on the patient platform.</li>
                            <li>Worked on growth design at Wizehire, an ATS solution for small and medium businesses.</li>
                            <li>Worked as a UX design intern at PagerDuty before they went public, designing on the enterprise and permissions team.</li>
                        </ul>
                        </p>

                   
                    </motion.div>

                </Col>

            </Row>


            <Row start="lg" style={{ marginTop: "16px", marginBottom: "64px" }}>
                <Col xs={12} lg={8} lgOffset={1}>
                    <motion.div>
                        <p style={styles.HeroDescription}>Hey! My name is Chaitanya, or Chai for short! I'm a product designer and front-end engineer who finished up my B.S in Design and Interaction at the University of California, San Diego. I am passionate about creating efficient and delightful experiences that everyone can enjoy.</p>
                        <p style={styles.HeroDescription}>I am keen about taking on complex system design challenges to unearth simple solutions, combining the algorithmic thinking of a programmer with the empathetic perspective of a designer to build meaningful products in a multidisciplinary setting.</p>
                        <p style={styles.HeroDescription}>Outside the classroom, I love traveling, listening to electronic music, going to concerts with friends, watching Succession, and am an avid sports fan, tweeting about Chelsea FC and the LA Clippers almost every opportunity I get.</p>
                        <p style={styles.HeroDescription}>Thanks for visiting my little corner of the internet! Hit me up on <motion.a style={styles.HeroLink} initial="initial" whileHover="hover" variants={InlineLink} href="mailto:chaitanyaprashant@gmail.com" target="_blank" onClick={()=> gaEventTracker('e-mail--about page')}>e-mail</motion.a>, or check out this <motion.a style={styles.HeroLink} initial="initial" whileHover="hover" variants={InlineLink} href={resumeFile} target="_blank" onClick={()=> gaEventTracker('Resume download--about page')}>fancy piece of paper</motion.a>.</p>
                    </motion.div>
                </Col>

            </Row>
            <Row start="lg">
                    <Col lg={12} lgOffset={1}>
                        <p style={styles.FooterText}>Built with <a style={{ padding: 4 }}>&#x1F499;</a> + <a style={{ padding: 4 }}>&#x2615;</a> in San Diego using React (Typescript) and Framer Motion.</p>
                    </Col>
                </Row>
            
        </Grid>



    )
}

export default About;
