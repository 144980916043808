/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from "react"
import { motion } from "framer-motion"

import '../grid.css'
import { Link } from 'react-router-dom'
import { ProjectCard } from '../Components/ProjectCard'
import { ProjectCardMobile } from '../Components/ProjectCardMobile'

import { NavBar } from '../Components/NavBar'

import { Grid, Row, Col } from "react-flexbox-grid"
//adding support for IBM Plex Mono as a font.

import resumeFile from '../Assets/Chaitanya-resume-2024-25.pdf'


import QuickDesignWalkthrough from '../Assets/Wizehire/Wizehire-assets/Fasten-up.mp4'

import LogoCurology from '../Assets/Curology/curology-logo-naive.jpeg'
import ImageWizehire from '../Assets/Wizehire/Wizehire-big-mockup.png'
import ImagePd from '../Assets/PagerDuty/PD-mock.png'
import ImageMango from '../Assets/Mango/Mango-mockup.png'
import ImageVenmo from '../Assets/Venmo/Venmo-mockup.png'
import { ReactComponent as Twitter } from '../Assets/Icons/twitter.svg'
import { ReactComponent as Github } from '../Assets/Icons/github.svg'
import { ReactComponent as Dribbble } from '../Assets/Icons/dribbble-ball-mark.svg'
import { ReactComponent as Instagram } from '../Assets/Icons/instagram.svg'
import { ReactComponent as LinkedIn } from '../Assets/Icons/Linkedin.svg'
import "fontsource-inter"
import "fontsource-space-grotesk"
import "fontsource-jost"
import useMediaQuery from "../Components/useMediaQuery"
import { useState } from "react"
//import tracking code
import useAnalyticsEventTracker from '../useAnalyticsEventTracker';


//adding CSS styles inline, as we dok=

//tracking window width 

const styles = {
    Page: { backgroundColor: "#27292D" },

    HeroTitle: {
        fontFamily: `"IBM Plex Mono", monospace`,
        color: "rgba(255, 255, 255, 0.9)",
        fontSize: "3.5em",
        letterSpacing: "0.0625em",
        lineHeight: 1.2,
        fontWeight: 500,
        fontStyle: "normal",

    } as const,

    HeroDescription: {
        fontFamily: `"Inter", sans-serif`,
        color: "rgba(255, 255, 255, 0.9)",
        fontSize: "1.25em",
        letterSpacing: "0.00125em",
        lineHeight: 1.65,
    } as const,
    FooterText: {
        fontFamily: `"IBM Plex Mono", monospace`,
        color: "rgba(255, 255, 255, 0.9)",
        fontSize: "1.25em",
        letterSpacing: "0.00125em",
        lineHeight: 1.65,
    } as const,

    HeroLink: {
        fontFamily: `"Inter", sans-serif`,
        color: "rgba(255, 255, 255, 0.9)",
        fontSize: "1em",
        letterSpacing: "0.00125em",
        lineHeight: 1.65,

    } as const,

    HeroDivider: {
        fontFamily: `"IBM Plex Mono", monospace`,
        color: "rgba(255, 255, 255, 0.9)",
        fontSize: "2.5em",
        letterSpacing: "0.00125em",
        lineHeight: 1.65,
        fontWeight: 500,

    } as const,


    SideWorkHeader: {
        fontFamily: `"IBM Plex Mono", monospace`,
        color: "rgba(255, 255, 255, 0.9)",
        fontSize: "1em",
        letterSpacing: "0.00125em",
        lineHeight: 1.65,
        fontWeight: 500,

    } as const,


    PhotoContainerProject: {
        maxWidth: "100%",
        maxHeight: "900px",
        overflow: "visible",
        margin: "24px 24px 0px 0px"
    } as const,

    //styles for inline links

    //styles for social links
    SocialContainer: {
        paddingTop: 32,
    } as const,

    IconContainer: {
        marginRight: 24,
        marginTop: 32,
        display: "flex-horizontal",
    },

    SocialContainerIcon: {
        initial: {
            opacity: 0.45,

        },

        hover: {
            opacity: 1,
            transition: {
                type: "ease",
                ease: [0.22, 1, 0.36, 1],
                duration: 0.15,
            }
        }

    } as const,

    //have two variants for this, default and hover.     


}


const InlineLink = {

    initial: {
        textDecoration: "underline",

        opacity: "75%"
    },
    hover: {
        textDecoration: "underline",

        opacity: "100%"
    },


} as const

const upperVariants = {
    initial: {
        opacity: 1,
        scale: 1,
    },
    in: {
        opacity: 1,
        scale: 1,
        transition: {
            type: "tween",
            ease: [0.22, 1, 0.36, 1],
            delayChildren: 0.125,
            staggerChildren: 0.85,

        },
    },
    out: {
        opacity: 1,
        scale: 1,
        staggerChildren: 0.025,
    }
}



//page transitions for animating into homepage
const HeroVariants = {
    initial: {
        opacity: 0,
        scale: 1,
        y: "-120%",

    },
    in: {
        opacity: 1,
        y: 0,
        scale: 1,
        transition: {
            type: "ease",
            ease: [0.22, 1, 0.36, 1],
            duration: 0.75,

        },

    },
    out: {
    }
};




const CardVariants = {
    initial: {
        opacity: 1,
        scale: 1,
        x: "30%",

    },
    in: {
        opacity: 1,
        x: 0,
        scale: 1,
        transition: {
            x: { stiffness: 100, velocity: -100 },
            type: "ease",
            ease: [0.22, 1, 0.36, 1],
            duration: 1.5,
            staggerChildren: 0.85,
            staggerDirection: 1,
        },
    },
    out: {
        opacity: 0,
        x: "-80%",
        scale: 1,
        transition: {
            x: { stiffness: 100, velocity: -100 },
            type: "ease",
            ease: [0.22, 1, 0.36, 1],
            duration: 1.5,
            staggerChildren: 0.85,
            staggerDirection: 1,
        }
    }
};


function Home(): JSX.Element {

    const gaEventTracker = useAnalyticsEventTracker('Homepage')
    const [isMobile, setIsMobile] = useState(false)
    let res = useMediaQuery('(max-width: 960px)')
    React.useEffect(() => {
        setIsMobile(res)
    }, [res])

    console.log(res)

    //const isMobile = useMediaQuery('(max-width: 960px)')

    return (
        <motion.div
            initial="initial"
            animate="in"
            exit="out"
            variants={upperVariants}
            layout
        >
            <Grid fluid>
                <Row top="lg" around="lg">
                    <Col lg={5} lgOffset={1} xs={11} xsOffset={1}>
                        <motion.div
                            initial="initial"
                            animate="in"
                            exit="out"
                            variants={HeroVariants}
                        >
                            <p style={styles.HeroTitle}>Chaitanya Prashant</p>
                            <p style={styles.HeroDescription}>What’s up! I’m a San Francisco based product designer who was most recently a UX designer at Minds. I studied Design and Computer Science at UCSD, and have diverse experiences as a product designer and engineer. Previously at <motion.a style={styles.HeroLink} initial="initial" whileHover="hover" variants={InlineLink} href="https://curology.com/" target="_blank">Curology</motion.a> as a product design intern, helping make skincare accessible for all. I'm also grateful to have built my technical skills as a UX engineer at CBRE (through RTS), working closely on internal tooling for the developer team. In a previous life as a product design intern, I contributed on enterprise SaaS products such as <motion.a style={styles.HeroLink} initial="initial" whileHover="hover" variants={InlineLink} href="https://www.pagerduty.com/" target="_blank">PagerDuty</motion.a>, designing features for their core enterprise product before they went public, as well as <motion.a style={styles.HeroLink} initial="initial" whileHover="hover" variants={InlineLink} href="https://wizehire.com/" target="_blank">Wizehire</motion.a>, where I was able to design for new user growth. </p>
                            <p style={styles.HeroDescription}>I’m looking for full time product design and design engineering roles starting September 2024, and am also open to freelance and contract opportunities. Feel free to look at my work below, or view this <motion.a style={styles.HeroLink} initial="initial" whileHover="hover" variants={InlineLink} href={resumeFile} target="_blank" onClick={()=> gaEventTracker('Resume download--homepage')}>fancy piece of paper</motion.a>.</p>
                            <p style={styles.HeroDescription}> If you like what you see, please do not hesitate to <motion.a style={styles.HeroLink} initial="initial" whileHover="hover" variants={InlineLink} href="mailto:chaitanyaprashant@gmail.com" target="_blank" onClick={()=> gaEventTracker('e-mail--homepage')}>reach out!</motion.a></p>
                        </motion.div>
                        <motion.div
                            style={styles.SocialContainer}
                            variants={HeroVariants}
                        >
                            <motion.a
                                style={styles.IconContainer}
                                variants={styles.SocialContainerIcon}
                                initial="initial"
                                whileHover="hover"

                                href="http://twitter.com/chints98"
                                target="_blank">
                                <Twitter
                                    fill="#FFFFFF"
                                />
                            </motion.a>

                            <motion.a
                                style={styles.IconContainer}
                                variants={styles.SocialContainerIcon}
                                initial="initial"
                                whileHover="hover"

                                href="https://www.github.com/chints98"
                                target="_blank">
                                <Github
                                    fill="#FFFFFF"
                                />
                            </motion.a>

                            <motion.a
                                style={styles.IconContainer}
                                variants={styles.SocialContainerIcon}
                                initial="initial"
                                whileHover="hover"

                                href="https://dribbble.com/chints98"
                                target="_blank">
                                <Dribbble
                                    fill="#FFFFFF"
                                />
                            </motion.a>

                            <motion.a
                                style={styles.IconContainer}
                                variants={styles.SocialContainerIcon}
                                initial="initial"
                                whileHover="hover"

                                href="https://www.linkedin.com/in/chaitanyaprashant"
                                target="_blank">
                                <LinkedIn
                                    fill="#FFFFFF"
                                />
                            </motion.a>

                            <motion.a
                                style={styles.IconContainer}
                                variants={styles.SocialContainerIcon}
                                initial="initial"
                                whileHover="hover"

                                href="https://instagram.com/chints98"
                                target="_blank">
                                <Instagram
                                    fill="#FFFFFF"
                                />
                            </motion.a>



                        </motion.div>

                    </Col>
                    <Col lg={1} lgOffset={2} xs={3} xsOffset={2} style={{ marginTop: 48 }}>
                        <Link to='/'>
                            <NavBar />
                        </Link>
                    </Col>
                    <Col lg={1} xs={4} style={{ marginTop: 48 }}>
                        <Link to='/Pages/About'>
                            <NavBar
                                Active={false}
                                Text="About Me"
                            />

                        </Link>
                    </Col>


                </Row>

                <motion.div
                    initial="initial"
                    animate="in"
                    exit="out"
                    variants={CardVariants}
                >

                    {/** NEW STYLE oF divvying up divs */}

                    <Row middle="lg" style={{ marginTop: 64 }}>
                        <Col xs={12}>
                            <Row around='lg'>
                                <Col lgOffset={1} lg={5}>
                                    <p style={styles.HeroDivider}><u>shipped work</u> <a style={{ padding: 0 }}>&#x2b07;</a> </p>

                                </Col>
                                <Col lgOffset={1} lg={4}>

                                </Col>
                            </Row>
                        </Col>
                    </Row>

                    <Row middle="lg" style={{ marginTop: 64 }}>
                        <Col xs={12}>
                            <Row around='lg'>
                                <Col lgOffset={1} lg={4}>
                                    <Link to='/Pages/CurologyCadence' onClick={()=> gaEventTracker('Subscription Cadence')}>
                                        {isMobile ?
                                            <ProjectCardMobile
                                                ProjTitle={"Subscription Cadence"}
                                            ></ProjectCardMobile>
                                            :
                                            <ProjectCard
                                                ProjTitle={"Subscription Cadence"}
                                            ></ProjectCard>

                                        }

                                    </Link>

                                </Col>
                                <Col lgOffset={1} lg={4}>

                                    <Link to='/Pages/Wizehire' onClick={()=> gaEventTracker('Wizehire Growth')}>
                                        {isMobile ?
                                            <ProjectCardMobile
                                                Background={"#27292D"}
                                                Theme={"#84AF43"}
                                                ProjectImage={ImageWizehire}
                                                ProjTitle={"Designing for growth at Wizehire"}
                                                ProjDescription={"One of the projects I worked on over the summer of 2019 was to design a way to increase new user growth through our existing referral program within the product, and identify new areas of impact for the feature."}
                                                TagWork={"Product Design"}
                                                TagLocation={"Wizehire"}
                                            ></ProjectCardMobile>
                                            :
                                            <ProjectCard
                                                Background={"#27292D"}
                                                Theme={"#84AF43"}
                                                ProjectImage={ImageWizehire}
                                                ProjTitle={"Designing for growth at Wizehire"}
                                                ProjDescription={"One of the projects I worked on over the summer of 2019 was to design a way to increase new user growth through our existing referral program within the product, and identify new areas of impact for the feature."}
                                                TagWork={"Product Design"}
                                                TagLocation={"Wizehire"}
                                            ></ProjectCard>


                                        }
                                    </Link>


                                </Col>
                            </Row>
                        </Col>
                    </Row>



                    {/* 
                    <Row middle="lg" style={{ marginTop: 64 }}>
                        <Col xs={12}>
                            <Row around='lg'>
                            <Col lgOffset={1} lg={4}>
                                    <Link to='/Pages/CurologyCadence'>
                                        {isMobile ?
                                            <ProjectCardMobile
                                                ProjTitle={"Subscription Cadence"}
                                            ></ProjectCardMobile>
                                            :
                                            <ProjectCard
                                                ProjTitle={"Subscription Cadence"}
                                            ></ProjectCard>

                                        }

                                    </Link>

                                </Col>
                                <Col lgOffset={1} lg={4}>

                                    <Link to='/Pages/Wizehire'>
                                        {isMobile ?
                                            <ProjectCardMobile
                                                Background={"#27292D"}
                                                Theme={"#84AF43"}
                                                ProjectImage={ImageWizehire}
                                                ProjTitle={"Designing for growth at Wizehire"}
                                                ProjDescription={"One of the projects I worked on over the summer of 2019 was to design a way to increase new user growth through our existing referral program within the product, and identify new areas of impact for the feature."}
                                                TagWork={"Product Design"}
                                                TagLocation={"Wizehire"}
                                            ></ProjectCardMobile>
                                            :
                                            <ProjectCard
                                                Background={"#27292D"}
                                                Theme={"#84AF43"}
                                                ProjectImage={ImageWizehire}
                                                ProjTitle={"Designing for growth at Wizehire"}
                                                ProjDescription={"One of the projects I worked on over the summer of 2019 was to design a way to increase new user growth through our existing referral program within the product, and identify new areas of impact for the feature."}
                                                TagWork={"Product Design"}
                                                TagLocation={"Wizehire"}
                                            ></ProjectCard>


                                        }
                                    </Link>


                                </Col>
                            </Row>
                        </Col>
                    </Row> */}




                    <Row middle="lg" style={{ marginTop: 64 }}>
                        <Col xs={12}>
                            <Row around='lg'>
                                <Col lgOffset={1} lg={4}>
                                    <Link to='/Pages/CurologyTimeline' onClick={()=> gaEventTracker('Curology additional work')}>
                                        {isMobile ?
                                            <ProjectCardMobile
                                                Background={"#332E54"}
                                                ProjectImage={LogoCurology}
                                                ProjTitle={"Additional work at Curology"}
                                                ProjDescription={"Work including contributing on the Provider Timeline project, as well as collaborating on our administration side design system to try and align with Curology brand values. Created a brand new component (segmented controls) in conjunction with our senior designer and engineer, and contributing to our Curology design system, Radiance."}
                                                TagWork={"Product Design"}
                                                TagLocation={"Curology"}
                                            ></ProjectCardMobile>
                                            :
                                            <ProjectCard
                                                Background={"#332E54"}
                                                ProjectImage={LogoCurology}
                                                ProjTitle={"Additional work at Curology"}
                                                ProjDescription={"Work including contributing on the Provider Timeline project, as well as collaborating on our administration side design system to try and align with Curology brand values. Created a brand new component (segmented controls) in conjunction with our senior designer and engineer, and contributing to our Curology design system, Radiance."}
                                                TagWork={"Product Design"}
                                                TagLocation={"Curology"}
                                            ></ProjectCard>

                                        }

                                    </Link>

                                </Col>
                                <Col lgOffset={1} lg={4}>

                                    <Link to='/Pages/PagerDuty' onClick={()=> gaEventTracker('PagerDuty UX design case study')}>
                                        {isMobile ?
                                            <ProjectCardMobile
                                                Theme={"#41BC00"}
                                                ProjectImage={ImagePd}
                                                ProjTitle={"PagerDuty Teams UX internship"}
                                                ProjDescription={"I spent the summer of 2018 interning at PagerDuty, where I redesigned a feature for the core enterprise product to better represent administrative data within teams for incident management, and allow admins to make incident management decisions with more confidence."}
                                                TagWork={"Product Design"}
                                                TagLocation={"PagerDuty"}
                                            ></ProjectCardMobile>
                                            :
                                            <ProjectCard
                                                Theme={"#41BC00"}
                                                ProjectImage={ImagePd}
                                                ProjTitle={"PagerDuty Teams UX internship"}
                                                ProjDescription={"I spent the summer of 2018 interning at PagerDuty, where I redesigned a feature for the core enterprise product to better represent administrative data within teams for incident management, and allow admins to make incident management decisions with more confidence."}
                                                TagWork={"Product Design"}
                                                TagLocation={"PagerDuty"}
                                            ></ProjectCard>


                                        }
                                    </Link>


                                </Col>
                            </Row>
                        </Col>
                    </Row>




                    <Row middle="lg" style={{ marginTop: 64 }}>
                        <Col xs={12}>
                            <Row around='lg'>
                                <Col lgOffset={1} lg={5}>
                                    <p style={styles.HeroDivider}><u>work + play</u> <a style={{ padding: 0 }}>&#x2b07;</a> </p>

                                </Col>
                                <Col lgOffset={1} lg={4}>

                                </Col>
                            </Row>
                        </Col>
                    </Row>



                    <Row middle="lg" style={{ marginTop: 64 }}>
                        <Col xs={12}>
                            <Row around='lg'>
                                <Col lgOffset={1} lg={4}>
                                    <Link to='/Pages/Mango' onClick={()=> gaEventTracker('Mango Health case study')}>
                                        {isMobile ?
                                            <ProjectCardMobile
                                                ProjTitle={"Redesigning Mango Health"}
                                                ProjectImage={ImageMango}
                                                TagColor={"#2F80ED"}
                                                Theme={"#EF6C3C"}
                                                ProjDescription={"A redesign of Mango Health for the KP Fellows Application. I focused on information and visual design, using guerilla research to discover potential pain points, scope for improvements to the home surface and redesign the app to be more goal oriented, less information dense. Result - Named KP Fellows finalist for 2019."}
                                                TagWork={"Side Project"}
                                                TagLocation={"UX/Visual design"}
                                            ></ProjectCardMobile>
                                            :
                                            <ProjectCard
                                                ProjTitle={"Redesigning Mango Health"}
                                                ProjectImage={ImageMango}
                                                TagColor={"#2F80ED"}
                                                Theme={"#EF6C3C"}
                                                ProjDescription={"A redesign of Mango Health for the KP Fellows Application. I focused on information and visual design, using guerilla research to discover potential pain points, scope for improvements to the home surface and redesign the app to be more goal oriented, less information dense. Result - Named KP Fellows finalist for 2019."}
                                                TagWork={"Side Project"}
                                                TagLocation={"UX design"}
                                            ></ProjectCard>

                                        }

                                    </Link>

                                </Col>
                                <Col lgOffset={1} lg={4}>

                                    <Link to='/Pages/Venmo' onClick={()=> gaEventTracker('Venmo case study')}>
                                        {isMobile ?
                                            <ProjectCardMobile
                                                Theme={"#5d93d8"}
                                                TagColor={"#2F80ED"}
                                                ProjectImage={ImageVenmo}
                                                ProjTitle={"Redesigning Venmo's payments flow"}
                                                ProjDescription={"Redesigning Venmo to be more efficient in terms of transactions, as well as conceptualizing a potential recurring payments feature and exploring how it fits within Venmo's current ecosystem. I was asked to redesign a finance based app for an interview, and this is my solution."}
                                                TagWork={"Side project"}
                                                TagLocation={"Challenge"}
                                            ></ProjectCardMobile>
                                            :
                                            <ProjectCard
                                                Theme={"#5d93d8"}
                                                TagColor={"#2F80ED"}
                                                ProjectImage={ImageVenmo}
                                                ProjTitle={"Redesigning Venmo's payments flow"}
                                                ProjDescription={"Redesigning Venmo to be more efficient in terms of transactions, as well as conceptualizing a potential recurring payments feature and exploring how it fits within Venmo's current ecosystem. I was asked to redesign a finance based app for an interview, and this is my solution."}
                                                TagWork={"Side project"}
                                                TagLocation={"Challenge"}
                                            ></ProjectCard>


                                        }
                                    </Link>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    {/** OLD STYLE oF divvying up divs */}

                    <Row middle="lg" style={{ marginTop: 64 }}>
                        <Col xs={12}>
                            <Row >
                                <Col lgOffset={1} lg={6}>
                                <video style={styles.PhotoContainerProject} src={QuickDesignWalkthrough} controls autoPlay loop></video>                                        
                                    <p style={styles.SideWorkHeader}><b>Quick demo of the Wizehire design system</b>, <u>implemented and created for consumption by yours truly</u>. I was able to contribute to this effort alongside our head of product, which included (not an exhaustive list) auditing the current experience, identifying current + future components, as well as crafting individual components and documentation (visual QA, product design) in a tight feedback loop.</p>

                                </Col>
                  
                            </Row>
                        </Col>
                    </Row>


                </motion.div>
                <Row start="lg" style={{ marginTop: 128 }}>
                    <Col lg={12} lgOffset={1}>
                        <p style={styles.FooterText}>Built with <a style={{ padding: 4 }}>&#x1F499;</a> + <a style={{ padding: 4 }}>&#x2615;</a> in San Diego using React (Typescript) and Framer Motion.</p>
                    </Col>
                </Row>
            </Grid>
        </motion.div>
    )
}

export default Home;
