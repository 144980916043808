import * as React from "react"
import { render } from "react-dom"
import { Frame } from "framer"
import { motion } from "framer-motion"



export function NavBar(_props: any) {

    // general styles for navigation bar

    //props to have
    const {
        Active, Text 
    } = _props

    //functions for conditional rendering

    const styles = {

        NavContainer: {

            active: {
                width: 120,
                height: 74,
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-evenly",
                alignItems: "center",
                overflow: "hidden",
                background: "#27292D",


            },
            default: {
                width: 120,
                height: 74,
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-evenly",
                alignItems: "center",
                overflow: "hidden",
                background: "#27292D",

            },
        } as const,

        LinkOne: {
            active: {
                width: 78,
                height: 19,
                marginTop: 32,
                overflow: "visible",
                fontFamily: `"IBM Plex Mono", monospace`,
                color: "rgba(255, 255, 255, 0.9)",
                fontSize: 16,
                letterSpacing: 0,
                lineHeight: 1.2,
                textDecoration: "underline",
                fontWeight: 700,
                fontStyle: "normal",
                textAlign: "center",
                background: "#27292D",
                duration: 0.5,
                type: "ease",
                ease: [0.22, 1, 0.36, 1],



            },
            default: {
                width: 78,
                marginTop: 32,
                height: 19,
                overflow: "visible",
                fontFamily: `"IBM Plex Mono", monospace`,
                color: "rgba(255, 255, 255, 0.9)",
                fontSize: 16,
                letterSpacing: 0,
                lineHeight: 1.2,
                fontWeight: 400,
                fontStyle: "normal",
                textAlign: "center",
                background: "#27292D",
                duration: 0.5,
                type: "ease",
                ease: [0.22, 1, 0.36, 1],


            }
        } as const,

        LinkTwo: {
            active: {
                width: 78,
                height: 19,
                overflow: "visible",
                fontFamily: `"IBM Plex Mono", monospace`,
                color: "rgba(255, 255, 255, 0.9)",
                fontSize: 16,
                letterSpacing: 0,
                lineHeight: 1.2,
                fontStyle: "normal",
                textAlign: "center",
                background: "#27292D",
                textDecoration: "underline",
                fontWeight: 700,
                type: "ease",
                ease: [0.22, 1, 0.36, 1],
                duration: 0.1,
            },

            default: {
                width: 78,
                height: 19,
                overflow: "visible",
                fontFamily: `"IBM Plex Mono", monospace`,
                color: "rgba(255, 255, 255, 0.9)",
                fontSize: 16,
                letterSpacing: 0,
                lineHeight: 1.2,
                fontWeight: 400,
                fontStyle: "normal",
                textAlign: "center",
                background: "#27292D",
                textDecoration: "none",
                type: "ease",
                ease: [0.22, 1, 0.36, 1],
                duration: 0.1,
            },
        } as const,



    }

    if (Active){
        return (
        
            <motion.div
            >
                <Frame style={styles.LinkOne.active} variants={styles.LinkOne} whileHover="active">{Text}</Frame>
            </motion.div>
    );

    }
    else {
        return (
        
            <motion.div
            >
                <Frame style={styles.LinkOne.default} variants={styles.LinkOne} whileHover="active">{Text}</Frame>
            </motion.div>
    );

    }


}

NavBar.defaultProps = {
    Active: true,
    Text: "Home",

}

const rootElement = document.getElementById("root")
render(<NavBar />, rootElement)
