import * as React from "react"
import '../grid.css'
import { motion } from "framer-motion"

import { Link } from 'react-router-dom'
import { NavBar } from '../Components/NavBar'

import { Grid, Row, Col } from "react-flexbox-grid"
//adding support for IBM Plex Mono as a font.

import ImageMango from '../Assets/Mango/Mango-mockup.png'

//image imports for Mango case
import JoyPersona from '../Assets/Mango/persona-1.png'
import PrachetanPersona from '../Assets/Mango/persona-2.png'
import SusanPersona from '../Assets/Mango/persona-3.png'
import CurrentMangoHome from '../Assets/Mango/current-screen.png'

import CurrentInbox from '../Assets/Mango/curr_inbox.jpeg'
import RedesignedHome from '../Assets/Mango/Home.png'
import AddMedicationFlow from '../Assets/Mango/add-med-flow.png'
import MyMeds from '../Assets/Mango/My-Meds.png'
import MyHabits from '../Assets/Mango/My-Habits.png'
import SpecificMedInfo from '../Assets/Mango/Specific-med-1.0.png'
import SpecificMedAdherence from '../Assets/Mango/Specific-med-2.0.png'
import Styleguide from '../Assets/Mango/styleguide-UI.png'

import useAnalyticsEventTracker from '../useAnalyticsEventTracker';


import "fontsource-jost"

//adding CSS styles inline, as we do
const styles = {
    Page: { backgroundColor: "#27292D" },

    TopTitle: {
        fontFamily: `"IBM Plex Mono", monospace`,
        color: "rgba(255, 255, 255, 0.9)",
        fontSize: "1.25em",
        letterSpacing: "0.0001em",
        lineHeight: 1.2,
        fontWeight: 500,
        fontStyle: "normal",

    } as const,

    HeroDescription: {
        fontFamily: `"Inter", sans-serif`,
        color: "rgba(255, 255, 255, 0.9)",
        fontSize: "1.25em",
        letterSpacing: "0.00625em",
        lineHeight: 1.65,

    } as const,

    HeroDescriptionBold: {
        fontFamily: `"Inter", sans-serif`,
        color: "rgba(255, 255, 255, 0.9)",
        fontSize: "1.25em",
        letterSpacing: "0.00625em",
        lineHeight: 1.65,
        fontWeight: 700,

    } as const,

    HeroContainer: {
        width: 424,
        height: 74,
        marginTop: 64,
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-evenly",
        alignItems: "center",
        overflow: "visible",
        background: "#27292D",
    } as const,


    Style: {
        marginTop: 72,
        width: "100%",
    } as const,

    // styles for text and images, etc. 
    PhotoContainer: {
        maxWidth: "100%",
        overflow: "hidden",
        borderRadius: 4,
        margin: "24px 24px 24px 24px",

    } as const,

    ProjectIntro: {
        fontFamily: `"IBM Plex Mono", monospace`,
        color: "rgba(255, 255, 255, 0.9)",
        fontSize: "1.563rem",
        letterSpacing: "0.00625em",
        lineHeight: 1.2,
        fontWeight: 700,
        alignText: "center",
        paddingLeft: 0,
        paddingBottom: 32,
        paddingTop: 16,
    } as const,

    ProjectHeaderContainer: {
        width: "100%",
        height: 64,
        paddingBottom: 48,
        alignItems: "center",
        margin: "24px 24px 24px 24px",

    } as const,

    OverviewChip: {
        boxSizing: "border-box",
        width: "40%",
        height: 24,
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-evenly",
        alignItems: "center",
        padding: 16,
        overflow: "hidden",
        backgroundColor: "#343434",
        borderRadius: 4,
        fontFamily: `"IBM Plex Mono", monospace`,
        color: "#FFF",
        fontSize: "0.9em",
        letterSpacing: "0.00625em",
        lineHeight: 1.2,
        fontWeight: 700,
        fontStyle: "normal",
        textAlign: "center",
        marginBottom: "16px",
    } as const,

    OverviewChipType: {
        boxSizing: "border-box",
        width: "40%",
        height: 24,
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-evenly",
        alignItems: "center",
        padding: 16,
        overflow: "hidden",
        backgroundColor: "#EF6C3C",
        borderRadius: 4,
        fontFamily: `"IBM Plex Mono", monospace`,
        color: "#FFF",
        fontSize: "0.9em",
        letterSpacing: "0.00625em",
        lineHeight: 1.2,
        fontWeight: 700,
        fontStyle: "normal",
        textAlign: "center",
        marginBottom: "16px",
    } as const,
    OverviewChipWrapper: {
        boxSizing: "border-box",
        width: "100%",
        height: 24,
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-evenly",
        alignItems: "left",
        padding: "16px 16px 16px 0px",
        overflow: "visible",
        backgroundColor: "rgba(255, 255, 255,0)",
        borderRadius: 4,
        fontFamily: `"Inter", sans-serif`,
        color: "#FFF",
        fontSize: 16,
        letterSpacing: "0.00625em",
        lineHeight: 1.2,
        fontWeight: 700,
        fontStyle: "normal",
        textAlign: "center",
        marginBottom: "16px",
    } as const,

    OverviewTitle: {
        fontFamily: `"IBM Plex Mono", monospace`,
        color: "rgba(255, 255, 255, 0.9)",
        fontSize: "1.563rem",
        letterSpacing: "0.00625em",
        lineHeight: 1.2,
        fontWeight: 700,

    } as const,

    OverviewText: {
        fontFamily: `"Inter", sans-serif`,
        color: "rgba(255, 255, 255, 0.9)",
        fontSize: "1.25rem",
        letterSpacing: "0.00625em",
        lineHeight: 1.2,
        fontWeight: 400,

    } as const,

    SectionHeader: {
        fontFamily: `"IBM Plex Mono", monospace`,
        color: "rgba(255, 255, 255, 0.9)",
        fontSize: "1.563rem",
        letterSpacing: "0.00625em",
        lineHeight: 1.2,
        fontWeight: 700,
        minWidth: "400px",
    },

    SectionBlurb: {
        fontFamily: `"IBM Plex Mono", monospace`,
        color: "rgba(255, 255, 255, 0.9)",
        fontSize: "1rem",
        letterSpacing: "0.00625em",
        lineHeight: 1.2,
        fontWeight: 400,
        minWidth: "340px",
    },

    SectionTitle: {
        fontFamily: `"IBM Plex Mono", monospace`,
        color: "rgba(255, 255, 255, 0.9)",
        fontSize: "1.25rem",
        letterSpacing: "0.00625em",
        lineHeight: 1.2,
        fontWeight: 700,

    },

    SectionText: {

    },

    PhotoContainerProject: {
        maxWidth: "100%",
        maxHeight: "900px",
        overflow: "visible",
        margin: "24px 24px 0px 0px"
    } as const,



    ImageCaption: {
        fontFamily: `"Inter", sans-serif`,
        color: "rgba(255, 255, 255, 0.6)",
        fontSize: "1.0rem",
        letterSpacing: "0.00625em",
        lineHeight: 1.2,
        fontWeight: 600,
    } as const,



}

//variants for inline links 

const InlineLink = {

    initial: {
        textDecoration: "none",

        opacity: "75%"
    },
    hover: {
        textDecoration: "underline",

        opacity: "100%"
    },


} as const


// variants for motion div (TITLE) -

const titleVariants = {
    initial: {
        opacity: 1,
        x: 0,
        fontSize: "3.815em",
    },
    in: {
        opacity: 1,
        x: 0,
        transition: {
            type: "ease",
            ease: [0.22, 1, 0.36, 1],
            duration: 0.75,
            x: { stiffness: 100, velocity: -100 },
        },
        fontSize: "1.563em",
    },
    out: {
        opacity: 1,
        x: "100vw",
    },
}



function Mango(): JSX.Element {
const gaEventTracker = useAnalyticsEventTracker('Mango case study page')

    return (
        <Grid fluid>
            <Row top="lg" around="lg">
                <Col lg={5} lgOffset={1}>
                    <motion.div
                        style={styles.Style}
                        initial="initial"
                        animate="in"
                        exit="out"
                        variants={titleVariants}
                    >
                        <p style={styles.TopTitle}>Chaitanya Prashant</p>

                    </motion.div>

                </Col>
                <Col lg={1} lgOffset={2} xs={3} xsOffset={2} style={{ marginTop: 48 }}>
                    <Link to='/' onClick={()=> gaEventTracker('Homepage top nav')}>
                        <NavBar
                            Active={false}
                        />
                    </Link>
                </Col>
                <Col lg={1} xs={4} style={{ marginTop: 48 }}>
                    <Link to='/Pages/About' onClick={()=> gaEventTracker('About page top nav')}>
                        <NavBar
                            Active={false}
                            Text="About Me"
                        />

                    </Link>
                </Col>
            </Row>


            <motion.div>

                <Row start="lg" style={{ marginTop: 64 }}>
                    <Col xs={10} md={6} mdOffset={3} lg={6} lgOffset={3}>
                        <motion.div style={styles.ProjectHeaderContainer}>
                            <motion.div style={{ width: "100%", textAlign: "center" }}>
                                <p style={styles.ProjectIntro}>Mango Health</p>
                            </motion.div>
                            <motion.div
                                style={styles.OverviewChipWrapper}
                            >
                                <motion.div
                                    style={styles.OverviewChip}
                                >Side Project (challenge)</motion.div>
                                <motion.div
                                    style={styles.OverviewChipType}
                                >2019</motion.div>
                            </motion.div>

                        </motion.div>

                    </Col>
                </Row>


                <Row start="lg" style={{ marginTop: 64 }}>
                    <Col xs={10} lg={6} lgOffset={3}>
                        <motion.div
                        >
                            <img style={styles.PhotoContainer} src={ImageMango} alt="Mango Screens" />
                        </motion.div>

                    </Col>
                </Row>
            </motion.div>




            <Row start="lg">
                <Col xs={10} xsOffset={1} lg={5} lgOffset={1}>
                    <motion.div>
                        <p style={styles.OverviewTitle}>Overview</p>
                        <p style={styles.OverviewText}>Redesigning Mango Health to help people regulate their medication.</p>
                        <p style={styles.OverviewText}>Note: Done for the KP fellows application, I am in no way affiliated with Mango Health.</p>
                        <p style={styles.OverviewText}><b>Selected as a KP Design fellows finalist</b></p>

                    </motion.div>
                    <motion.div>
                        <p style={styles.OverviewTitle}>Role</p>
                        <p style={styles.OverviewText}>Solo designer and researcher.</p>
                    </motion.div>
                    <motion.div>
                        <p style={styles.OverviewTitle}>Timeline</p>
                        <p style={styles.OverviewText}>2 weeks in January 2019</p>
                    </motion.div>

                </Col>
                <Col xs={10} xsOffset={1} lg={5} lgOffset={1}>
                    <motion.div>
                        <p style={styles.OverviewTitle}>Responsibilities</p>
                        <p style={styles.OverviewText}>Design research, interaction and visual design, and product thinking.</p>
                    </motion.div>
                    <motion.div>
                        <p style={styles.OverviewTitle}>Team</p>
                        <ul style={styles.OverviewText}>
                            <li>Chaitanya Prashant - Designer and researcher</li>
                        </ul>
                    </motion.div>
                </Col>

            </Row>

            <Row start="lg" style={{ marginTop: "72px" }}>
                <Col xs={12} lg={2} lgOffset={1}>
                    <motion.div>
                        <p style={styles.SectionHeader}>Introduction</p>

                    </motion.div>
                </Col>
                <Col xs={12} lg={6} lgOffset={2}>
                    <motion.div>
                        <p style={styles.HeroDescription}>For my application to the KP Fellows program, the prompt seemed simple enough, yet represented an interesting challenge.</p>
                        <p style={styles.HeroDescriptionBold}>Redesign a feature of a KP Portfolio Product</p>
                        <p style={styles.HeroDescription}>Going through KP’s impressive portfolio, I imagined daring product additions to unicorns like Airbnb and Uber, or captivating redesigns of a personal favorite, Spotify. However, I eventually settled on Mango Health.</p>
                        <p style={styles.HeroDescription}>Mango Health aims to solve a pressing problem within the healthcare space, which is to help people manage their medications and form healthier habits.</p>
                        <p style={styles.HeroDescription}>Mango Health, while humbler than some of the massive companies on the list, piqued my interest because of its mission to help people regulate their medication and live healthier lives. Some of my family members use similar apps, and it represented a pick where I didn’t know too much about the app coming in, but knew of the basic use cases.</p>
                        <p style={styles.HeroDescription}>Disclaimer: This redesign was done without access to actual user data or user feedback from Mango Health. These would be critical considerations in a real-world project. In order to account for this, I conducted my own secondary and primary research, which gave me a reasonable foundation for the project.</p>

                    </motion.div>
                </Col>
            </Row>
            <Row start="lg" style={{ marginTop: "72px" }}>
                <Col xs={12} lg={2} lgOffset={1}>
                    <motion.div>
                        <p style={styles.SectionHeader}>Who are the users?</p>
                    </motion.div>

                </Col>
                <Col xs={12} lg={6} lgOffset={2}>
                    <motion.div>
                        <p style={styles.HeroDescription}>Mango Health’s user base exists across a variety of demographics, and after conducting testing and reading up more about who uses Mango Health, I came up with three personas to fit the different demographics that are the most represented for the product. I will be presenting the story of my redesign through the lens of these three users</p>
                        <p style={styles.SectionTitle}>Joy</p>
                        <Row>
                            <Col xs={12} lg={12}>
                                <img style={styles.PhotoContainerProject} src={JoyPersona} alt="JoyPersona" />
                                <p style={styles.ImageCaption}>20 year old college student</p>
                                <p style={styles.ImageCaption}>Still getting used to the idea of taking regular medication, but isn’t as serious as she should be.</p>
                                <p style={styles.ImageCaption}>Has an unforgiving schedule and wants to be able to see what she needs to take quickly.</p>
                                <p style={styles.ImageCaption}>Wants to establish healthy lifestyle habits.</p>

                            </Col>
                        </Row>
                        <p style={styles.SectionTitle}>Prachetan</p>
                        <Row>
                            <Col xs={12} lg={12}>
                                <img style={styles.PhotoContainerProject} src={PrachetanPersona} alt="PrachetanPersona" />
                                <p style={styles.ImageCaption}>Middle aged, working parent</p>
                                <p style={styles.ImageCaption}>Likes getting reminders about medication on the go.</p>
                                <p style={styles.ImageCaption}>Journals his mood and eating habits to adopt a healthier perspective on life.</p>

                            </Col>
                        </Row>
                        <p style={styles.SectionTitle}>Susan</p>
                        <Row>
                            <Col xs={12} lg={12}>
                                <img style={styles.PhotoContainerProject} src={SusanPersona} alt="SusanPersona" />
                                <p style={styles.ImageCaption}>Retired architect</p>
                                <p style={styles.ImageCaption}>Is forgetful about what pills she has to take and when.</p>
                                <p style={styles.ImageCaption}>Gets frustrated by her reminders app very easily.</p>

                            </Col>
                        </Row>


                    </motion.div>

                </Col>

            </Row>

            <Row start="lg" style={{ marginTop: "72px" }}>
                <Col xs={12} lg={2} lgOffset={1}>
                    <motion.div>
                        <p style={styles.SectionHeader}>What's the problem?</p>
                    </motion.div>
                </Col>
                <Col xs={12} lg={6} lgOffset={2}>
                    <motion.div>
                    <p style={styles.HeroDescription}>Whenever I do a redesign, I aim to look at pressing, people problems to solve rather than cosmetic redesigns that don’t add tangible value.</p>
                        <p style={styles.HeroDescription}>Therefore, the first thing I did while auditing the Mango Health app was ask people (friends and family), both who have used Mango Health and new users alike, their thoughts on the app. This is to ensure that I’m not stuck in my own bubble of perspectives, but consider other people’s needs and pain points.</p>
                        <p style={styles.HeroDescriptionBold}>Some of the questions I asked included,</p>
                        <p style={styles.HeroDescription}>
                            <ul>
                                <li>How often do you use Mango Health?</li>
                                <li>Is the app successful in helping your track your medication?</li>
                                <li>What features of the app do you use the most?</li>
                                <li>Do you struggle with any of the app’s features? What would you change?</li>
                            </ul>
                        </p>
                        <p style={styles.HeroDescription}>I also conducted some short usability tests on their overall experience of using the app itself, which would help me gather design opportunities and user pain points.</p>
                        <p style={styles.HeroDescription}>After analyzing these results, as well as personally auditing the current design, I compiled a list of some of the potential design opportunities and critical user pain points within Mango Health.</p>
                        <p style={styles.SectionTitle}>Visual design and heirarchy</p>
                        <Row>
                            <Col xs={12} lg={12}>
                                <img style={styles.PhotoContainerProject} src={CurrentMangoHome} alt="CurrentMangoHome" />

                            </Col>
                        </Row>


                        <p style={styles.HeroDescription}>A common pain point while conducting usability testing of the application centered around the visual design, and it does little to aid usability.</p>
                        <p style={styles.HeroDescription}>The user needs to put in a lot of work and consume too much information in order to scan through the homepage. While the use of cards is a common visual pattern in interface design, they all seem to be fighting for attention rather than guide the user in terms of the actions they should take. Does tapping on a card take them to a particular topic? This was a question that was asked a lot during usability testing first up, and a critical problem seemed to be ambiguity for actions.</p>
                        <p style={styles.HeroDescription}>Similar concerns arise for the inbox tab, which is used for general information about different medications. While it serves as a central hub for this information, the unformatted descriptions and unclear hierarchy here can confuse the user as to what the information truly means.</p>
                        <p style={styles.SectionTitle}>Lack of personalized indication when adding medication</p>
                        <p style={styles.HeroDescription}>A behavioral pattern I noticed was that patients usually refer to their medications by their indications, such as “Time to take my blood pressure meds!” However, when a user takes a medication, the app simply says “Dose recorded”.</p>
                        <p style={styles.HeroDescription}>By personalizing the patient’s medication information and accompanying messages, however, we can increase both motivation and adherence through the principle of positive reinforcement.</p>
                        <p style={styles.SectionTitle}>Task Completion</p>
                        <p style={styles.HeroDescription}>In the current flow for completing a day’s tasks, a user has to move between four different screens before their task is recorded. In this example, users must first click on their reminder under “My Schedule”, after which they are forwarded to a list of the next week. From there, users are forwarded to the landing page for the habit/medicine selected. Finally, they click “Record Now”, for the reminder to be logged.</p>
                        <p style={styles.HeroDescription}>This flow takes multiple steps for an action that should, in essence be simple and persistent. For all three of our users, this presents a definitive problem, as completing a task becomes an arduous process, rather than one which is gratifying and motivating.</p>
                        <p style={styles.SectionTitle}>Schedule</p>
                        <p style={styles.HeroDescription}>Currently, even if a user has multiple reminders throughout the day, only the upcoming reminder shows up on the screen. While feeling restrictive, it could also be detrimental to users who might not turn on notifications or only check the app periodically.</p>
                        <p style={styles.SectionTitle}>Lack of context when taking medication</p>
                        <p style={styles.HeroDescription}>For our users who might be new to taking medication on a regular basis, this is a major pain point, as some medication have interactions that they might not always be aware of. This is especially true for Joy and Susan’s use case.</p>
                        <p style={styles.HeroDescription}>Currently, a user would have to go to the inbox or click on the specific medication to find out more about the medication’s common interactions.</p>
                        <p style={styles.SectionTitle}>Learning more about specific medications</p>
                        <Row>
                            <Col xs={12} lg={12}>
                                <img style={styles.PhotoContainerProject} src={CurrentInbox} alt="CurrentInbox" />

                            </Col>
                        </Row>

                        <p style={styles.HeroDescription}>To currently learn about a specific medication, one has to either go to the inbox (which has unformatted information galore), or the specific medication, where the detail tab only shows the instructions for the medication. To learn about its interactions however, the user goes to the alerts section, where the information is unformatted and is simply a link to another page. For a user who wants to quickly learn about a specific medication, this can serve as a hindrance to their experience.</p>


                    </motion.div>
                </Col>
            </Row>

            <Row start="lg" style={{ marginTop: "72px" }}>
                <Col xs={12} lg={2} lgOffset={1}>
                    <motion.div>
                        <p style={styles.SectionHeader}>Solution</p>
                    </motion.div>
                </Col>
                <Col xs={12} lg={6} lgOffset={2}>
                    <motion.div>
                        <p style={styles.SectionTitle}>Homepage</p>
                        <Row>
                            <Col xs={12} lg={12}>
                                <img style={styles.PhotoContainerProject} src={RedesignedHome} alt="RedesignedHome" />
                                <p style={styles.ImageCaption}>Redesigned home page</p>
                            </Col>
                        </Row>

                        <p style={styles.HeroDescription}>In the redesigned homepage, I maintain the main call to action, which is to add a medicine, habit or care recipient at the top of the nav-bar, much like the previous design, as it follows iOS design patterns and is clear to the user.</p>
                        <p style={styles.HeroDescription}>I employ a card based approach, but instead of pushing cards that are fighting for attention, I decided to dedicate each card to take the full width of a screen, with larger headers emphasizing both visual consistency and clarity.</p>
                        <p style={styles.HeroDescription}>I surface Mango Health’s streak based information first up, as Mango Health’s original value proposition is to “gamify” healthcare, and this sort of positive reinforcement aims to motivate the user to adhere to their schedule!</p>

                        <p style={styles.HeroDescription}>For reminders and medicines currently on the user’s schedule to take immediately, I surface that information in the “Take Now” card, which aims to motivate the user to record their medication with a simple, yet familiar interaction of marking the checkbox. Each reminder is equipped with dosage information and medication notes, important contextual information that is especially important for users who might forget dangerous interactions or specific instructions about the medication.</p>
                        <p style={styles.HeroDescription}>Upcoming tasks are surfaced horizontally for the user to clearly view what the user has up next, including what time it’s coming up, so that the user has a clear idea about their schedule at a glance. A call to action to manage reminders is available from the homepage if the user wants to manage and edit specific reminders.</p>
                        <p style={styles.HeroDescription}>Similarly, points are surfaced with a clear measure of progress, as well as added information for Mango Health’s raffle feature, which can act as another motivating factor for the user to adhere to their schedule!</p>
                        <p style={styles.SectionTitle}>Adding a new medication</p>
                        <Row>
                            <Col xs={12} lg={12}>
                                <img style={styles.PhotoContainerProject} src={AddMedicationFlow} alt="AddMedicationFlow" />
                                <p style={styles.ImageCaption}>Interaction flow for adding a medication</p>
                            </Col>
                        </Row>

                        <p style={styles.HeroDescription}>While Mango Health’s current flow for adding a medication is fairly easy, one aspect it lacks is personalization to each patient’s specific case. As mentioned in previous research, some patients don’t know why there are taking their meds. Although there is a note field for the added medication, is it ideal?</p>
                        <p style={styles.HeroDescription}>Personalizing the patient’s medical information increases patient motivation by letting them be mindful of exactly why they might be taking a particular medication, which in turn would help their adherence.</p>

                        <p style={styles.SectionTitle}>Separating My Health into My Meds and My Habits</p>

                        <Row>
                            <Col xs={12} lg={6}>
                                <img style={styles.PhotoContainerProject} src={MyMeds} alt="MyMeds" />

                            </Col>
                            <Col xs={12} lg={6}>
                                <img style={styles.PhotoContainerProject} src={MyHabits} alt="MyHabits" />

                            </Col>
                            <p style={styles.ImageCaption}>Separated screens for a user's medication and habits.</p>
                        </Row>

                        <p style={styles.HeroDescription}>The decision for the split was mainly due to habits and medications being inherently different things. While medications require specific dosage information, and have a set of instructions, habits are a much more personal recording experience for the user, where they keep a log of their habits and can chart progress and continue to become healthier individuals. This is opposed to medication, where we are simply measuring adherence in this case.</p>
                        <p style={styles.HeroDescription}>Inherent difference aside, it is especially important on mobile interfaces that we are fostering focus, especially in the case of Joy and Susan.</p>
                        <p style={styles.SectionTitle}>Specific Medication</p>

                        <Row>
                            <Col xs={12} lg={6}>
                                <img style={styles.PhotoContainerProject} src={SpecificMedInfo} alt="SpecificMedInfo" />

                            </Col>
                            <Col xs={12} lg={6}>
                                <img style={styles.PhotoContainerProject} src={SpecificMedAdherence} alt="SpecificMedAdherence" />

                            </Col>
                            <p style={styles.ImageCaption}>Specific medication page with physical information and adherence metrics.</p>
                        </Row>

                        <p style={styles.HeroDescription}>I adopt a similar card based approach to specific medications as well, with the top card showcasing a clear call to action to take now, or edit your reminder on the nav bar. A swipe to the right showcases adherence metrics in an easy to compare, yet visual manner which aims to keep the user motivated about their meds.</p>
                        <p style={styles.HeroDescription}>A clear instructions card provides the user with dosage information as well as when their reminder is set in an easy to access way.</p>

                        <p style={styles.HeroDescription}>Inbox specific information, which was unformatted and linked to different pages, are now specific cards as well, offering contextual information to the user about the drug, and if the user wants to find out more, they can tap on the dropdown to reveal more detailed information.</p>
                        <p style={styles.HeroDescription}>Consolidating drug based information into a central hub, creates a more persistent way for users to interact with a specific medication and learn more about it, with the different kinds of information clearly defined and easy to find.</p>
                        <p style={styles.SectionTitle}>Visual Design</p>
                        <Row>
                            <Col xs={12} lg={12}>
                                <img style={styles.PhotoContainerProject} src={Styleguide} alt="UI Styleguide" />
                                <p style={styles.ImageCaption}>Final visual style guide</p>
                            </Col>
                        </Row>

                        <p style={styles.HeroDescription}>In terms of the overall visual design direction, I aimed to keep it consistent and minimal, as our target user group needs information surfaced in a way that is clear and doesn’t provide cognitive overload for the user, which was a common issue in the current design of the app.</p>
                        <p style={styles.HeroDescription}>In terms of the current design of Mango Health, there were a lot of inconsistencies where font sizes, formatting, and visual spacing were concerned. Therefore, I used a concise visual styleguide, alongside an 8pt grid which aided visual consistency. A constraint I placed on myself was to not stray away from Mango Health’s branding. As an established company, I was wary of making any changes to brand assets and the logo, instead aiming to incorporate it in a simplified manner.</p>



                    </motion.div>
                </Col>
            </Row>



          

          

            <Row start="lg" style={{ marginTop: "72px" }}>
                <Col xs={12} lg={2} lgOffset={1}>
                    <motion.div>
                        <p style={styles.SectionHeader}>Final thoughts</p>
                    </motion.div>
                </Col>
                <Col xs={12} lg={6} lgOffset={2}>
                    <motion.div>
                    <p style={styles.HeroDescription}>
                            <ol>
                                <li><b>Constraints matter:</b> For my redesign, I wanted to stay true to the Mango brand, and make sure to not introduce any new features or visual patterns that would be blue sky solutions to user problems and unfeasible. Instead, by focusing on interaction and product design, I was able to scope the problem space much better, which allowed me to come up with a stronger design solution..</li>
                                <li><b>Mango health is onto something:</b> Cracking that intractable seeming problem, that of healthcare adherence, is a tough one. Taking on this project made me gain a lot of appreciation for the problems that are being solved at Mango, and made me think deeper about how technology can be used to augment, if not completely serve as a driver, for medical adherence.</li>
                                <li><b>There's no substitute for teamwork:</b> I would have loved to collaborate with Mango designers, engineers and users to deeply understand the potential tradeoffs involved. A lot of my designs were based on cursory research and designer assumption, rather than solid data, which would have been fantastic to leverage for this exercise, and would have surely lead to a stronger solution.</li>
                                <li><b>Embrace the uncertain:</b> To be honest, I am usually very apprehensive about redesigning an existing product that is already successful, because I might be stepping over previous decisions made by designers backed with data. Therefore, the best I could do to make up for the lack of data was conduct primary and secondary research that would give me a good foundation for the project.</li>
                            </ol>
                        </p>
                        <p style={styles.HeroDescription}>Thank you for reading through all of that! If you have any feedback, don't hesistate to reach out at chaitanyaprashant@gmail.com.</p>

                    </motion.div>
                </Col>
            </Row>




            <Row start="lg" style={{ marginTop: 32 }}>
                <Col xs={12} lg={5} lgOffset={1}>

                    <motion.div style={{ paddingBottom: 64 }}>
                        <motion.a initial="initial" whileHover="hover" variants={InlineLink} style={styles.SectionBlurb} href="/" onClick={()=> gaEventTracker('Home page footer')}>Go back home</motion.a>

                    </motion.div>

                </Col>
            </Row>
        </Grid>



    )
}

export default Mango;