import * as React from "react"
import '../grid.css'
import { motion } from "framer-motion"

import { Link } from 'react-router-dom'
import { NavBar } from '../Components/NavBar'

import { Grid, Row, Col } from "react-flexbox-grid"
//adding support for IBM Plex Mono as a font.
import ImageWizehire from '../Assets/Wizehire/Wizehire-big-mockup.png'
import WizehireFinalScreenshot from '../Assets/Wizehire/Wizehire-growth-final.png'
import WizehireOld from '../Assets/Wizehire/Old-dashboard-design.png'
import WizehireLegacyReferral from '../Assets/Wizehire/Legacy-referrals.png'


// <video style={styles.PhotoContainerProject} src={FinalCadencePrototype} controls></video>


//all other imports
import WizehireReferralsEntry from '../Assets/Wizehire/Wizehire-assets/New-referrals.png'
import ProposedUserFlow from '../Assets/Wizehire/Wizehire-assets/User-flows.png'
import SettingsIA from '../Assets/Wizehire/Wizehire-assets/Wizehire-settings-IA.png'
import CalloutWireframe from '../Assets/Wizehire/Wizehire-assets/Lo-fi-callout.png'
import LayoutVariantV1 from '../Assets/Wizehire/Wizehire-assets/Dashboard-variant-1.png'
import LayoutVariantV2 from '../Assets/Wizehire/Wizehire-assets/Dashboard-variant-2.png'
import FinalEntryPointDesign from '../Assets/Wizehire/Wizehire-assets/Final-callout-dashboard.png'
import HiringVariantV1 from '../Assets/Wizehire/Wizehire-assets/Hiring-variant-1.png'
import HiringVariantV2 from '../Assets/Wizehire/Wizehire-assets/Hiring-variant-2.png'
import ReferralProtoFade from '../Assets/Wizehire/Wizehire-assets/Wizehire-referral-fade.mov'
import ReferralProtoHorizontal from '../Assets/Wizehire/Wizehire-assets/Wizehire-referral-horizontal.mov'
import ReferralProtoVertical from '../Assets/Wizehire/Wizehire-assets/Wizehire-referral-vertical.mov'
import FinalCalloutSpec from '../Assets/Wizehire/Wizehire-assets/Finalized-callout-spec.png'
import FinalPrototype from '../Assets/Wizehire/Wizehire-assets/Final-shipped-referrals-project.mov'
import HoverNavScreen from '../Assets/Wizehire/Wizehire-assets/Final-Navbar.png'
import SubscriptionPage from '../Assets/Wizehire/Wizehire-assets/Subscriptions-page.png'
//(change the final figma -- shift card to right side)
import InvoicesPage from '../Assets/Wizehire/Wizehire-assets/Invoice-page.png'
import useAnalyticsEventTracker from '../useAnalyticsEventTracker';

import "fontsource-jost"

//adding CSS styles inline, as we do
const styles = {
    Page: { backgroundColor: "#27292D" },

    TopTitle: {
        fontFamily: `"IBM Plex Mono", monospace`,
        color: "rgba(255, 255, 255, 0.9)",
        fontSize: "1.25em",
        letterSpacing: "0.0001em",
        lineHeight: 1.2,
        fontWeight: 500,
        fontStyle: "normal",

    } as const,

    HeroDescription: {
        fontFamily: `"Inter", sans-serif`,
        color: "rgba(255, 255, 255, 0.9)",
        fontSize: "1.25em",
        letterSpacing: "0.00625em",
        lineHeight: 1.65,

    } as const,

    HeroDescriptionBold: {
        fontFamily: `"Inter", sans-serif`,
        color: "rgba(255, 255, 255, 0.9)",
        fontSize: "1.25em",
        letterSpacing: "0.00625em",
        lineHeight: 1.65,
        fontWeight: 700,

    } as const,

    HeroContainer: {
        width: 424,
        height: 74,
        marginTop: 64,
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-evenly",
        alignItems: "center",
        overflow: "visible",
        background: "#27292D",
    } as const,


    Style: {
        marginTop: 72,
        width: "100%",
    } as const,

    // styles for text and images, etc. 
    PhotoContainer: {
        maxWidth: "100%",
        overflow: "hidden",
        borderRadius: 4,
        margin: "24px 24px 24px 24px",

    } as const,

    ProjectIntro: {
        fontFamily: `"IBM Plex Mono", monospace`,
        color: "rgba(255, 255, 255, 0.9)",
        fontSize: "1.563rem",
        letterSpacing: "0.00625em",
        lineHeight: 1.2,
        fontWeight: 700,
        alignText: "center",
        paddingLeft: 0,
        paddingBottom: 32,
        paddingTop: 16,
    } as const,

    ProjectHeaderContainer: {
        width: "100%",
        height: 64,
        paddingBottom: 48,
        alignItems: "center",
        margin: "24px 24px 24px 24px",

    } as const,

    OverviewChip: {
        boxSizing: "border-box",
        width: "40%",
        height: 24,
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-evenly",
        alignItems: "center",
        padding: 16,
        overflow: "hidden",
        backgroundColor: "#343434",
        borderRadius: 4,
        fontFamily: `"IBM Plex Mono", monospace`,
        color: "#FFF",
        fontSize: "0.9em",
        letterSpacing: "0.00625em",
        lineHeight: 1.2,
        fontWeight: 700,
        fontStyle: "normal",
        textAlign: "center",
        marginBottom: "16px",
    } as const,

    OverviewChipType: {
        boxSizing: "border-box",
        width: "40%",
        height: 24,
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-evenly",
        alignItems: "center",
        padding: 16,
        overflow: "hidden",
        backgroundColor: "#84AF43",
        borderRadius: 4,
        fontFamily: `"IBM Plex Mono", monospace`,
        color: "#FFF",
        fontSize: "0.9em",
        letterSpacing: "0.00625em",
        lineHeight: 1.2,
        fontWeight: 700,
        fontStyle: "normal",
        textAlign: "center",
        marginBottom: "16px",
    } as const,
    OverviewChipWrapper: {
        boxSizing: "border-box",
        width: "100%",
        height: 24,
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-evenly",
        alignItems: "left",
        padding: "16px 16px 16px 0px",
        overflow: "visible",
        backgroundColor: "rgba(255, 255, 255,0)",
        borderRadius: 4,
        fontFamily: `"Inter", sans-serif`,
        color: "#FFF",
        fontSize: 16,
        letterSpacing: "0.00625em",
        lineHeight: 1.2,
        fontWeight: 700,
        fontStyle: "normal",
        textAlign: "center",
        marginBottom: "16px",
    } as const,

    OverviewTitle: {
        fontFamily: `"IBM Plex Mono", monospace`,
        color: "rgba(255, 255, 255, 0.9)",
        fontSize: "1.563rem",
        letterSpacing: "0.00625em",
        lineHeight: 1.2,
        fontWeight: 700,

    } as const,

    OverviewText: {
        fontFamily: `"Inter", sans-serif`,
        color: "rgba(255, 255, 255, 0.9)",
        fontSize: "1.25rem",
        letterSpacing: "0.00625em",
        lineHeight: 1.2,
        fontWeight: 400,

    } as const,

    SectionHeader: {
        fontFamily: `"IBM Plex Mono", monospace`,
        color: "rgba(255, 255, 255, 0.9)",
        fontSize: "1.563rem",
        letterSpacing: "0.00625em",
        lineHeight: 1.2,
        fontWeight: 700,
        minWidth: "400px",
    },

    SectionBlurb: {
        fontFamily: `"IBM Plex Mono", monospace`,
        color: "rgba(255, 255, 255, 0.9)",
        fontSize: "1rem",
        letterSpacing: "0.00625em",
        lineHeight: 1.2,
        fontWeight: 400,
        minWidth: "340px",
    },

    SectionTitle: {
        fontFamily: `"IBM Plex Mono", monospace`,
        color: "rgba(255, 255, 255, 0.9)",
        fontSize: "1.25rem",
        letterSpacing: "0.00625em",
        lineHeight: 1.2,
        fontWeight: 700,

    },

    SectionText: {

    },

    PhotoContainerProject: {
        maxWidth: "100%",
        maxHeight: "900px",
        overflow: "visible",
        margin: "24px 24px 0px 0px"
    } as const,



    ImageCaption: {
        fontFamily: `"Inter", sans-serif`,
        color: "rgba(255, 255, 255, 0.6)",
        fontSize: "1.0rem",
        letterSpacing: "0.00625em",
        lineHeight: 1.2,
        fontWeight: 600,
    } as const,

}

//variants for inline links 

const InlineLink = {

    initial: {
        textDecoration: "none",

        opacity: "75%"
    },
    hover: {
        textDecoration: "underline",

        opacity: "100%"
    },


} as const


// variants for motion div (TITLE) -

const titleVariants = {
    initial: {
        opacity: 1,
        x: 0,
        fontSize: "3.815em",
    },
    in: {
        opacity: 1,
        x: 0,
        transition: {
            type: "ease",
            ease: [0.22, 1, 0.36, 1],
            duration: 0.75,
            x: { stiffness: 100, velocity: -100 },
        },
        fontSize: "1.563em",
    },
    out: {
        opacity: 1,
        x: "100vw",
    },
}



function Wizehire(): JSX.Element {
    const gaEventTracker = useAnalyticsEventTracker('Wizehire case study page')

    return (
        <Grid fluid>
            <Row top="lg" around="lg">
                <Col lg={5} lgOffset={1}>
                    <motion.div
                        style={styles.Style}
                        initial="initial"
                        animate="in"
                        exit="out"
                        variants={titleVariants}
                    >
                        <p style={styles.TopTitle}>Chaitanya Prashant</p>

                    </motion.div>

                </Col>
                <Col lg={1} lgOffset={2} xs={3} xsOffset={2} style={{ marginTop: 48 }}>
                    <Link to='/' onClick={()=> gaEventTracker('Home page top nav')}>
                        <NavBar
                            Active={false}
                        />
                    </Link>
                </Col>
                <Col lg={1} xs={4} style={{ marginTop: 48 }}>
                    <Link to='/Pages/About' onClick={()=> gaEventTracker('About page top nav')}>
                        <NavBar
                            Active={false}
                            Text="About Me"
                        />

                    </Link>
                </Col>
            </Row>


            <motion.div>

                <Row start="lg" style={{ marginTop: 64 }}>
                    <Col xs={10} md={6} mdOffset={3} lg={6} lgOffset={3}>
                        <motion.div style={styles.ProjectHeaderContainer}>
                            <motion.div style={{ width: "100%", textAlign: "center" }}>
                                <p style={styles.ProjectIntro}>Designing for growth at Wizehire</p>
                            </motion.div>
                            <motion.div
                                style={styles.OverviewChipWrapper}
                            >
                                <motion.div
                                    style={styles.OverviewChip}
                                >Internship</motion.div>
                                <motion.div
                                    style={styles.OverviewChipType}
                                >Summer 2019</motion.div>
                            </motion.div>

                        </motion.div>

                    </Col>
                </Row>


                <Row start="lg" style={{ marginTop: 64 }}>
                    <Col xs={10} lg={6} lgOffset={3}>
                        <motion.div
                        >
                            <img style={styles.PhotoContainer} src={ImageWizehire} alt="Wizehire Dashboard" />
                        </motion.div>

                    </Col>
                </Row>
            </motion.div>




            <Row start="lg">
                <Col xs={10} xsOffset={1} lg={5} lgOffset={1}>
                    <motion.div>
                        <p style={styles.OverviewTitle}>Overview</p>
                        <p style={styles.OverviewText}>Designing a new referral experience within the Wizehire ATS to increase new user growth and aid monetization.</p>
                    </motion.div>
                    <motion.div>
                        <p style={styles.OverviewTitle}>Role</p>
                        <p style={styles.OverviewText}>Lead designer on a cross functional team.</p>
                    </motion.div>
                    <motion.div>
                        <p style={styles.OverviewTitle}>Timeline</p>
                        <p style={styles.OverviewText}>Summer 2019 - 4 weeks</p>
                    </motion.div>

                </Col>
                <Col xs={10} xsOffset={1} lg={5} lgOffset={1}>
                    <motion.div>
                        <p style={styles.OverviewTitle}>Responsibilities</p>
                        <p style={styles.OverviewText}>Design research, interaction and visual design, and product thinking.</p>
                    </motion.div>
                    <motion.div>
                        <p style={styles.OverviewTitle}>Team</p>
                        <ul style={styles.OverviewText}>
                            <li>Chaitanya Prashant - Design</li>
                            <li>Chad Masso - Product and Engineering</li>
                            <li>Ritz Wu - Data</li>
                            <li>Leisha Scallan - Illustrator</li>
                            <li>Olive Krawczyk - Content</li>
                        </ul>
                    </motion.div>
                </Col>

            </Row>

            <Row start="lg" style={{ marginTop: "72px" }}>
                <Col xs={12} lg={2} lgOffset={1}>
                    <motion.div>
                        <p style={styles.SectionHeader}>Introduction</p>
                        <p style={styles.SectionBlurb}>This project was a growth initiative aiming to better allow SMB (small and medium business) owners, who use the Wizehire product for their hiring needs, to invite others facing similar hiring needs in their network. While I co-lead design of the feature, I worked with two product engineers, a writer, and another designer.</p>

                    </motion.div>
                </Col>
                <Col xs={12} lg={6} lgOffset={2}>
                    <motion.div>
                        <p style={styles.HeroDescription}>As my first design project focused on growth, I realized that while it is important to be informed by metrics and data, one needs to consider the broader user’s journey, instead of paywalls that could serve to be intrusive to the user.</p>
                        <p style={styles.SectionTitle}>Hypothesis</p>
                        <p style={styles.HeroDescription}>If we surface referral options to users at moments of achievement, as well as areas that are discoverable within context, it would allow users to share the value of Wizehire as a product to others within their network, driving new user growth.</p>
                        <motion.div
                            style={styles.PhotoContainerProject}
                        >
                            <img style={styles.PhotoContainerProject} src={WizehireFinalScreenshot} alt="Wizehire Dashboard" />

                            <p style={styles.ImageCaption}>Shipped experience in staging</p>

                        </motion.div>
                    </motion.div>
                </Col>
            </Row>
            <Row start="lg" style={{ marginTop: "72px" }}>
                <Col xs={12} lg={2} lgOffset={1}>
                    <motion.div>
                        <p style={styles.SectionHeader}>Understanding the problem</p>
                        <p style={styles.SectionBlurb}>tl;dr: A very small percentage of promoters (via NPS Score), 13% are responsible for 90% of the referrals from the Wizehire product. There are opportunities to surface invites in a more discoverable way so that users can share the product with other friends + family who have hiring needs, while getting $100 off after each friend signs up.</p>

                    </motion.div>

                </Col>
                <Col xs={12} lg={6} lgOffset={2}>
                    <motion.div>
                        <p style={styles.SectionTitle}>Opportunities</p>
                        <p style={styles.HeroDescription}>We aimed to validate our hypothesis with promoters first, as they are more likely to refer someone compared to a non promoter.</p>
                        <motion.div
                            style={styles.PhotoContainerProject}
                        >

                            <p style={styles.SectionTitle}>Auditing the current experience</p>
                            <p style={styles.HeroDescription}>tl;dr: An engineer and myself led an audit of the current referral experience. The audit, alongside the survey results helped us realize that most of our users weren’t discovering the referrals feature, whilst the current referrals feature suffered from a lack of consistency.</p>
                            <p style={styles.HeroDescription}>Myself and a product engineer led an audit on the current referral experience to identify areas of improvement in the referral experience, as well as analyze the different points of entry.</p>
                            <Row>
                                <Col xs={12} lg={6}>
                                    <img style={styles.PhotoContainerProject} src={WizehireLegacyReferral} alt="Wizehire Dashboard" />
                                    <p style={styles.ImageCaption}>Legacy referrals page that is shown after clicking on the callout link</p>

                                </Col>
                                <Col xs={12} lg={6}>
                                    <img style={styles.PhotoContainerProject} src={WizehireOld} alt="Wizehire Dashboard" />
                                    <p style={styles.ImageCaption}>Referral callout surfaced on the dashboard</p>

                                </Col>
                            </Row>
                        </motion.div>
                        <p style={styles.HeroDescription}>To invite a friend, a user can click on the link in the call-out, but it <b>leads to the legacy referrals page, whilst the product’s own referrals page is within settings.</b></p>

                        <p style={styles.HeroDescription}>This inconsistent experience can lead to a lack of context and trust in certain situations, with a lot of users being confused as to why they were led to a different looking product.</p>

                        <p style={styles.HeroDescription}>Another problem we identified was the lack of visibility for the referrals program, as it was only accessible with a small call-out in the sidebar navigation.</p>

                        <p style={styles.HeroDescription}>The referrals page itself is also abstracted away in the information architecture, being housed in the advanced settings page.</p>


                        <Row>
                            <Col xs={12} lg={12}>
                                <img style={styles.PhotoContainerProject} src={WizehireReferralsEntry} alt="WizehireReferralsEntry" />
                                <p style={styles.ImageCaption}>Referrals page within the product</p>

                            </Col>
                        </Row>
                        <p style={styles.SectionTitle}>Survey</p>

                        <p style={styles.HeroDescription}>I also sent out a private survey to 40 of the promoters on the product, aiming to validate some of the numbers and conclusions drawn from the audit, as well as discover why Wizehire users would refer someone to the product.</p>

                        <p style={styles.HeroDescription}>The main insight from these survey results were that referrals were only easily accessible from the sidebar, with 40% of the users not knowing that they could get to the referrals page through account settings. 90% respondents also validated that they refer Wizehire to friends due to the value they find in the product themselves.</p>


                    </motion.div>

                </Col>

            </Row>

            <Row start="lg" style={{ marginTop: "72px" }}>
                <Col xs={12} lg={2} lgOffset={1}>
                    <motion.div>
                        <p style={styles.SectionHeader}>Insights</p>
                        <p style={styles.SectionBlurb}>The audit, along with the survey helped us realize that most of our users weren’t discovering the referrals feature.</p>
                    </motion.div>
                </Col>
                <Col xs={12} lg={6} lgOffset={2}>
                    <motion.div>
                        <p style={styles.HeroDescription}>I also explored a user’s journey throughout using Wizehire to identify key touchpoints in their journey where they achieve their goal in terms of using the product, and what features they use the most. This helped us set principles for the referral experience that would serve as a north star for the project.</p>
                        <p style={styles.HeroDescription}>Note: I am currently unable to showcase the diagram due to an NDA signed with the company, however the insights our team gained were instrumental in setting goals for the entire experience, and informed our design process.</p>
                        <p style={styles.HeroDescriptionBold}>Some of the key touchpoints in the experience where referrals could have an impact included</p>
                        <p style={styles.HeroDescription}>
                            <ul>
                                <li>When the user makes a hire within the ATS</li>
                                <li>A user whilst using the dashboard (main activity hub)</li>
                                <li>When a user’s account closes (Wizehire isn’t a permanent subscription service)</li>
                                <li>New user experience for an invited user</li>
                                <li>Top level account navigation</li>
                            </ul>
                        </p>
                    </motion.div>
                </Col>
            </Row>

            <Row start="lg" style={{ marginTop: "72px" }}>
                <Col xs={12} lg={2} lgOffset={1}>
                    <motion.div>
                        <p style={styles.SectionHeader}>Goals</p>
                        <p style={styles.SectionBlurb}>What is the value add for the user and the business?</p>
                    </motion.div>
                </Col>
                <Col xs={12} lg={6} lgOffset={2}>
                    <motion.div>
                        <p style={styles.SectionTitle}>Wizehire Goals</p>
                        <p style={styles.HeroDescription}>Make it easier for Wizehire users who value the product to be able to share it with friends and their network, and retain these users.</p>
                        <p style={styles.HeroDescription}>Increase number of referrals for dormant promoters within the product</p>
                        <p style={styles.SectionTitle}>User goals</p>

                        <p style={styles.HeroDescription}>Share Wizehire's value with other people within their network who need hiring help</p>
                        <p style={styles.HeroDescription}>Use Wizehire's product at a cheaper price (through the $100 off for each friend that is referred and signed up on the platform)</p>
                        <p style={styles.SectionTitle}>Principles</p>

                        <p style={styles.HeroDescription}>We set ourselves principles or key experience goals that would guide our designs and ensure that we stayed in scope.</p>
                        <p style={styles.HeroDescription}>
                            <ul>
                                <li>Easily accessible, but not intrusive.</li>
                                <li>Scalability - How might we design the referral experience to be scalable, so that the experience is consistent and engineering workload is reduced.</li>
                                <li>Always in context - The experience shouldn't interrupt a user's flow of work within the product.</li>
                            </ul>
                        </p>
                        <p style={styles.SectionTitle}>What does success look like</p>

                        <p style={styles.HeroDescription}>An increase in the percentage of invites sent out on the platform (propensity to refer should be in the 0.5-0.6 range).</p>
                        <p style={styles.HeroDescription}>An increase in the percentage of promoters to 30-50% that are sending out invites.</p>

                    </motion.div>
                </Col>
            </Row>


            <Row start="lg" style={{ marginTop: "72px" }}>
                <Col xs={12} lg={2} lgOffset={1}>
                    <motion.div>
                        <p style={styles.SectionHeader}>Crafting the high level flow</p>
                        <p style={styles.SectionBlurb}>Framing the user flow as a set of modules helped us immensely as a team, as we were able to gain alignment on</p>
                        <p style={styles.SectionBlurb}>
                            <ul>
                                <li>High level experience goals, and how each potential touchpoint impacts the goal.</li>
                                <li>Prioritizing which design opportunities to focus on in terms of potential impact early on.</li>
                            </ul>
                        </p>
                    </motion.div>
                </Col>
                <Col xs={12} lg={6} lgOffset={2}>
                    <motion.div>
                        <motion.div
                            style={styles.PhotoContainerProject}>
                            <img style={styles.PhotoContainerProject} src={ProposedUserFlow} alt="ProposedUserFlow" />

                            <p style={styles.ImageCaption}>Proposed user flow broken down into a set of modules.</p>

                        </motion.div>
                        <p style={styles.SectionTitle}>Opportunity space - During Hiring stages</p>
                        <p style={styles.HeroDescription}>When a user makes a hire on the product, that is their greatest moment of achievement, as the goal of Wizehire is to help a user make an informed hire. We hypothesized that if we surfaced referral options at a touchpoint which provides a user value, it would drive the number of invites upwards.</p>
                        <p style={styles.SectionTitle}>Opportunity space - Surfacing referrals higher in the information architecture</p>
                        <motion.div
                            style={styles.PhotoContainerProject}>
                            <img style={styles.PhotoContainerProject} src={SettingsIA} alt="SettingsIA" />

                            <p style={styles.ImageCaption}>Opportunity to potentially surface referrals in the main account menu</p>
                        </motion.div>

                        <p style={styles.HeroDescription}>Considering our previous goal, as well as in a case where a callout is not present, surfacing referrals higher in the information architecture would always be discoverable and persistent.</p>
                        <p style={styles.SectionTitle}>Opportunity space - Dashboard</p>
                        <p style={styles.HeroDescription}>Although the previous design also had a callout on the dashboard, after conversations with stakeholders, we realized that users spend most of their dormant time within the dashboard, but weren't seeing the current referral callout.</p>
                    </motion.div>
                </Col>
            </Row>




            <Row start="lg" style={{ marginTop: "72px" }}>
                <Col xs={12} lg={2} lgOffset={1}>
                    <motion.div>
                        <p style={styles.SectionHeader}>Surfacing referrals on the dashboard</p>
                        <p style={styles.SectionBlurb}>We wanted to keep the experience scalable and non intrusive, especially on the dashboard of the product, which lead to us considering the callout. I iterated on the callout that previously existed after feedback from the team, with the goal of making it more prominent.</p>
                    </motion.div>
                </Col>
                <Col xs={12} lg={6} lgOffset={2}>
                    <motion.div>
                        <motion.div
                            style={styles.PhotoContainerProject}>
                            <img style={styles.PhotoContainerProject} src={CalloutWireframe} alt="CalloutWireframe" />

                            <p style={styles.ImageCaption}>Low fidelity design of the callout</p>

                        </motion.div>
                        <p style={styles.SectionTitle}>Selecting the layout</p>

                        <p style={styles.HeroDescription}>Our team converged on two explorations for where in the interface the referral callout would be surfaced, keeping in mind the goals we had set.</p>
                        <p style={styles.SectionTitle}>Variant 1 - Referral callout on sidebar navigation</p>
                        <motion.div
                            style={styles.PhotoContainerProject}>
                            <img style={styles.PhotoContainerProject} src={LayoutVariantV1} alt="LayoutVariantV1" />
                        </motion.div>
                        <p style={styles.SectionTitle}>PROS <a style={{ padding: 4 }}>&#x2705;</a></p>
                        <p style={styles.HeroDescription}>Always persistent in navigation</p>

                        <p style={styles.SectionTitle}>CONS <a style={{ padding: 4 }}>&#x1F6D1;</a></p>
                        <p style={styles.HeroDescription}>Loss of context in other situations of the product- i.e managing a team or evaluating and using filters for the ATS.</p>
                        <p style={styles.HeroDescription}>Visual dissonance with the existing sidebar</p>

                        <p style={styles.SectionTitle}>CONCERNS</p>
                        <p style={styles.HeroDescription}>Engineering resources - Unscalable CSS code to account for various cases in the sidebar</p>

                        <p style={styles.SectionTitle}>Variant 2 - Callout as a floating action</p>
                        <motion.div
                            style={styles.PhotoContainerProject}>
                            <img style={styles.PhotoContainerProject} src={LayoutVariantV2} alt="LayoutVariantV2" />
                        </motion.div>
                        <p style={styles.SectionTitle}>PROS <a style={{ padding: 4 }}>&#x2705;</a></p>
                        <p style={styles.HeroDescription}>Able to discover referral link without clutter in the sidebar</p>

                        <p style={styles.SectionTitle}>CONCERNS</p>
                        <p style={styles.HeroDescription}>Potentially easier for users to miss (as users read from left to right)</p>
                        <p style={styles.HeroDescription}>If a user removes the callout, is there a place for easy access to referrals?</p>
                        <p style={styles.HeroDescriptionBold}>Considering the user flow diagram above, what would the call out link to? Would it be the existing referral page? Or would we surface a modal?</p>
                        <p style={styles.HeroDescription}>After discussing this idea with our product team, we realized that surfacing a modal each time a user clicks on a callout interrupts the flow of a user within the product, while it is too little screen estate to show metrics such as amount of credits earned and number of friends invited.</p>
                        <p style={styles.HeroDescription}>Therefore, we decided that all touchpoints would lead to the referrals page itself, with that being the users’ intent.</p>
                        <p style={styles.SectionTitle}>Final design for entry point</p>
                        <motion.div
                            style={styles.PhotoContainerProject}>
                            <img style={styles.PhotoContainerProject} src={FinalEntryPointDesign} alt="FinalEntryPointDesign" />

                            <p style={styles.ImageCaption}>Note- this was after iterations on both the illustrations and copy</p>
                        </motion.div>

                        <p style={styles.HeroDescription}>We went ahead with Variant 2 for our first test, due to the engineering constraints brought forward by Chad, as well as our primary goal of keeping the module scalable and consistent across touchpoints.</p>

                    </motion.div>
                </Col>
            </Row>

            <Row start="lg" style={{ marginTop: "72px" }}>
                <Col xs={12} lg={2} lgOffset={1}>
                    <motion.div>
                        <p style={styles.SectionHeader}>Surfacing referrals within hiring stages</p>
                        <p style={styles.SectionBlurb}>Aiming to surface the option to invite a friend when a user hires, I created some design explorations with the goal of celebrating the users’ success, whilst being in context and not interruptive.</p>
                    </motion.div>
                </Col>
                <Col xs={12} lg={6} lgOffset={2}>
                    <motion.div>
                        <p style={styles.SectionTitle}>Variant 1 - Tooltip</p>
                        <motion.div
                            style={styles.PhotoContainerProject}>
                            <img style={styles.PhotoContainerProject} src={HiringVariantV1} alt="HiringVariantV1" />
                            <p style={styles.ImageCaption}>Exploration with tooltip on the top right containing referral link</p>

                        </motion.div>
                        <p style={styles.SectionTitle}>PROS <a style={{ padding: 4 }}>&#x2705;</a></p>
                        <p style={styles.HeroDescription}>Discoverable while taking significantly less screen real estate</p>

                        <p style={styles.SectionTitle}>CONS <a style={{ padding: 4 }}>&#x1F6D1;</a></p>
                        <p style={styles.HeroDescription}>Inconsistent with usage of tooltips in other areas of the product as a design pattern</p>
                        <p style={styles.SectionTitle}>Variant 2 - Callout as a floating action</p>
                        <motion.div
                            style={styles.PhotoContainerProject}>
                            <img style={styles.PhotoContainerProject} src={HiringVariantV2} alt="HiringVariantV2" />
                            <p style={styles.ImageCaption}>Callout on the right side of the screen, after a user makes a hire</p>

                        </motion.div>
                        <p style={styles.SectionTitle}>PROS <a style={{ padding: 4 }}>&#x2705;</a></p>
                        <p style={styles.HeroDescription}>Able to discover referral link without clutter in the sidebar</p>

                        <p style={styles.SectionTitle}>CONS <a style={{ padding: 4 }}>&#x1F6D1;</a></p>
                        <p style={styles.HeroDescription}>Does this interrupt the flow of work for a user?</p>
                        <p style={styles.SectionTitle}>CONCERNS</p>
                        <p style={styles.HeroDescription}>Could be jarring in a user's current context without any motion or feedback</p>
                        <p style={styles.SectionTitle}>Iterating on how the callout is surfaced when a user makes a hire</p>
                        <p style={styles.HeroDescription}>With the goal of making the experience contextual and non-intrusive, yet subtly directing attention, we experimented with different ways in which we could utilize motion in the interface.</p>

                        <p style={styles.HeroDescriptionBold}>The use case for these prototypes is when the user first moves a card to the hired section within hiring stages, indicating a hire.</p>

                        <motion.div
                            style={styles.PhotoContainerProject}>
                                 <video style={styles.PhotoContainerProject} src={ReferralProtoFade} controls></video>
                        </motion.div>
                        <motion.div
                            style={styles.PhotoContainerProject}>
                                 <video style={styles.PhotoContainerProject} src={ReferralProtoHorizontal} controls></video>
                        </motion.div>
                        <motion.div
                            style={styles.PhotoContainerProject}>
                                 <video style={styles.PhotoContainerProject} src={ReferralProtoVertical} controls></video>
                        </motion.div>

                        <p style={styles.HeroDescription}>We went ahead with the vertical exploration, after auditing other products using referrals, as well as it being not as jarring as coming in from the side, or simply appearing without any particular context.</p>

                    </motion.div>
                </Col>
            </Row>
            <Row start="lg" style={{ marginTop: "72px" }}>
                <Col xs={12} lg={2} lgOffset={1}>
                    <motion.div>
                        <p style={styles.SectionHeader}>Final design of callout module</p>
                        <p style={styles.SectionBlurb}>I went ahead and iterated on the callout with a copywriter, and worked alongside an illustrator to make the copy and illustrations of the callout contextual for each touchpoint that a user might be a part of during their journey with the product, which was an important goal of the experiment.</p>
                    </motion.div>
                </Col>
                <Col xs={12} lg={6} lgOffset={2}>
                    <motion.div>
                        <motion.div
                            style={styles.PhotoContainerProject}>
                            <img style={styles.PhotoContainerProject} src={FinalCalloutSpec} alt="FinalCalloutSpec" />

                            <p style={styles.ImageCaption}>Finalized copy and layout spec for handoff</p>

                        </motion.div>
                        <p style={styles.SectionTitle}>Demo</p>
                        <motion.div
                            style={styles.PhotoContainerProject}>
                                 <video style={styles.PhotoContainerProject} src={FinalPrototype} controls></video>
                        </motion.div>

                    </motion.div>
                </Col>
            </Row>

            <Row start="lg" style={{ marginTop: "72px" }}>
                <Col xs={12} lg={2} lgOffset={1}>
                    <motion.div>
                        <p style={styles.SectionHeader}>A place for persistent access to referrals</p>
                        <p style={styles.SectionBlurb}>We placed a link within the menu dropdown of the account profile, hypothesizing that surfacing referrals higher in the IA would lead to greater discoverability, and is a persistent area for the user to access the feature from anywhere within the product.</p>
                    </motion.div>
                </Col>
                <Col xs={12} lg={6} lgOffset={2}>
                    <motion.div>
                        <motion.div
                            style={styles.PhotoContainerProject}>
                            <img style={styles.PhotoContainerProject} src={HoverNavScreen} alt="HoverNavScreen" />

                            <p style={styles.ImageCaption}>When a user hovers over the referral link, now in the account menu</p>

                        </motion.div>
                    </motion.div>
                </Col>
            </Row>

            <Row start="lg" style={{ marginTop: "72px" }}>
                <Col xs={12} lg={2} lgOffset={1}>
                    <motion.div>
                        <p style={styles.SectionHeader}>Result and Impact</p>
                        <p style={styles.SectionBlurb}>We hypothesized that if we surface referral options to users at moments of achievement, as well as areas that are discoverable within context, it would allow users to share Wizehire as a product to others within their network more easily.</p>
                        <p style={styles.SectionBlurb}>We also predicted that designing the referral experience across various touchpoints of a user’s journey would lead to a higher number of promoters within the product to send invites.</p>
                    </motion.div>
                </Col>
                <Col xs={12} lg={6} lgOffset={2}>
                    <motion.div>
                        <p style={styles.HeroDescription}>The experiment was released on August 15th, 2019, with the goal of testing this against the previous referral experience with half of the users in the product.</p>
                        <p style={styles.HeroDescription}>The experiment was a success, resulting in a relative lift of 20% of existing promoters within 3 weeks of launch. The plan is to launch the experience to all users, and iterate on UI and copy according to analytics data with more users, as well as observe other areas of potential impact within the users' journey.</p>

                        <p style={styles.SectionTitle}>Selling design as a collective effort</p>
                        <p style={styles.HeroDescription}>As a part of an effort to make design more data informed, I pitched to a marketing engineer, as well as the CTO the benefits of setting up an analytics infrastructure, and we were able to co-ordinate this before the experiment released. Our tracking now includes a detailed breakdown of each elements' action by integrating Segment and Mixpanel.</p>

                    </motion.div>
                </Col>
            </Row>


            <Row start="lg" style={{ marginTop: "72px" }}>
                <Col xs={12} lg={2} lgOffset={1}>
                    <motion.div>
                        <p style={styles.SectionHeader}>Next Steps</p>
                        <p style={styles.SectionBlurb}>After presenting the project to be shipped, we got feedback from the sales team, who noted that while the subscription and invoice pages within the product aren’t “moments of achievement”, they still show critical information about payments, and seeing the effect of referrals on their bill could nudge them towards referring more friends.</p>
                    </motion.div>
                </Col>
                <Col xs={12} lg={6} lgOffset={2}>
                    <motion.div>
                        <p style={styles.HeroDescription}>The plan is to set up another growth experiment to see how it would influence the metrics, and I had a chance to work on some early screens of how we can surface referrals within these pages.</p>
                        <motion.div
                            style={styles.PhotoContainerProject}>
                            <img style={styles.PhotoContainerProject} src={SubscriptionPage} alt="SubscriptionPage" />

                            <p style={styles.ImageCaption}>Subscription page </p>

                        </motion.div>
                        <motion.div
                            style={styles.PhotoContainerProject}>
                            <img style={styles.PhotoContainerProject} src={InvoicesPage} alt="InvoicesPage" />

                            <p style={styles.ImageCaption}>Invoices page</p>

                        </motion.div>

                    </motion.div>
                </Col>
            </Row>

            <Row start="lg" style={{ marginTop: "72px" }}>
                <Col xs={12} lg={2} lgOffset={1}>
                    <motion.div>
                        <p style={styles.SectionHeader}>Reflection</p>
                        <p style={styles.SectionBlurb}>Being my first project focused on growth, I realized that it can be misleading and unsustainable to prefer short-term monetization instead of long term value, which is why it is so important to be data informed, rather than data driven.</p>
                    </motion.div>
                </Col>
                <Col xs={12} lg={6} lgOffset={2}>
                    <motion.div>
                        <p style={styles.HeroDescription}>Align with stakeholders early and often - This allowed me to have a good cadence in terms of feedback, as well as consider engineering constraints and align on the problem at hand.</p>
                        <p style={styles.HeroDescription}>Data informed - Designing for growth leans heavily towards experimentation, and it is absolutely critical to set up metrics of success to validate our hypothesis, enabling the team to learn quickly.</p>
                        <p style={styles.HeroDescription}>Design with the user’s journey in mind - When thinking about the long term, I found earlier on that we were experimenting to optimize a moment in the user’s journey. Instead, focusing on the user’s journey throughout the product led us to identify other areas of impact, as well as design the referral experience in a holistic manner.</p>

                    </motion.div>
                </Col>
            </Row>



            <Row start="lg" style={{ marginTop: 32 }}>
                <Col xs={12} lg={5} lgOffset={1}>

                    <motion.div style={{ paddingBottom: 64 }}>
                        <motion.a initial="initial" whileHover="hover" variants={InlineLink} style={styles.SectionBlurb} href="/" onClick={()=> gaEventTracker('Home page footer')}>Go back home</motion.a>

                    </motion.div>

                </Col>
            </Row>
        </Grid>



    )
}

export default Wizehire;