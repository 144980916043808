import React from 'react';
import './App.css';
import Home from './Pages/Home'
import ReactGA from 'react-ga4';
import { useEffect } from 'react';
const TRACKING_ID = "G-BBFV5C6WCE"; // OUR_TRACKING_ID
require("typeface-ibm-plex-mono")

// essentially, we are mounting each component onto the main App.tsx, through react-router for navigation. 
function App() {
  ReactGA.initialize(TRACKING_ID);

  useEffect(() => {
//    ReactGA.pageview(window.location.pathname + window.location.search);
    ReactGA.send({hitType: "pageview", page: window.location.pathname + window.location.search})
  }, []);



  return (
  <div className="App">
      <Home/>    
  </div>

  );
}

export default App;
