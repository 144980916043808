import * as React from "react"
import '../grid.css'
import { motion } from "framer-motion"

import { Link } from 'react-router-dom'
import { NavBar } from '../Components/NavBar'

import { Grid, Row, Col } from "react-flexbox-grid"
//adding support for IBM Plex Mono as a font.

//image imports for Venmo case
import ImageVenmo from '../Assets/Venmo/Venmo-mockup.png'
//persona imports 
import JanicePersona from '../Assets/Mango/persona-1.png'
import KartikPersona from '../Assets/Mango/persona-2.png'
//other imports
import CurrentTransactionScreen from '../Assets/Venmo/Venmo-assets/Venmo-curr.jpg'
import VenmoUserFlow from '../Assets/Venmo/Venmo-assets/Venmo-flow.png'
import VisualStyleGuide from '../Assets/Venmo/Venmo-assets/Venmo-styleguide.png'
import EarlySketch from '../Assets/Venmo/Venmo-assets/early-sketch.jpg'

import AddRecipientsPage from '../Assets/Venmo/Venmo-assets/Flow-5/Screen27.png'
//same as EditEntryPoint
import ActionDropdown from '../Assets/Venmo/Venmo-assets/Flow-5/Screen30.png'

//multi-step-flows
import Flow1 from '../Assets/Venmo/Venmo-assets/Flow-1.png'
import Flow2 from '../Assets/Venmo/Venmo-assets/Flow-2.png'
import Flow3 from '../Assets/Venmo/Venmo-assets/Flow-3.png'
import Flow4 from '../Assets/Venmo/Venmo-assets/Flow-4.png'
import Flow5 from '../Assets/Venmo/Venmo-assets/Flow-5.png'

//same as overall venmo user flow
import EditEntryPoint from '../Assets/Venmo/Venmo-assets/explore-entry.jpg'
import EditRecurringFlow from '../Assets/Venmo/Venmo-assets/Edit-request.png'
import LeaveRecurringFlow from '../Assets/Venmo/Venmo-assets/Leave-request.png'

import useAnalyticsEventTracker from '../useAnalyticsEventTracker';



import "fontsource-jost"

//adding CSS styles inline, as we do
const styles = {
    Page: { backgroundColor: "#27292D" },

    TopTitle: {
        fontFamily: `"IBM Plex Mono", monospace`,
        color: "rgba(255, 255, 255, 0.9)",
        fontSize: "1.25em",
        letterSpacing: "0.0001em",
        lineHeight: 1.2,
        fontWeight: 500,
        fontStyle: "normal",

    } as const,

    HeroDescription: {
        fontFamily: `"Inter", sans-serif`,
        color: "rgba(255, 255, 255, 0.9)",
        fontSize: "1.25em",
        letterSpacing: "0.00625em",
        lineHeight: 1.65,

    } as const,

    HeroDescriptionBold: {
        fontFamily: `"Inter", sans-serif`,
        color: "rgba(255, 255, 255, 0.9)",
        fontSize: "1.25em",
        letterSpacing: "0.00625em",
        lineHeight: 1.65,
        fontWeight: 700,

    } as const,

    HeroContainer: {
        width: 424,
        height: 74,
        marginTop: 64,
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-evenly",
        alignItems: "center",
        overflow: "visible",
        background: "#27292D",
    } as const,


    Style: {
        marginTop: 72,
        width: "100%",
    } as const,

    // styles for text and images, etc. 
    PhotoContainer: {
        maxWidth: "100%",
        overflow: "hidden",
        borderRadius: 4,
        margin: "24px 24px 24px 24px",

    } as const,

    ProjectIntro: {
        fontFamily: `"IBM Plex Mono", monospace`,
        color: "rgba(255, 255, 255, 0.9)",
        fontSize: "1.563rem",
        letterSpacing: "0.00625em",
        lineHeight: 1.2,
        fontWeight: 700,
        alignText: "center",
        paddingLeft: 0,
        paddingBottom: 32,
        paddingTop: 16,
    } as const,

    ProjectHeaderContainer: {
        width: "100%",
        height: 64,
        paddingBottom: 48,
        alignItems: "center",
        margin: "24px 24px 24px 24px",

    } as const,

    OverviewChip: {
        boxSizing: "border-box",
        width: "40%",
        height: 24,
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-evenly",
        alignItems: "center",
        padding: 16,
        overflow: "hidden",
        backgroundColor: "#343434",
        borderRadius: 4,
        fontFamily: `"IBM Plex Mono", monospace`,
        color: "#FFF",
        fontSize: "0.9em",
        letterSpacing: "0.00625em",
        lineHeight: 1.2,
        fontWeight: 700,
        fontStyle: "normal",
        textAlign: "center",
        marginBottom: "16px",
    } as const,

    OverviewChipType: {
        boxSizing: "border-box",
        width: "40%",
        height: 24,
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-evenly",
        alignItems: "center",
        padding: 16,
        overflow: "hidden",
        backgroundColor: "#5d93d8",
        borderRadius: 4,
        fontFamily: `"IBM Plex Mono", monospace`,
        color: "#FFF",
        fontSize: "0.9em",
        letterSpacing: "0.00625em",
        lineHeight: 1.2,
        fontWeight: 700,
        fontStyle: "normal",
        textAlign: "center",
        marginBottom: "16px",
    } as const,
    OverviewChipWrapper: {
        boxSizing: "border-box",
        width: "100%",
        height: 24,
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-evenly",
        alignItems: "left",
        padding: "16px 16px 16px 0px",
        overflow: "visible",
        backgroundColor: "rgba(255, 255, 255,0)",
        borderRadius: 4,
        fontFamily: `"Inter", sans-serif`,
        color: "#FFF",
        fontSize: 16,
        letterSpacing: "0.00625em",
        lineHeight: 1.2,
        fontWeight: 700,
        fontStyle: "normal",
        textAlign: "center",
        marginBottom: "16px",
    } as const,

    OverviewTitle: {
        fontFamily: `"IBM Plex Mono", monospace`,
        color: "rgba(255, 255, 255, 0.9)",
        fontSize: "1.563rem",
        letterSpacing: "0.00625em",
        lineHeight: 1.2,
        fontWeight: 700,

    } as const,

    OverviewText: {
        fontFamily: `"Inter", sans-serif`,
        color: "rgba(255, 255, 255, 0.9)",
        fontSize: "1.25rem",
        letterSpacing: "0.00625em",
        lineHeight: 1.2,
        fontWeight: 400,

    } as const,

    SectionHeader: {
        fontFamily: `"IBM Plex Mono", monospace`,
        color: "rgba(255, 255, 255, 0.9)",
        fontSize: "1.563rem",
        letterSpacing: "0.00625em",
        lineHeight: 1.2,
        fontWeight: 700,
        minWidth: "400px",
    },

    SectionBlurb: {
        fontFamily: `"IBM Plex Mono", monospace`,
        color: "rgba(255, 255, 255, 0.9)",
        fontSize: "1rem",
        letterSpacing: "0.00625em",
        lineHeight: 1.2,
        fontWeight: 400,
        minWidth: "340px",
    },

    SectionTitle: {
        fontFamily: `"IBM Plex Mono", monospace`,
        color: "rgba(255, 255, 255, 0.9)",
        fontSize: "1.25rem",
        letterSpacing: "0.00625em",
        lineHeight: 1.2,
        fontWeight: 700,

    },

    SectionText: {

    },

    PhotoContainerProject: {
        maxWidth: "100%",
        maxHeight: "900px",
        overflow: "visible",
        margin: "24px 24px 0px 0px"
    } as const,



    ImageCaption: {
        fontFamily: `"Inter", sans-serif`,
        color: "rgba(255, 255, 255, 0.6)",
        fontSize: "1.0rem",
        letterSpacing: "0.00625em",
        lineHeight: 1.2,
        fontWeight: 600,
    } as const,



}

//variants for inline links 

const InlineLink = {

    initial: {
        textDecoration: "none",

        opacity: "75%"
    },
    hover: {
        textDecoration: "underline",

        opacity: "100%"
    },


} as const


// variants for motion div (TITLE) -

const titleVariants = {
    initial: {
        opacity: 1,
        x: 0,
        fontSize: "3.815em",
    },
    in: {
        opacity: 1,
        x: 0,
        transition: {
            type: "ease",
            ease: [0.22, 1, 0.36, 1],
            duration: 0.75,
            x: { stiffness: 100, velocity: -100 },
        },
        fontSize: "1.563em",
    },
    out: {
        opacity: 1,
        x: "100vw",
    },
}



function Venmo(): JSX.Element {
    const gaEventTracker = useAnalyticsEventTracker('Venmo case study page')

    return (
        <Grid fluid>
            <Row top="lg" around="lg">
                <Col lg={5} lgOffset={1}>
                    <motion.div
                        style={styles.Style}
                        initial="initial"
                        animate="in"
                        exit="out"
                        variants={titleVariants}
                    >
                        <p style={styles.TopTitle}>Chaitanya Prashant</p>

                    </motion.div>

                </Col>
                <Col lg={1} lgOffset={2} xs={3} xsOffset={2} style={{ marginTop: 48 }}>
                    <Link to='/' onClick={()=> gaEventTracker('Home page top nav')}>
                        <NavBar
                            Active={false}
                        />
                    </Link>
                </Col>
                <Col lg={1} xs={4} style={{ marginTop: 48 }}>
                    <Link to='/Pages/About' onClick={()=> gaEventTracker('About page top nav')}>
                        <NavBar
                            Active={false}
                            Text="About Me"
                        />

                    </Link>
                </Col>
            </Row>


            <motion.div>

                <Row start="lg" style={{ marginTop: 64 }}>
                    <Col xs={10} md={6} mdOffset={3} lg={6} lgOffset={3}>
                        <motion.div style={styles.ProjectHeaderContainer}>
                            <motion.div style={{ width: "100%", textAlign: "center" }}>
                                <p style={styles.ProjectIntro}>Venmo redesign</p>
                            </motion.div>
                            <motion.div
                                style={styles.OverviewChipWrapper}
                            >
                                <motion.div
                                    style={styles.OverviewChip}
                                >Side project</motion.div>

                                <motion.div
                                    style={styles.OverviewChipType}
                                >Spring 2019</motion.div>
                            </motion.div>

                        </motion.div>

                    </Col>
                </Row>


                <Row start="lg" style={{ marginTop: 64 }}>
                    <Col xs={10} lg={6} lgOffset={3}>
                        <motion.div
                        >
                            <img style={styles.PhotoContainer} src={ImageVenmo} alt="Venmo screens" />
                        </motion.div>

                    </Col>
                </Row>
            </motion.div>




            <Row start="lg">
                <Col xs={10} xsOffset={1} lg={5} lgOffset={1}>
                    <motion.div>
                        <p style={styles.OverviewTitle}>Overview</p>
                        <p style={styles.OverviewText}>Redesigning Venmo to be more efficient in terms of transactions, as well as conceptualzing a potential recurring payments feature and exploring how it fits within Venmo's current ecosystem.</p>
                        <p style={styles.OverviewText}>Note: I am not affiliated with Venmo</p>
                    </motion.div>
                    <motion.div>
                        <p style={styles.OverviewTitle}>Role</p>
                        <p style={styles.OverviewText}>Solo designer and researcher.</p>
                    </motion.div>
                    <motion.div>
                        <p style={styles.OverviewTitle}>Timeline</p>
                        <p style={styles.OverviewText}>2 weeks</p>
                    </motion.div>

                </Col>
                <Col xs={10} xsOffset={1} lg={5} lgOffset={1}>
                    <motion.div>
                        <p style={styles.OverviewTitle}>Responsibilities</p>
                        <p style={styles.OverviewText}>Design research, interaction design, and product thinking.</p>
                    </motion.div>
                    <motion.div>
                        <p style={styles.OverviewTitle}>Team</p>
                        <ul style={styles.OverviewText}>
                            <li>Chaitanya Prashant - Designer and Researcher</li>
                        </ul>
                    </motion.div>
                </Col>

            </Row>

            <Row start="lg" style={{ marginTop: "72px" }}>
                <Col xs={12} lg={2} lgOffset={1}>
                    <motion.div>
                        <p style={styles.SectionHeader}>Introduction</p>

                    </motion.div>
                </Col>
                <Col xs={12} lg={6} lgOffset={2}>
                    <motion.div>
                        <p style={styles.HeroDescription}>Venmo is ubiquitous around the country for young adults, and for good reason. There has been no other payments solution that has streamlined payment since PayPal was launched.</p>
                        <p style={styles.HeroDescription}>In my day-to-day, I use Venmo for splitting up subscription based payments, club reimbursements, and general food requisitions when splitting any bills.</p>
                        <p style={styles.HeroDescription}>However, no app, Venmo included, is perfect. Even though the app has undergone significant changes, I asked the question</p>
                        <p style={styles.HeroDescriptionBold}>How can they achieve their goal of mitigating awkwardness in transactions, and making it even more efficient and useful?</p>

                    </motion.div>
                </Col>
            </Row>
            <Row start="lg" style={{ marginTop: "72px" }}>
                <Col xs={12} lg={2} lgOffset={1}>
                    <motion.div>
                        <p style={styles.SectionHeader}>Who are the users?</p>
                    </motion.div>

                </Col>
                <Col xs={12} lg={6} lgOffset={2}>
                    <motion.div>
                        <p style={styles.HeroDescription}>Venmo’s user base consists across a vast spectrum, consisting of around 27 and a half million users. A large percentage of this user base is the millennial demographic, which is part of the reason why from a product standpoint, Venmo aimed to make transactions more social. I came up with two personas to fit the different demographics that are most represented in the product, and will be telling the story of my redesign through the lens of these two users.</p>

                        <p style={styles.SectionTitle}>Janice</p>
                        <Row>
                            <Col xs={12} lg={12}>
                                <img style={styles.PhotoContainerProject} src={JanicePersona} alt="JanicePersona" />
                                <p style={styles.ImageCaption}>20 year old college student</p>
                                <p style={styles.ImageCaption}>Splits apartment utilities and Netflix subscriptions with friends</p>
                                <p style={styles.ImageCaption}>Has an unforgiving work and school schedule</p>

                            </Col>
                        </Row>
                        <p style={styles.SectionTitle}>Kartik</p>
                        <Row>
                            <Col xs={12} lg={12}>
                                <img style={styles.PhotoContainerProject} src={KartikPersona} alt="KartikPersona" />
                                <p style={styles.ImageCaption}>20 year old college student</p>
                                <p style={styles.ImageCaption}>Splits apartment utilities and Netflix subscriptions with friends</p>
                                <p style={styles.ImageCaption}>Has an unforgiving work and school schedule</p>

                            </Col>
                        </Row>
                    </motion.div>
                </Col>
            </Row>

            <Row start="lg" style={{ marginTop: "72px" }}>
                <Col xs={12} lg={2} lgOffset={1}>
                    <motion.div>
                        <p style={styles.SectionHeader}>What's the problem?</p>
                    </motion.div>
                </Col>
                <Col xs={12} lg={6} lgOffset={2}>
                    <motion.div>
                        <p style={styles.HeroDescription}>Whenever I start off with a redesign, I aim to look at pressing, people problems to solve, rather than add something cosmetic that doesn’t provide tangible value.</p>
                        <p style={styles.HeroDescription}>Therefore, the first thing I did while auditing Venmo was ask peers around me, all who have used the app in some capacity, their thoughts on the app. This is to ensure that I’m not stuck in my own bubble of perspectives, but am considering other people’s needs and pain points.</p>
                        <p style={styles.HeroDescription}>I conducted informal interviews with 6 people, and some of the questions I asked included,</p>
                        <p style={styles.HeroDescription}>
                            <ul>
                                <li>How often do you use Venmo?</li>
                                <li>What are some things/payments that you use Venmo for?</li>
                                <li>Any important payments such as rent or recurring payments?</li>
                                <li>Is the app successful in doing its job of paying and receiving money?</li>
                                <li>How do you go about paying or requesting money?</li>
                                <li>Have you ever mistakenly paid someone instead of requesting, and vice versa?</li>
                                <li>Do you struggle with any of the app’s features or the way it works? What would you change about it?</li>
                            </ul>
                        </p>
                        <p style={styles.HeroDescription}>After analyzing interview notes, as well as observing their use cases for Venmo, I focused on two potential design opportunities and user pain points.</p>
                        <p style={styles.SectionTitle}>Ambiguity between paying and requesting</p>
                        <Row>
                            <Col xs={12} lg={12}>
                                <img style={styles.PhotoContainerProject} src={CurrentTransactionScreen} alt="CurrentTransactionScreen" />
                                <p style={styles.ImageCaption}>Current transaction experience</p>

                            </Col>
                        </Row>

                        <p style={styles.HeroDescription}>From the interviews, 3 out of 6 users had been confused when it comes to paying vs requesting someone on Venmo.</p>
                        <p style={styles.HeroDescription}>The similarly grouped CTA’s (pay / request) led people to accidentally pay money instead of requesting a certain amount. This can possibly lead to tension and awkwardness between the two parties, which is something that Venmo is trying to mitigate with its product.</p>
                        <p style={styles.SectionTitle}>The potential for recurring payments</p>
                        <p style={styles.HeroDescription}>One core problem that kept coming up in my interviews was the nature of recurring payments</p>
                        <p style={styles.HeroDescription}>5 out of the 6 users I interviewed used Venmo for recurring payments, which included</p>
                        <p style={styles.HeroDescription}>
                            <ul>
                                <li>Netflix / Spotify / Apple Music subscriptions</li>
                                <li>House utilities</li>
                                <li>Rent payments (in the case of townhouses and homes)</li>
                            </ul>
                        </p>
                        <p style={styles.HeroDescription}>A core demographic of Venmo users, millennials and college students, face a very specific problem.</p>
                        <p style={styles.HeroDescription}>A number of college students and millennials have to pay rent, usually as part of a group of roommates. However, the current workflow, especially for a topic as sensitive as rent, has a lot of friction.</p>
                        <p style={styles.HeroDescriptionBold}>General workflow</p>
                        <p style={styles.HeroDescription}>In a group of 4, its usually one person writing a cheque to the landlord, as a way of getting money without any transaction fee. What usually happens is the one person has to remind their roommates or shared payers to pay at an internal date via a messaging app (potential point of friction) → then they Venmo the person X amount of money → payer waits for bank transfer → person writes cheque to landlord.</p>
                        <p style={styles.HeroDescription}>This can be extended to events outside of rent, to recurring payments like utilities and service subscriptions.</p>
                        <p style={styles.HeroDescriptionBold}>Problems can occur here, with some of them being</p>
                        <p style={styles.HeroDescription}>
                            <ul>
                                <li>A shared payer forgetting to pay for a particular paying cycle.</li>
                                <li>Accounting for all payers, people currently have problems with this due to the constant back and forth nature of reminding people on messenger, which can lead to awkwardness in transactions.</li>
                            </ul>
                        </p>
                        <p style={styles.HeroDescription}>In fact, 4 of the 5 people who used Venmo for recurring payments remember a time when they forgot to pay at a specified date.</p>
                        <p style={styles.HeroDescriptionBold}>What if Venmo, with its focus on millennials and product vision to make transactions more social and efficient, introduced a feature to keep track of recurring split payments?</p>

                    </motion.div>
                </Col>
            </Row>

            <Row start="lg" style={{ marginTop: "72px" }}>
                <Col xs={12} lg={2} lgOffset={1}>
                    <motion.div>
                        <p style={styles.SectionHeader}>Design Goals</p>
                        <p style={styles.SectionBlurb}>Before getting anywhere near pencil and paper, I set myself a few goals and constraints with the redesign efforts, to make sure that my designs are grounded in rationale, as well as with Venmo’s business goals in mind.</p>
                    </motion.div>
                </Col>
                <Col xs={12} lg={6} lgOffset={2}>
                    <motion.div>
                        <p style={styles.SectionTitle}>Design for familiarity</p>
                        <Row>
                            <Col xs={12} lg={12}>
                                <img style={styles.PhotoContainerProject} src={VenmoUserFlow} alt="VenmoUserFlow" />
                                <p style={styles.ImageCaption}>Venmo's transactional flow mapped to the way humans pay</p>

                            </Col>
                        </Row>

                        <p style={styles.HeroDescription}>Venmo’s current interface works well because of how well it maps humans mental models of payment digitally. Therefore, I wanted to make sure that my solution would not represent a massive change to their mental models, but instead make transactions a more seamless interaction.</p>
                        <p style={styles.SectionTitle}>Foster focus and minimize ambiguity</p>
                        <p style={styles.HeroDescription}>Design for focus, since payments are sensitive and we need to eliminate ambiguity for users to make sure that they are paying or requesting for what’s needed, thus strengthening the trust of users in Venmo.</p>
                        <p style={styles.SectionTitle}>Emphasize user control and context</p>
                        <p style={styles.HeroDescription}>The user should still feel in charge of all transactions, which increases trust and control in the product. User should be able to easily view contextual information so that they can complete their transaction accurately and efficiently.</p>
                        <p style={styles.SectionTitle}>Don’t stray from existing visual patterns</p>
                        <p style={styles.HeroDescription}>Venmo’s design as it stands today has gone through many iterations, backed with data and design rationale, which is why I set myself the constraint of not straying from existing design patterns within the Venmo paradigm. This way, my design solution could enrich the experience for new and old users alike, while respecting Venmo’s established principles that have influenced its design decisions.</p>
                        <Row>
                            <Col xs={12} lg={12}>
                                <img style={styles.PhotoContainerProject} src={VisualStyleGuide} alt="VisualStyleGuide" />
                                <p style={styles.ImageCaption}>Concise visual styleguide</p>
                            </Col>
                        </Row>


                        <p style={styles.HeroDescription}>I audited Venmo’s visual design, and created a concise style guide of the atomic elements so that my redesign would be consistent with the current visual design paradigm and patterns used in Venmo.</p>

                    </motion.div>
                </Col>
            </Row>


            <Row start="lg" style={{ marginTop: "72px" }}>
                <Col xs={12} lg={2} lgOffset={1}>
                    <motion.div>
                        <p style={styles.SectionHeader}>Solving for ambiguity in the transaction process on Venmo</p>
                        <p style={styles.SectionBlurb}>Before I jump to Sketch, I sketch (haha get it?) potential screens and solutions and to not get too attached to a particular solution early on.</p>
                    </motion.div>
                </Col>
                <Col xs={12} lg={6} lgOffset={2}>
                    <motion.div>
                        <p style={styles.SectionTitle}>Initial Sketches</p>

                        <motion.div
                            style={styles.PhotoContainerProject}>
                            <img style={styles.PhotoContainerProject} src={EarlySketch} alt="EarlySketch" />

                            <p style={styles.ImageCaption}>Early ideation sketches</p>

                        </motion.div>
                        <p style={styles.HeroDescription}>My initial sketches diverged between two possible solutions.</p>
                        <p style={styles.HeroDescription}>The first one on the top, involves the same flow as that seen on the current user flow for requesting or paying. However, upon tapping on pay or request, a confirmation modal is surfaced, with the user getting an intermittent reminder that they are indeed paying or requesting a certain amount.</p>
                        <p style={styles.HeroDescriptionBold}>However, does this foster focus in our user flow?</p>
                        <p style={styles.HeroDescription}>In another exploration, I explore using a drop-down after tapping the transaction call to action, with the user instantly being able to make a decision between pay or request, before going through the familiar transaction flow, with a single call to action (pay in the above example) instead that aims to minimize ambiguity and provide the user with laser focus on what they are doing. In comparison to the original flow, where a user might have to go back, or, accidentally pay vs. request, they now have a clear idea of what kind of transaction they are in.</p>
                        <p style={styles.HeroDescription}>Whilst it seems counterintuitive to add another step to get to the transaction page, the design intent was that it'd nudge the user to think about what kind of transaction they are making before going through the rest of the flow.</p>

                        <motion.div
                            style={styles.PhotoContainerProject}>
                            <img style={styles.PhotoContainerProject} src={Flow1} alt="Flow1" />


                        </motion.div>

                        <p style={styles.HeroDescription}>Exploring this in a higher fidelity, there are intermittent reminders throughout the flow, including the explicit header and the singular call to action that serve as context to the user in terms of what transaction they are in. The flow, however, is familiar to Venmo’s holistic user flow in terms of transactions.</p>
                        <motion.div
                            style={styles.PhotoContainerProject}>
                            <img style={styles.PhotoContainerProject} src={Flow2} alt="Flow2" />


                        </motion.div>

                        <p style={styles.HeroDescription}>However, one problem that I realized the interface had after informally testing this exploration and the app was that Venmo surfaced the call to action, even though a pop up appears if the user hasn’t written a description or the amount. To account for this, I created an iterated upon exploration that would hopefully act as a soft forcing function for the user to fill in all the necessary information before moving on.</p>
                        <p style={styles.HeroDescription}>In this final user flow for transactions, the call to action to pay or request is only surfaced after the user has input a description and amount, where the intent is to mitigate confusion over whether the user has to put in a description or not, and instead fostering laser focus throughout the transaction process.</p>

                    </motion.div>
                </Col>
            </Row>




            <Row start="lg" style={{ marginTop: "72px" }}>
                <Col xs={12} lg={2} lgOffset={1}>
                    <motion.div>
                        <p style={styles.SectionHeader}>Solving for the problem of recurring payments through Venmo</p>
                    </motion.div>
                </Col>
                <Col xs={12} lg={6} lgOffset={2}>
                    <motion.div>
                        <p style={styles.SectionTitle}>Feature Proposal</p>
                        <p style={styles.HeroDescription}>Recurring payments are a potential area of entry to differentiate Venmo, especially as products like Zelle and Cash App are eating into Venmo’s market share.</p>
                        <p style={styles.HeroDescription}>A clear problem exists, where the current workflow for recurring payments is one with potential points of friction, and Venmo’s product goal is to help make payments less awkward and more efficient.</p>
                        <p style={styles.HeroDescription}>However, such a feature should still be consistent from a systematic standpoint with the current Venmo flow. Venmo already has a familiar flow to most users, and any changes to it should be subtle, so that it doesn’t breed unfamiliarity and confusion in the interface, which can potentially lead to lack of trust.</p>
                        <p style={styles.HeroDescription}>While a lot of recurring payment infrastructures in other products have auto-pay enabled, a key aspect of Venmo’s success is that they give users total control in terms of paying, and from which method (bank, Venmo balance). Also, auto-pay would be unfeasible from a UX standpoint, for example not all users have their banks connected, in the case where their Venmo balance is too low. Therefore, a reminder serves as a way to notify the user at a recurring date, without the possibility of them losing control of their money.</p>
                        <p style={styles.SectionTitle}>Main Use Cases</p>
                        <p style={styles.HeroDescription}>Setting up a recurring request for rent or subscriptions.</p>
                        <p style={styles.HeroDescription}>Leaving a recurring request (for example if an apartment mate has moved out), or editing transaction details.</p>
                        <p style={styles.SectionTitle}>Potential entry points</p>
                        <motion.div
                            style={styles.PhotoContainerProject}>
                            <img style={styles.PhotoContainerProject} src={AddRecipientsPage} alt="AddRecipientsPage" />

                            <p style={styles.ImageCaption}>Add recipients page</p>

                        </motion.div>

                        <p style={styles.HeroDescription}>Add-recipients, while an interesting exploration, didn’t make sense as an explicit entry point. The scan code functionality is used for scanning someone’s venmo code and adding as a recipient, and having a call to action for setting up a recurring payment doesn’t match the users mental model for selecting the type of transaction before involving the responsible parties, and this solution would only serve to increase ambiguity.</p>
                        <motion.div
                            style={styles.PhotoContainerProject}>
                            <img style={styles.PhotoContainerProject} src={EditEntryPoint} alt="Profile Page" />

                            <p style={styles.ImageCaption}>Profile page</p>
                        </motion.div>

                        <p style={styles.HeroDescription}>The profile page, while not a viable point of entry for starting the payment, seemed like an appropriate place for editing and accessing a recurring request, with a familiar design pattern already in place (ability to split Uber purchases); However, I would have loved to test this out with users and collect real time usage data.</p>
                        
                        <motion.div
                            style={styles.PhotoContainerProject}>
                            <img style={styles.PhotoContainerProject} src={ActionDropdown} alt="ActionDropdown" />

                            <p style={styles.ImageCaption}>Redesigned action dropdown</p>
                        </motion.div>

                        
                        <p style={styles.HeroDescription}>While exploring potential points of entry, one that stood out to me was the dropdown that I had implemented for the earlier payments flow. During interviews, Venmo users were pretty enthusiastic about the feature, with one saying</p>
                        <p style={styles.HeroDescriptionBold}>“I’d probably use this feature less than normal requests, but still need a way to quickly ask for my friend’s part of the subscription without having to make a mental note every time”</p>
                        <p style={styles.HeroDescription}>With this in mind, I decided that the best entry point would be housed inside the redesigned payments flow, on the top right of the screen above. While being easily accessible, it isn’t as common a use case as pay or request, which is why I changed the button color to deemphasize it on the users hierarchy, while using iconography to signify the functionality.</p>
                        <p style={styles.SectionTitle}>Explorations for setting up a recurring request</p>
                        <motion.div
                            style={styles.PhotoContainerProject}>
                            <img style={styles.PhotoContainerProject} src={Flow3} alt="Flow3" />
                        </motion.div>

                        <p style={styles.HeroDescription}>The first solution presents the dropdown first, where the user is able to select the recurring request option and is taken to the add recipients page, familiar to Venmo’s existing user flow, alongside the redesigned forcing function I implemented (only surfacing a call to action after transaction details have been filled out). After the user adds a recipient, amount and description however, I add another call to action that then allows them to edit the various parameters involved in recurring transactions, which are</p>
                        <p style={styles.HeroDescription}>
                            <ul>
                                <li>Start date</li>
                                <li>End date</li>
                                <li>Frequency of recurring requests</li>
                            </ul>
                        </p>
                        <p style={styles.HeroDescription}>I made sure to conform to existing iOS patterns here, so that the interaction is familiar, and one that fosters trust in the product. Once a user has entered their transaction details, they are able to tap on a single call to action (request), and confirm the recurring request, with the intention being that there is no ambiguity in the flow.</p>
                        <p style={styles.HeroDescription}>In terms of copy for the confirmation message, I decided to add the frequency of recurring requests to subtly remind the user again, the type of transaction that they are making, aiming to minimize any ambiguity that might take place.</p>
                        <motion.div
                            style={styles.PhotoContainerProject}>
                            <img style={styles.PhotoContainerProject} src={Flow4} alt="Flow4" />
                        </motion.div>

                        <p style={styles.HeroDescription}>In the second solution, I explored a different flow, where the user after tapping on recurring requests, first selects the date based parameters before going on to add recipients and the remaining transaction details (amount, description). However, recurring transaction information is not provided in context during confirmation, which can increase cognitive load for the user (in terms of remembering dates and frequency of recurring requests without having a look at the information during confirmation.</p>
                        <motion.div
                            style={styles.PhotoContainerProject}>
                            <img style={styles.PhotoContainerProject} src={Flow5} alt="Flow5" />
                        </motion.div>

                        <p style={styles.HeroDescription}>Therefore, I added the recurring transaction information during the confirmation stage of the flow. The intention being that it would lessen the cognitive load faced by the user when making the transaction and providing them control and context in terms of being able to change the recurring transaction details before confirmation.</p>
                     
                        <motion.div
                            style={styles.PhotoContainerProject}>
                            <img style={styles.PhotoContainerProject} src={VenmoUserFlow} alt="VenmoUserFlow" />
                        </motion.div>

                        <p style={styles.HeroDescription}>While these were two interesting divergent solution sets in terms of setting up recurring payments, I realized through research and Venmo’s own transaction flow compared to the traditional transaction flow that humans carry out (diagram above), it is awkward for them to decide transaction details before jumping to who the recipient is and the transaction amount.</p>
                        <p style={styles.HeroDescription}>Therefore Solutions 2 and 3 actually mismatch to users’ mental models in terms of how transactions are carried out, and thus I concluded that Solution 1 would prove a stronger one in terms of matching a users’ mental model, since it goes through the traditional transactional flow, with simply a step added in the description of a transaction. To give an example, if Joy is sharing a Netflix subscription with her friends, her first priority is to find the people she’s sharing the account with, before then looking at the amount and transaction details.</p>
                        <p style={styles.SectionTitle}>Accessing and editing a recurring payment</p>

                        <motion.div
                            style={styles.PhotoContainerProject}>
                            <img style={styles.PhotoContainerProject} src={EditEntryPoint} alt="EditEntryPoint - Profile page" />
                        </motion.div>
                        <p style={styles.HeroDescription}>For being able to access and edit a recurring payment, I aimed to make this step incredibly simple for the user. They are able to access the payment from either within their feed or the profile, where they can edit the payment. This works similarly to the split call to action when users pay by Venmo card or in this example, Uber’s Venmo integration.</p>
                        <motion.div
                            style={styles.PhotoContainerProject}>
                            <img style={styles.PhotoContainerProject} src={EditRecurringFlow} alt="EditRecurringFlow" />
                            <p style={styles.ImageCaption}>Screens for editing a recurring request</p>
                        </motion.div>

                       
                       <p style={styles.HeroDescription}>Using this common design pattern, I used a feed cell as the entry point, with the user being able to “edit” a payment if it was one of a recurring nature.</p>
                        <p style={styles.HeroDescription}>From the side of a user who created the recurring request, the user can go into edit, which surfaces the transaction information.</p>
                        <p style={styles.HeroDescription}>The user can edit the information and make an updated request, or delete the request altogether, with a confirmation serving as an intentional forcing function here, and making the process of editing a recurring payment familiar to the user flow already present in Venmo, leading to a more seamless interaction.</p>
                        <motion.div
                            style={styles.PhotoContainerProject}>
                            <img style={styles.PhotoContainerProject} src={LeaveRecurringFlow} alt="LeaveRecurringFlow" />
                            <p style={styles.ImageCaption}>Screens for leaving a recurring request</p>
                        </motion.div>

                        
                        <p style={styles.HeroDescription}>However, from the side of a user who didn’t set up the request, but still wishes to access it, they only have the option to leave, and not to edit any of the original transaction details. The rationale for this is to provide control to the original requester (since they are the party that initiated the transaction), while minimizing confusion in the case where a recipient could potentially change the parameters by mistake.</p>
                        <p style={styles.HeroDescription}>The change of the call to action from the primary blue to red aims to signify to the user that they are indeed leaving the transaction, and serves as a forcing function to consider their decision.</p>


                    </motion.div>
                </Col>
            </Row>


            <Row start="lg" style={{ marginTop: "72px" }}>
                <Col xs={12} lg={2} lgOffset={1}>
                    <motion.div>
                        <p style={styles.SectionHeader}>Lessons and Next Steps</p>
                    </motion.div>
                </Col>
                <Col xs={12} lg={6} lgOffset={2}>
                    <motion.div>
                        <p style={styles.HeroDescription}>
                            <ol>
                                <li><b>Constraints matter:</b> For my redesign, I wanted to stay true to the Venmo brand, and make sure to not introduce any new features or visual patterns that would be blue sky solutions to user problems and unfeasible. Instead, by focusing on interaction and product design, I was able to scope the problem space much better, which allowed me to come up with a stronger design solution.</li>
                                <li><b>Listen to your users:</b> While designing, it is often easy for us to design in a silo, which is why it is critical to get out of your own bubble and consider users’ needs and pain points before redesigning an existing product or proposing a new feature.</li>
                                <li><b>Product design is never an individual endeavor:</b> I would have loved to collaborate with Venmo designers, engineers and users to deeply understand the potential tradeoffs involved. A lot of my designs were based on cursory research and designer assumption, rather than solid data, which would have been fantastic to leverage for this exercise, and would have surely lead to a stronger solution.</li>
                                <li><b>User testing and motion design:</b> Given more time, I would definitely have liked to create testable prototypes in Framer or Origami, that I could informally test with users to iterate even quicker. Another aspect I would have loved to worked on would be to incorporate motion design and micro-interactions that would convey the flow and reinforce subtle feedback for the user, which I will be working on soon!</li>
                            </ol>
                        </p>
                        <p style={styles.HeroDescription}>Thank you for reading through all of that! If you have any feedback, don't hesistate to reach out at chaitanyaprashant@gmail.com.</p>
                    </motion.div>
                </Col>
            </Row>



            <Row start="lg" style={{ marginTop: 32 }}>
                <Col xs={12} lg={5} lgOffset={1}>

                    <motion.div style={{ paddingBottom: 64 }}>
                        <motion.a initial="initial" whileHover="hover" variants={InlineLink} style={styles.SectionBlurb} href="/" onClick={()=> gaEventTracker('Home page footer')}>Go back home</motion.a>

                    </motion.div>

                </Col>
            </Row>
        </Grid>



    )
}

export default Venmo;