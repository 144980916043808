import { useState, useEffect } from 'react'


export default function useMediaQuery(query: any) {
    const [matches, setMatches] = useState(false);

    useEffect(() => {
        const media = window.matchMedia(query);
        if (media.matches !== matches) {
            setMatches(media.matches);
        }

        //add listener
        const listener = () => {
            setMatches(media.matches);
        };
        media.addEventListener("change", () => listener);

        //remove listener upon render
        return () => media.removeEventListener("change", () => listener);
}, [matches,query]);

return matches

}