import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { BrowserRouter } from 'react-router-dom'
import App from './App';
import { Route, Switch } from 'react-router-dom';
import Wizehire from './Pages/Wizehire';
import CurologyCadence from './Pages/CurologyCadence';
import CurologyTimeline from './Pages/CurologyTimeline';
import PagerDuty from './Pages/PagerDuty';
import Mango from './Pages/Mango';
import Venmo from './Pages/Venmo';
import About from './Pages/About';

import ScrollToTop from './Components/ScrollToTop'
import * as serviceWorker from './serviceWorker';


ReactDOM.render(
  <BrowserRouter>
    <ScrollToTop />
    <Switch>
      <Route
        exact path='/'
        component={App}
      />
      <Route
        path='/Pages/About'
        component={About}
      />
      <Route
        path='/Pages/Wizehire'
        component={Wizehire}
      />
      <Route
        path='/Pages/CurologyCadence'
        component={CurologyCadence}
      />
      <Route
        path='/Pages/CurologyTimeline'
        component={CurologyTimeline}
      />
      <Route
        path='/Pages/PagerDuty'
        component={PagerDuty}
      />
      <Route
        path='/Pages/Mango'
        component={Mango}
      />
      <Route
        path='/Pages/Venmo'
        component={Venmo}
      />

    </Switch>
  </BrowserRouter>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
