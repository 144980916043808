import * as React from "react"
import '../grid.css'
import { motion } from "framer-motion"

import { Link } from 'react-router-dom'
import { NavBar } from '../Components/NavBar'

import { Grid, Row, Col } from "react-flexbox-grid"
//adding support for IBM Plex Mono as a font.
//Cadence file imports. 
import EntryPointPhone from '../Assets/Curology/Subscription-cadence/Entry-point-iphone-screen.png'
import ModalScreenPhone from '../Assets/Curology/Subscription-cadence/Modal-main-iphone-screen.png'
import FinalCadencePrototype from '../Assets/Curology/Prototypes/Cadence-prototype-final-slick.mov'
import DesktopInitialSnippet from '../Assets/Curology/Subscription-cadence/old-desktop-snippet.png'
import OldExperienceMobile from '../Assets/Curology/Subscription-cadence/Previously-launched-mobile.png'
import InitialDropdownCard from '../Assets/Curology/Subscription-cadence/initial-dropdown-card.png'
import InitialDesignedEntryPoint from '../Assets/Curology/Subscription-cadence/initial-designed-entrypoint.png'
import EntryPointCopyExplorations from '../Assets/Curology/Subscription-cadence/entry-point-copy-iterations.png'
import FinalEntryPointModule from '../Assets/Curology/Subscription-cadence/final-designed-entrypoint.png'
import FinalEntryPointScreen from '../Assets/Curology/Subscription-cadence/entry-point-screen-finalized.png'

import InitialEntryPointScreen from '../Assets/Curology/Subscription-cadence/Initial-flow-entry-screen.png'
import InitialFlowModal from '../Assets/Curology/Subscription-cadence/frequency-modal-screen-ver-b.png'
import InitialFlowModalSelect from '../Assets/Curology/Subscription-cadence/frequency-modal-screen-ver-b-1.1.png'
import InitialEntryPointNotification from '../Assets/Curology/Subscription-cadence/frequency-notification-screen-ver-b.png'
import InitialFlowPrototype from '../Assets/Curology/Prototypes/Cadence-prototype-initial.mov'
import FrequencyModalCrit from '../Assets/Curology/Subscription-cadence/frequency-modal-crit-1.0.png'
import CritiqueBoard from '../Assets/Curology/Subscription-cadence/design-crit-board.png'
//same as frequencymodalcrit
import FrequencyModalInspo from '../Assets/Curology/Subscription-cadence/freq-modal-inspo-final.png'
import VisualModalInitial from '../Assets/Curology/Subscription-cadence/frequency-modal-user-test-1.0-1.1.png'
import VisualModalSelection from '../Assets/Curology/Subscription-cadence/freq-modal-user-test-1.0-1.2.png'

import PrototypeUserTestV1 from '../Assets/Curology/Prototypes/Cadence-prototype-user-test-1.mov'
import V1CopyDiff from '../Assets/Curology/Subscription-cadence/user-test-end-state-1.0.png'
import V2CopyDiff from '../Assets/Curology/Subscription-cadence/user-test-end-state-2.0.png'
import PrototypeUserTestV2 from '../Assets/Curology/Prototypes/Cadence-prototype-user-test-2.mov'
//same as final prototype
import HappyPathBoard from '../Assets/Curology/Subscription-cadence/Happy-path-board.png'
import EdgeCaseBoard from '../Assets/Curology/Subscription-cadence/Edge-case-board.png'
import DesktopDesign from '../Assets/Curology/Subscription-cadence/Desktop-board.png'

import "fontsource-jost"
import useAnalyticsEventTracker from '../useAnalyticsEventTracker';

//adding CSS styles inline, as we do
const styles = {
    Page: { backgroundColor: "#27292D" },

    TopTitle: {
        fontFamily: `"IBM Plex Mono", monospace`,
        color: "rgba(255, 255, 255, 0.9)",
        fontSize: "1.25em",
        letterSpacing: "0.0001em",
        lineHeight: 1.2,
        fontWeight: 500,
        fontStyle: "normal",

    } as const,

    HeroDescription: {
        fontFamily: `"Inter", sans-serif`,
        color: "rgba(255, 255, 255, 0.9)",
        fontSize: "1.25em",
        letterSpacing: "0.00625em",
        lineHeight: 1.65,

    } as const,

    HeroDescriptionBold: {
        fontFamily: `"Inter", sans-serif`,
        color: "rgba(255, 255, 255, 0.9)",
        fontSize: "1.25em",
        letterSpacing: "0.00625em",
        lineHeight: 1.65,
        fontWeight: 700,

    } as const,

    HeroContainer: {
        width: 424,
        height: 74,
        marginTop: 64,
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-evenly",
        alignItems: "center",
        overflow: "visible",
        background: "#27292D",
    } as const,


    Style: {
        marginTop: 72,
        width: "100%",
    } as const,

    // styles for text and images, etc. 
    PhotoContainer: {
        maxWidth: "100%",
        overflow: "hidden",
        borderRadius: 4,
        margin: "24px 24px 24px 24px",

    } as const,

    ProjectIntro: {
        fontFamily: `"IBM Plex Mono", monospace`,
        color: "rgba(255, 255, 255, 0.9)",
        fontSize: "1.563rem",
        letterSpacing: "0.00625em",
        lineHeight: 1.2,
        fontWeight: 700,
        alignText: "center",
        paddingLeft: 0,
        paddingBottom: 32,
        paddingTop: 16,
    } as const,

    ProjectHeaderContainer: {
        width: "100%",
        height: 64,
        paddingBottom: 48,
        alignItems: "center",
        margin: "24px 24px 24px 24px",

    } as const,

    OverviewChip: {
        boxSizing: "border-box",
        width: "40%",
        height: 24,
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-evenly",
        alignItems: "center",
        padding: 16,
        overflow: "hidden",
        backgroundColor: "#343434",
        borderRadius: 4,
        fontFamily: `"IBM Plex Mono", monospace`,
        color: "#FFF",
        fontSize: "0.9em",
        letterSpacing: "0.00625em",
        lineHeight: 1.2,
        fontWeight: 700,
        fontStyle: "normal",
        textAlign: "center",
        marginBottom: "16px",
    } as const,

    OverviewChipType: {
        boxSizing: "border-box",
        width: "40%",
        height: 24,
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-evenly",
        alignItems: "center",
        padding: 16,
        overflow: "hidden",
        backgroundColor: "#332E54",
        borderRadius: 4,
        fontFamily: `"IBM Plex Mono", monospace`,
        color: "#FFF",
        fontSize: "0.9em",
        letterSpacing: "0.00625em",
        lineHeight: 1.2,
        fontWeight: 700,
        fontStyle: "normal",
        textAlign: "center",
        marginBottom: "16px",
    } as const,
    OverviewChipWrapper: {
        boxSizing: "border-box",
        width: "100%",
        height: 24,
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-evenly",
        alignItems: "left",
        padding: "16px 16px 16px 0px",
        overflow: "visible",
        backgroundColor: "rgba(255, 255, 255,0)",
        borderRadius: 4,
        fontFamily: `"Inter", sans-serif`,
        color: "#FFF",
        fontSize: 16,
        letterSpacing: "0.00625em",
        lineHeight: 1.2,
        fontWeight: 700,
        fontStyle: "normal",
        textAlign: "center",
        marginBottom: "16px",
    } as const,

    OverviewTitle: {
        fontFamily: `"IBM Plex Mono", monospace`,
        color: "rgba(255, 255, 255, 0.9)",
        fontSize: "1.563rem",
        letterSpacing: "0.00625em",
        lineHeight: 1.2,
        fontWeight: 700,

    } as const,

    OverviewText: {
        fontFamily: `"Inter", sans-serif`,
        color: "rgba(255, 255, 255, 0.9)",
        fontSize: "1.25rem",
        letterSpacing: "0.00625em",
        lineHeight: 1.2,
        fontWeight: 400,

    } as const,

    SectionHeader: {
        fontFamily: `"IBM Plex Mono", monospace`,
        color: "rgba(255, 255, 255, 0.9)",
        fontSize: "1.563rem",
        letterSpacing: "0.00625em",
        lineHeight: 1.2,
        fontWeight: 700,
        minWidth: "400px",
    },

    SectionBlurb: {
        fontFamily: `"IBM Plex Mono", monospace`,
        color: "rgba(255, 255, 255, 0.9)",
        fontSize: "1rem",
        letterSpacing: "0.00625em",
        lineHeight: 1.2,
        fontWeight: 400,
        minWidth: "340px",
    },

    SectionTitle: {
        fontFamily: `"IBM Plex Mono", monospace`,
        color: "rgba(255, 255, 255, 0.9)",
        fontSize: "1.25rem",
        letterSpacing: "0.00625em",
        lineHeight: 1.2,
        fontWeight: 700,

    },

    SectionText: {

    },

    PhotoContainerProject: {
        maxWidth: "100%",
        maxHeight: "900px",
        overflow: "visible",
        margin: "24px 24px 0px 0px"
    } as const,



    ImageCaption: {
        fontFamily: `"Inter", sans-serif`,
        color: "rgba(255, 255, 255, 0.6)",
        fontSize: "1.0rem",
        letterSpacing: "0.00625em",
        lineHeight: 1.2,
        fontWeight: 600,
    } as const,



}

//variants for inline links 

const InlineLink = {

    initial: {
        textDecoration: "none",

        opacity: "75%"
    },
    hover: {
        textDecoration: "underline",

        opacity: "100%"
    },


} as const


// variants for motion div (TITLE) -

const titleVariants = {
    initial: {
        opacity: 1,
        x: 0,
        fontSize: "3.815em",
    },
    in: {
        opacity: 1,
        x: 0,
        transition: {
            type: "ease",
            ease: [0.22, 1, 0.36, 1],
            duration: 0.75,
            x: { stiffness: 100, velocity: -100 },
        },
        fontSize: "1.563em",
    },
    out: {
        opacity: 1,
        x: "100vw",
    },
}



function CurologyCadence(): JSX.Element {
    const gaEventTracker = useAnalyticsEventTracker('Cadence case study page')

    return (
        <Grid fluid>
            <Row top="lg" around="lg">
                <Col lg={5} lgOffset={1}>
                    <motion.div
                        style={styles.Style}
                        initial="initial"
                        animate="in"
                        exit="out"
                        variants={titleVariants}
                    >
                        <p style={styles.TopTitle}>Chaitanya Prashant</p>

                    </motion.div>

                </Col>
                <Col lg={1} lgOffset={2} xs={3} xsOffset={2} style={{ marginTop: 48 }}>
                    <Link to='/' onClick={()=> gaEventTracker('Homepage top nav')}>
                        <NavBar
                            Active={false}
                        />
                    </Link>
                </Col>
                <Col lg={1} xs={4} style={{ marginTop: 48 }}>
                    <Link to='/Pages/About' onClick={()=> gaEventTracker('About page top nav')}>
                        <NavBar
                            Active={false}
                            Text="About Me"
                        />

                    </Link>
                </Col>
            </Row>


            <motion.div>

                <Row start="lg" style={{ marginTop: 64 }}>
                    <Col xs={10} md={6} mdOffset={3} lg={6} lgOffset={3}>
                        <motion.div style={styles.ProjectHeaderContainer}>
                            <motion.div style={{ width: "100%", textAlign: "center" }}>
                                <p style={styles.ProjectIntro}>Subscription Cadence at Curology</p>
                            </motion.div>
                            <motion.div
                                style={styles.OverviewChipWrapper}
                            >
                                <motion.div
                                    style={styles.OverviewChip}
                                >Internship</motion.div>
                                <motion.div
                                    style={styles.OverviewChipType}
                                >Summer 2020</motion.div>
                            </motion.div>

                        </motion.div>

                    </Col>
                </Row>


                <Row start="lg" style={{ marginTop: 64 }}>
                    <Col xs={10} lg={6} lgOffset={3}>
                    <Row>
                            <Col xs={12} lg={6}>
                                <img style={styles.PhotoContainerProject} src={EntryPointPhone} alt="Cadence phone model" />
                            </Col>
                            <Col xs={12} lg={6}>
                                <img style={styles.PhotoContainerProject} src={ModalScreenPhone} alt="Cadence phone model" />
                            </Col>
                        </Row>

                    </Col>
                </Row>
            </motion.div>




            <Row start="lg">
                <Col xs={10} xsOffset={1} lg={5} lgOffset={1}>
                    <motion.div>
                        <p style={styles.OverviewTitle}>Overview</p>
                        <p style={styles.OverviewText}>Designing a way for Curology patients’ to have more control and personalization on their subscription box.</p>
                    </motion.div>
                    <motion.div>
                        <p style={styles.OverviewTitle}>Role</p>
                        <p style={styles.OverviewText}>Lead designer on a cross functional team.</p>
                    </motion.div>
                    <motion.div>
                        <p style={styles.OverviewTitle}>Timeline</p>
                        <p style={styles.OverviewText}>Summer 2020 - 8 weeks</p>
                    </motion.div>

                </Col>
                <Col xs={10} xsOffset={1} lg={5} lgOffset={1}>
                    <motion.div>
                        <p style={styles.OverviewTitle}>Responsibilities</p>
                        <p style={styles.OverviewText}>Interaction + visual design, prototyping and product thinking.</p>
                    </motion.div>
                    <motion.div>
                        <p style={styles.OverviewTitle}>Team</p>
                        <ul style={styles.OverviewText}>
                            <li>Chaitanya Prashant - Design</li>
                            <li>Ben Kolde - Product Design (manager)</li>
                            <li>Tiffany Wu - Copy</li>
                            <li>Rucha Makati = UX Research</li>
                        </ul>
                    </motion.div>
                </Col>

            </Row>

            <Row start="lg" style={{ marginTop: "72px" }}>
                <Col xs={12} lg={2} lgOffset={1}>
                    <motion.div>
                        <p style={styles.SectionHeader}>Introduction</p>
                        <p style={styles.SectionBlurb}>Was there an opportunity for Curology to improve the patients’ experience in that it’d increase their sentiment to the subscription?</p>
                        <p style={styles.SectionBlurb}>Would providing more flexibility to a patient actually increase the personalization of a patient toward their subscription?</p>
                        <p style={styles.SectionBlurb}><b>Would a cohort of currently subscribing patients want to change the frequency (cadence) of their shipment arrivals?</b></p>
                    </motion.div>
                </Col>
                <Col xs={12} lg={6} lgOffset={2}>
                    <motion.div>
                        <p style={styles.HeroDescription}>This project started out as an initiative to empower patients’ decision making process when it comes to how often their subscription is shipped to them, thus personalizing their subscription experience according to their individual preference. While the product offering (subscription to either the Curology regular formula, or the super bottle) allows our patients to change their most recent upcoming shipment date, different patients have different preferences when it comes to how often they want their box.</p>
                        <p style={styles.SectionTitle}>Hypothesis</p>
                        <p style={styles.HeroDescription}>We hypothesized that out of currently subscribing Curology patients, a sizable minority of patients in a scenario might either consume their shipment too quickly, or consume their shipment at a slower pace leading to an excess of formula bottles, potentially dissuading the patient and causing them to cancel their subscription.</p>
                        <p style={styles.SectionTitle}>Objectives</p>

                        <p style={styles.HeroDescription}>Offer patients the flexibility to customize the frequency of when their personalized subscription set arrives, building a stronger connection to the product.</p>
                        <p style={styles.HeroDescription}>Increase retention and mitigate churn rates amongst patients who don’t follow the default subscription frequency set by our providers.</p>
                        <p style={styles.SectionTitle}>Key results (tracked over 6 months)</p>

                        <p style={styles.HeroDescription}>Relative lift of 25% retention in cohort that changed frequency at least once.</p>
                        <p style={styles.HeroDescription}>10% relative lift in cohort that stayed on default frequency, but were presented with the new feature.</p>
                        <p style={styles.SectionTitle}>Final Design</p>
                        <Row>
                            <Col xs={12} lg={6}>
                                <img style={styles.PhotoContainerProject} src={EntryPointPhone} alt="Wizehire Dashboard" />
                                <p style={styles.ImageCaption}>Entry point - on a patients' subscription page</p>

                            </Col>
                            <Col xs={12} lg={6}>
                                <img style={styles.PhotoContainerProject} src={ModalScreenPhone} alt="Wizehire Dashboard" />
                                <p style={styles.ImageCaption}>Selection screen</p>

                            </Col>
                        </Row>

                        <motion.div
                            style={styles.PhotoContainerProject}
                        >
                            <video style={styles.PhotoContainerProject} src={FinalCadencePrototype} controls></video>
                            <p style={styles.ImageCaption}>Final prototype</p>

                        </motion.div>


                    </motion.div>
                </Col>
            </Row>
            <Row start="lg" style={{ marginTop: "72px" }}>
                <Col xs={12} lg={2} lgOffset={1}>
                    <motion.div>
                        <p style={styles.SectionHeader}>Understanding the problem</p>
                        <p style={styles.SectionBlurb}>tl;dr: We had launched a very naive version on desktop, however, in discussions with the PM and designer, there was a lot of low hanging fruit. What was this supposed feature solving?</p>

                    </motion.div>

                </Col>
                <Col xs={12} lg={6} lgOffset={2}>
                    <motion.div>
                        <Row>
                            <Col xs={12} lg={6}>
                                <img style={styles.PhotoContainerProject} src={DesktopInitialSnippet} alt="Wizehire Dashboard" />
                                <p style={styles.ImageCaption}>Initial launch on desktop</p>

                            </Col>
                            <Col xs={12} lg={6}>
                                <img style={styles.PhotoContainerProject} src={OldExperienceMobile} alt="Wizehire Dashboard" />
                                <p style={styles.ImageCaption}>Initially launched solution, experiences on both mobile and Android.</p>

                            </Col>
                        </Row>

                        <p style={styles.HeroDescription}>The launched version of the experience saw a slight bump up in retention. The experience was disparate across platforms however, especially mobile. While the drop-down paradigm works well in Desktop designs, it scales down poorly. Even though the app is mobile web, using native components meant that the iOS experience was similar to a date-picker, while the Android experience consisted of a simple radio-button checklist.</p>
                        <p style={styles.HeroDescription}>This also scaled to different flavors of Android reacting to make the component look visually different. - <b>Was this lack of consistency worth it?</b></p>
                        <p style={styles.HeroDescription}>To add to the confusion, for example, the iOS experience has additional copy at the top of the date-picker that says “shipping cadence”, while the language throughout the product utilizes frequency. This was a low hanging fruit that we definitely wanted to address.</p>
                        <p style={styles.HeroDescription}>In an informal session with 3 users who had used the feature, there was confusion in terms of what changing the shipping cadence actually does. In a clear cut case, say if the user changes frequency from 6 weeks to 8 weeks, the subscription would be updated, however, there is no status update or notification on the product. It showcased the same billing and shipment date as the old frequency, and a user would only see the new changes once this date has passed</p>
                        <p style={styles.HeroDescription}>For example, in the previously shipped experience, if their current cadence was every 8 wks (next date is 8th September), their next shipment is being delivered in a week (screen date is September 1st), but the user changes their cadence to be every 6 weeks. There is still no status update, and the status on the action card is the same as above, only showing the current frequency until the hard “date” is passed - This was a clear low hanging fruit to address and refine through unmoderated user testing, so that we were setting the right expectation as the user changes their shipment frequency.</p>
                    </motion.div>

                </Col>

            </Row>

            <Row start="lg" style={{ marginTop: "72px" }}>
                <Col xs={12} lg={2} lgOffset={1}>
                    <motion.div>
                        <p style={styles.SectionHeader}>Opportunities</p>
                    </motion.div>
                </Col>
                <Col xs={12} lg={6} lgOffset={2}>
                    <motion.div>
                        <p style={styles.SectionTitle}>People Problem</p>
                        <p style={styles.HeroDescription}>How might we offer patients more control over when their products arrive (other than the one time reschedule)?</p>
                        <p style={styles.HeroDescription}>As I described above, different patients have differing habits. For example</p>
                        <p style={styles.HeroDescription}>
                            <ul>
                                <li>Patient applying Curology formula twice a day, but moisturizer only once a day.</li>
                                <li>Patient that applies Curology once every two days, and feels that their subscription comes in too “fast”.</li>
                            </ul>
                        </p>
                        <p style={styles.HeroDescription}>There was only one way to customize a patients’ shipment, and that was to edit the date of your next upcoming shipment. Whilst very much a solution to use in a scenario where a patient has to leave town for vacation and delay the upcoming shipment by one or two weeks, does this scale? Does it offer a clear area of control over what a patients’ “schedule” looks like?</p>
                        <p style={styles.SectionTitle}>Business Problem</p>

                        <p style={styles.HeroDescription}>How might we improve retention for patients who don’t follow the default subscription cadence?</p>
                        <p style={styles.SectionTitle}>Insight and risks</p>

                        <p style={styles.HeroDescription}>We anticipated a point of contention for users, in terms of what their expectations might be when changing the frequency of their box. Did users only want changes applied to shipments after their most recent shipment was delivered? Or did users want a change to frequency to be reflected on their next box, and all subsequent boxes afterward applying the same setting? Important questions that we wanted to take a stab at, as well as potentially test.</p>

                    </motion.div>
                </Col>
            </Row>

            <Row start="lg" style={{ marginTop: "72px" }}>
                <Col xs={12} lg={2} lgOffset={1}>
                    <motion.div>
                        <p style={styles.SectionHeader}>Crafting the entry point</p>
                        <p style={styles.SectionBlurb}>Currently designed, we clearly identified inconsistencies with the native components, disparate between Android and iOS. There was also clear visual dissonance, whereas we wanted to keep the two core sections of our subscription surface consistent from a “take an action” POV.</p>
                    </motion.div>
                </Col>
                <Col xs={12} lg={6} lgOffset={2}>
                    <motion.div>
                    <motion.div
                            style={styles.PhotoContainerProject}
                        >
                            <img style={styles.PhotoContainerProject} src={InitialDropdownCard} alt="Wizehire Dashboard" />

                            <p style={styles.ImageCaption}>Initial entry point card, as previously seen on mobile</p>

                        </motion.div>

                        <p style={styles.HeroDescription}>At first stab, we made sure that the action on the card was consistent with the rest of the surface, using the action button with descriptive copy and a chevron. We hypothesized early on, that emphasizing the billing and shipping date as metadata (housed in a pill) would help in delineating b/w a change in cadence settings versus actual date, and serve as a visual anchor of sorts.</p>
                        <motion.div
                            style={styles.PhotoContainerProject}
                        >
                            <img style={styles.PhotoContainerProject} src={InitialDesignedEntryPoint} alt="Wizehire Dashboard" />

                            <p style={styles.ImageCaption}>Entry point card - iterating with action button, bringing the card's action in convention with our design system.</p>

                        </motion.div>
                        <motion.div
                            style={styles.PhotoContainerProject}
                        >
                            <img style={styles.PhotoContainerProject} src={EntryPointCopyExplorations} alt="Entry point copy" />
                        </motion.div>

                        <p style={styles.HeroDescription}>Above are some of the copy explorations I came up with alongside our superstar copywriter Tiff, with the goal of how best to “communicate” the action taken.</p>
                        <p style={styles.HeroDescription}>With option B, the thinking was it’d be similar to common subscription surfaces across various e-commerce apps, where a radio selection might be used, for example, as a set and select option with strict rules. Would this scale well considering we still had an open question as to setting expectations of whether the cadence affects the next box? Would it add to the confusion?.</p>
                        <p style={styles.HeroDescription}>Option A was the simplest, most descriptive use of copy for an action button, which is a key tenet of our design system, to aim for simple, descriptive language over technical descriptors or affordances, which is what Option C presented, using the same language as the iOS picker.</p>
                        <p style={styles.HeroDescription}>Whilst a question I had was that since the action button was on the subscription surface, perhaps it would make sense for the descriptor to be referred as subscription. However, this was quickly answered in unmoderated user testing and comparing previous notes with our wonderful researcher Rucha, as language around the product during onboarding and messaging conditioned users to think about their Curology box as a customizable shipment. Armed with this rationale, we were confident in using <b>Option A</b></p>
                        <p style={styles.HeroDescriptionBold}>Iterating on option A</p>
                        <p style={styles.HeroDescription}>In our final iteration for the entry point, it does away with the floating element as metadata.</p>
                        <p style={styles.HeroDescription}>Why? Whilst we came out of critique with a prerogative to reduce the amount of screen estate (especially as the metadata pill extended our modal’s expected size), we wanted to ensure a crisp visual anchor to the billing and shipping date whilst being scalable for any possible edge cases and reduce clutter on the surface. From a visual design viewpoint, it felt like a floating surface that didn’t really afford an action, so to that end, we stripped away at the anatomy of the card itself, fusing it onto the action card, making use of whitespace, and emphasized copy pertaining to date and / or frequency. This decision was also taken with the intent to ensure consistency across the subscription surface from a visual design viewpoint. On the other hand, it was important to tie it into the mental model of subscription, rather than a singular shipment.</p>
                        <Row>
                            <Col xs={12} lg={6}>
                                <img style={styles.PhotoContainerProject} src={FinalEntryPointModule} alt="Wizehire Dashboard" />
                                <p style={styles.ImageCaption}>Entry point module</p>

                            </Col>
                            <Col xs={12} lg={6}>
                                <img style={styles.PhotoContainerProject} src={FinalEntryPointScreen} alt="Wizehire Dashboard" />
                                <p style={styles.ImageCaption}>Feature entry point - module in context</p>

                            </Col>
                        </Row>
                    </motion.div>
                </Col>
            </Row>


            <Row start="lg" style={{ marginTop: "72px" }}>
                <Col xs={12} lg={2} lgOffset={1}>
                    <motion.div>
                        <p style={styles.SectionHeader}>Crafting the initial flow for a patient changing frequency</p>
                        <p style={styles.SectionBlurb}>Initially, the question we asked with this flow was, how might we constrain it within a specific design pattern that already exists in our design system. The dialog modal seemed a good fit, because of vertical stack-ability, as well as being scalable on mobile web.</p>
                    </motion.div>
                </Col>
                <Col xs={12} lg={6} lgOffset={2}>
                    <motion.div>

                    <Row>
                            <Col xs={12} lg={6}>
                                <img style={styles.PhotoContainerProject} src={InitialEntryPointScreen} alt="InitialEntryPointScreen" />
                                <p style={styles.ImageCaption}>Entry point</p>

                            </Col>
                            <Col xs={12} lg={6}>
                                <img style={styles.PhotoContainerProject} src={InitialFlowModal} alt="InitialFlowModal" />
                                <p style={styles.ImageCaption}>Initial modal</p>

                            </Col>
                            <Col xs={12} lg={6}>
                                <img style={styles.PhotoContainerProject} src={InitialFlowModalSelect} alt="InitialFlowModalSelect" />
                                <p style={styles.ImageCaption}>Modal after selection</p>

                            </Col>
                            <Col xs={12} lg={6}>
                                <img style={styles.PhotoContainerProject} src={InitialEntryPointNotification} alt="InitialEntryPointNotification" />
                                <p style={styles.ImageCaption}>Entry point screen - end of flow</p>

                            </Col>

                        </Row>
                        <p style={styles.HeroDescription}>We iterated upon this by introducing meta-data onto the modal. This would communicate to the user, that the setting would not change their upcoming box’s shipment date. However, we would craft the intent of the copy in the action button to reflect the new frequency state, which we hypothesized would re-assure users of their action taken, while being informed that it would not immediately change their shipment date.</p>
                       
                        <p style={styles.HeroDescription}>The first prototype here, assumes the simplest possible flow, and is structurally the same as the previously implemented feature. However, the modal doesn’t precisely communicate what the change in frequency is affecting. Since the setting simply communicated the old frequency (6 weeks in this case), users might not have been sure of the immediate effect.</p>
                        <motion.div
                            style={styles.PhotoContainerProject}>
                            <video style={styles.PhotoContainerProject} src={InitialFlowPrototype} controls></video>
                            <p style={styles.ImageCaption}>Initial flow for changing frequency. We were optimizing for speed of task.</p>

                        </motion.div>
                        <p style={styles.HeroDescription}>Before design critique, I cleaned up the meta-data in terms of visual design, to ensure that we were using as little screen estate as possible, while still bringing emphasis.</p>
                        <p style={styles.HeroDescription}>We also iterated on copy for the modal, to be friendly, yet descriptive, as was used throughout our granular settings’ surface. Shipment Frequency, while describing the action well, did not mesh with the rest of our copy for settings at a similar granularity. For this reason, we changed it to “How often should we ship your box?”, with the psychological affordance that Curology is asking a friendly question, as opposed to a task about changing a password.</p>
                        <motion.div
                            style={styles.PhotoContainerProject}>
                            <img style={styles.PhotoContainerProject} src={FrequencyModalCrit} alt="FrequencyModalCrit" />

                            <p style={styles.ImageCaption}>Friendlier, more brand aligned copy for modal header.</p>

                        </motion.div>
                        <motion.div
                            style={styles.PhotoContainerProject}>
                            <img style={styles.PhotoContainerProject} src={CritiqueBoard} alt="CritiqueBoard" />
                            <p style={styles.ImageCaption}>Design critique board that I prepared to walk through initial explorations with the team in an informal group setting.</p>

                        </motion.div>

                        <p style={styles.HeroDescriptionBold}>Exploration 1</p>
                        <p style={styles.HeroDescription}>Dialog modal with single-select cells. Contextual anchor to shipping date.</p>
                        <p style={styles.HeroDescription}>Initially, I kept the design simple, showing contextual meta-data that showcases the shipping date for the user.</p>
                        <p style={styles.HeroDescription}>The intent here, was to provide the user context as to when their shipment was arriving, but also to scale as I asked our design team whether it’d be useful for users to change their most recent, upcoming box.</p>
                        <p style={styles.HeroDescriptionBold}>Exploration 2</p>
                        <p style={styles.HeroDescription}>Adding micro-copy under each option to provide user with additional context. We were essentially providing each option with corresponding meta-data. However, a question raised, was would the amount of text cause choice paralysis?</p>
                        <p style={styles.HeroDescriptionBold}>Exploration 3</p>
                        <p style={styles.HeroDescription}>A variation on our second exploration, but one that would automatically self select, as seen in products such as Hims / Dollar Shave club.</p>
                        <p style={styles.HeroDescription}>The second and third prototypes here were meant to foster discussion on potentially using micro-copy under each selection as metadata, testing out whether the removal of a CTA reduces friction. It also took the approach that users would be able to change the frequency of their next immediate box, if logically possible. However, feedback that we received centered on trying to keep the visual design of the modal itself as simple as possible, and one that should aim to “reduce” cognitive load, versus options that might induce some kind of choice paralysis (2nd and 3rd explorations)</p>
                        <p style={styles.SectionTitle}>Assupmtion to de-risk</p>
                        <p style={styles.HeroDescription}>Initially, after chatting with our CTO during critique, we went for an edge-case proof approach, keeping the logic as simple as possible.</p>
                        <p style={styles.HeroDescription}>This meant that our solution set was to only change cadence for boxes after a patients’ most recent upcoming box. (Therefore, we wouldn’t change when your next box is on its way, but would adjust the frequency after that box arrived).</p>
                        <p style={styles.HeroDescription}>The main challenge here, was communicating to the user and setting the expectation that they had indeed changed the frequency setting, even though the box affected would not be the upcoming one, but all subsequent packages afterward.</p>
                        <p style={styles.HeroDescription}>Did setting the frequency of the subsequent box set the right expectations for our patients versus the most recent upcoming box?</p>
                        <p style={styles.HeroDescription}>
                            <ul>
                                <li>Whilst we went ahead with the simple approach of the former for the first user test, we would be able to validate whether changing subscription frequency was setting the right expectations for users.</li>
                                <li>We would also go ahead and ask for their expectation within the user test, to glean insight as to whether changing subsequent boxes or changing their most recent box, and which one is expected behavior on their end.</li>
                            </ul>
                        </p>
                        <p style={styles.HeroDescriptionBold}>Visual design evolution of our dialog modal</p>

                    <Row>
                            <Col xs={12} lg={6}>
                                <img style={styles.PhotoContainerProject} src={FrequencyModalCrit} alt="FrequencyModalCrit" />
                                <p style={styles.ImageCaption}>Dialog modal after critique</p>

                            </Col>
                            <Col xs={12} lg={6}>
                                <img style={styles.PhotoContainerProject} src={FrequencyModalInspo} alt="FrequencyModalInspo" />
                                <p style={styles.ImageCaption}>Previous, generative exploration -- one of the reasons why I always keep old explorations stashed away, rather than deleting them. Design is a generative endeavor a lot of the time.</p>

                            </Col>

                        </Row>
                        <p style={styles.HeroDescription}>The above exploration was an old, generative iteration that I had come up with, with the intent to clearly delineate between selection, and meta-data. When discussing the feature after critique, we realized that if users’ did want to change their initial shipment whilst changing frequency, there would need to be screen real estate for scalable copy. An example would be if a user wanted to change their frequency, and in the default case, it would be able to show their shipment date. How about a user changing their frequency, but it being too late to change the frequency of their current box? It would accompany copy such as “This change only affects boxes shipped after Aug 20, 2020.” Would this scale well right below the modal header? Or is there a way to delineate meta-data and selection without an edge-case situation feeling crammed, and with visual breathing room.</p>



                    </motion.div>
                </Col>
            </Row>




            <Row start="lg" style={{ marginTop: "72px" }}>
                <Col xs={12} lg={2} lgOffset={1}>
                    <motion.div>
                        <p style={styles.SectionHeader}>Visual design details - evolution of modal after feedback and iteration</p>
                    </motion.div>
                </Col>
                <Col xs={12} lg={6} lgOffset={2}>
                    <motion.div>
                    <Row>
                            <Col xs={12} lg={6}>
                                <img style={styles.PhotoContainerProject} src={VisualModalInitial} alt="VisualModalInitial" />
                                <p style={styles.ImageCaption}>Initial modal</p>

                            </Col>
                            <Col xs={12} lg={6}>
                                <img style={styles.PhotoContainerProject} src={VisualModalSelection} alt="VisualModalSelection" />
                                <p style={styles.ImageCaption}>Modal when user makes a selection, in this example to change their frequency from 6 weeks to every 10 weeks</p>

                            </Col>

                        </Row>

                        <p style={styles.HeroDescription}>To that end, we iterated on the dialog modal, housing a recommended chip, as well as a contextual micro copy to remind user what their current frequency was.</p>
                        <p style={styles.HeroDescription}>The intent for the recommended chip came with an insight during critique, where one of our in-house providers mentioned that most providers go ahead with the 6 week duration as a matter of best practice, and in some cases, changes might not always be effective in terms of frequency change. Taking this feedback in, we added the recommended chip as a frictional affordance for our patient, so that they would change their shipment frequency only if they absolutely needed to / wanted to.</p>
                        <p style={styles.HeroDescription}>Once a user makes a selection, we utilize scalable micro-copy below the selection options, with copy in this situation clearly telling the user that the frequency change would affect boxes shipped after a certain date (as decided tentatively during critique). However, this would also scale well if the frequency changed would be immediate, and the copy would then be altered to “Next Billing and Shipping Date: [x date]”.</p>
                        <p style={styles.HeroDescription}>The change from check-mark, to a radio style selection was critical from a visual design perspective. Even though we had used the check-box within the product, recent changes in the design system meant that for single select actions, such as picking a single frequency in this case, it’d offer more clarity to use radio style single select throughout our experience.</p>

                    </motion.div>
                </Col>
            </Row>

            <Row start="lg" style={{ marginTop: "72px" }}>
                <Col xs={12} lg={2} lgOffset={1}>
                    <motion.div>
                        <p style={styles.SectionHeader}>Testing and moving forward</p>
                    </motion.div>
                </Col>
                <Col xs={12} lg={6} lgOffset={2}>
                    <motion.div>
                        <p style={styles.SectionTitle}>User Test 1</p>
                        <motion.div
                            style={styles.PhotoContainerProject}>
                            <video style={styles.PhotoContainerProject} src={PrototypeUserTestV1} controls></video>
                            <p style={styles.ImageCaption}>User test interactive prototype</p>

                        </motion.div>
                        <p style={styles.HeroDescription}>We ran an unmoderated test on 10 users, and had some really interesting findings from it.</p>
                        <p style={styles.HeroDescriptionBold}>Learnings</p>
                        <p style={styles.HeroDescription}>A critical problem we found in user testing early on, was that patients were confused about how changing the frequency was affecting their shipment dates.</p>
                        <p style={styles.HeroDescription}>A few were essentially confused in the first user test over the settings change affecting their next box versus the subsequent box.</p>
                        <p style={styles.HeroDescription}>Whilst some patients got their next shipment date right, several users were also confused over the header on the card showcasing the new frequency setting, with the shipping date unchanged. (even though the setting had changed internally and we hypothesized that adding contextual copy would mitigate such confusion).</p>
                        <p style={styles.HeroDescription}>
                            <ul>
                                <li>This was at the end-state, or when the patient had gone in the flow and completed the change of frequency.</li>
                            </ul>
                        </p>
                        <p style={styles.HeroDescription}>The user testing session made clear that we needed to set the correct expectation for how updating the frequency affects a shipment date. We thus knew, that iterating on copy was essential.</p>
                        <p style={styles.HeroDescription}>However, testing validated that the action of finding and changing subscription frequency itself was frictionless and something we could build upon. Task completion, which was optimized for speed, was meeting its desired result in front of the users.</p>
                        
                        <p style={styles.SectionTitle}>Changes in design from 1st to 2nd user test</p>
                        <Row>
                            <Col xs={12} lg={6}>
                                <img style={styles.PhotoContainerProject} src={V1CopyDiff} alt="V1CopyDiff" />
                                <p style={styles.ImageCaption}>Copy for 1st user test, after setting change</p>

                            </Col>
                            <Col xs={12} lg={6}>
                                <img style={styles.PhotoContainerProject} src={V2CopyDiff} alt="V2CopyDiff" />
                                <p style={styles.ImageCaption}>Copy for 2nd user test, after setting change</p>

                            </Col>

                        </Row>

                        <p style={styles.SectionTitle}>User Test 2</p>
                        <motion.div
                            style={styles.PhotoContainerProject}>
                            <video style={styles.PhotoContainerProject} src={PrototypeUserTestV2} controls></video>
                            <p style={styles.ImageCaption}>User test (round 2) interactive prototype</p>
                        </motion.div>

                        <p style={styles.HeroDescription}>On our second round of user tests, we were able to iterate on the end state of our experience. More specifically, the expectation we were setting on the subscription surface, after a frequency setting was changed. In this situation, we set a clear expectation with reactive copy on the modal (“This change only affects boxes shipped after [x date]”).</p>
                        <p style={styles.HeroDescription}>However, on the frequency card, the header shows the same frequency as previous, since in this scenario, we would only change frequency of the subscription once a patients’ most recent box is shipped. This header copy is contextualized with clear expectation setting, where if a patient has changed their frequency, it says</p>
                        <p style={styles.HeroDescription}>
                            <ul>
                                <li>“We’ll ship your box on [current shipment date for recent box], then every 10 weeks after that.”</li>
                                <li>Use of typography and weight, we hypothesized, would add clarity for our patients in the end state, and the copy itself would provide clear context as to when the changes in setting would propagate.</li>
                                <li>Done in close collaboration with Tiffany, our copywriter.</li>
                            </ul>
                        </p>
                        <p style={styles.HeroDescription}>Whilst the right expectation was set at our end state, 8 out of 10 users that we ran the test on, expected that changing their frequency would affect their most recent, upcoming shipment date. Although all our users, for this test, got their new date right upon completing the flow, it was an interesting insight that our users’ had expected a change in recent shipping date upon changing frequency, as well.</p>

                    </motion.div>
                </Col>
            </Row>



            <Row start="lg" style={{ marginTop: "72px" }}>
                <Col xs={12} lg={2} lgOffset={1}>
                    <motion.div>
                        <p style={styles.SectionHeader}>Taking our learnings to craft a final, scalable solution.</p>
                        <p style={styles.SectionBlurb}>Before getting down on crafting the final solution, we discussed with the engineers, as well as the PM who launched the initial experiment, that we would intend to use the exact backend logic as the previous experiment to cut down on development time.</p>
                    </motion.div>
                </Col>
                <Col xs={12} lg={6} lgOffset={2}>
                    <motion.div>
                        <p style={styles.HeroDescription}>Whilst the current backend logic was designed to have a condition to make sure that frequency was only being changed after the current shipment date had passed, how it worked was that the logic was changing shipments’ frequency, including the current date, if it was possible to change it.</p>
                        <p style={styles.HeroDescription}>In a scenario where the patients’ subscription frequency is 6 weeks, and their shipment was arriving on August 20th, if the patient changed their frequency to a setting greater than 6, the backend was able to facilitate that change, and set the next billing and shipping date to 2 weeks after the 20th August (expected behavior).</p>
                        <p style={styles.HeroDescription}>
                            <ul>
                                <li>This version was not launched in the previous experiment, as there wasn’t much design work done and the PM raised that it could be quite ambiguous.</li>
                            </ul>
                        </p>
                        <p style={styles.HeroDescription}>A clear insight that we had from our two rounds of testing, was that users expected, across the two tests, that they would be able to change the closest upcoming shipment date on average.</p>

                        <motion.div
                            style={styles.PhotoContainerProject}>
                            <video style={styles.PhotoContainerProject} src={FinalCadencePrototype} controls></video>

                            <p style={styles.ImageCaption}>Final prototype</p>

                        </motion.div>

                        <p style={styles.HeroDescription}>To this end, we split our experience, with the constraint of our backend logic, into two discrete paths, one being the happy path, and one being the edge-case scenario</p>
                        <p style={styles.HeroDescriptionBold}>Happy path design</p>
                        <motion.div
                            style={styles.PhotoContainerProject}>
                            <img style={styles.PhotoContainerProject} src={HappyPathBoard} alt="HappyPathBoard" />

                            <p style={styles.ImageCaption}>Scenario - happy path</p>

                        </motion.div>
                        <p style={styles.HeroDescription}>Scenario:  screen date - Sep 1st, I got a box on this date.</p>
                        <p style={styles.HeroDescription}>Currrent cadence -> 6 weeks. (next billing and shipping date - Oct 13th.</p>
                        <p style={styles.HeroDescription}>After changing frequency to 8 weeks -> new billing and shipping date is October 27th.</p>

                        <p style={styles.HeroDescriptionBold}>Edge case design</p>
                        <motion.div
                            style={styles.PhotoContainerProject}>
                            <img style={styles.PhotoContainerProject} src={EdgeCaseBoard} alt="EdgeCaseBoard" />

                            <p style={styles.ImageCaption}>Scenario - edge case</p>

                        </motion.div>

                        <p style={styles.HeroDescription}>Scenario:  screen date - Sep 1st.</p>
                        <p style={styles.HeroDescription}>Current cadence -> 8 weeks. (next billing and shipping date - Sep 8.</p>
                        <p style={styles.HeroDescription}>after changing frequency to 6 weeks  -> Modal to confirm final cadence change -> new billing and shipping date is still September 8th, and cadence is changed afterwards.</p>

                        <p style={styles.HeroDescription}>The intent behind this decision making was two fold. Whilst we would use the same backend logic as the previously launched experiment (keeping it constrained), in fact with just a change of conditional statements, this would also match up with a patients’ mental model of editing their frequency, as validated by users during testing.</p>
                        <p style={styles.HeroDescription}>That’s why, it is always always important to talk and validate with the end user. If one goes into testing, attached to a solution at hand, it leads to blind spots being uncovered by the user themselves.</p>
                        <p style={styles.HeroDescription}>Validating with users across two rounds of testing here helped us de-risk an earlier assumption that users’ might prefer a change to their subscription only after their recent box came in, and we were thus confident in shipping out this solution to a third of our cohort.</p>
                        <p style={styles.HeroDescriptionBold}>Scaled to Desktop</p>
                        <motion.div
                            style={styles.PhotoContainerProject}>
                            <img style={styles.PhotoContainerProject} src={DesktopDesign} alt="DesktopDesign" />

                            <p style={styles.ImageCaption}>Desktop designs</p>

                        </motion.div>
                    </motion.div>
                </Col>
            </Row>



            <Row start="lg" style={{ marginTop: "72px" }}>
                <Col xs={12} lg={2} lgOffset={1}>
                    <motion.div>
                        <p style={styles.SectionHeader}>Result and Impact</p>
                        <p style={styles.SectionBlurb}>We released the initial feature to 1/3 of all Curology patients for the first three months, before releasing to the full user base. Update: Subscription cadence is now a permanent feature within the Curology subscription experience.</p>
                    </motion.div>
                </Col>
                <Col xs={12} lg={6} lgOffset={2}>
                    <motion.div>
                        <p style={styles.HeroDescription}>Relative lift of 25% retention in cohort that changed frequency at least once.</p>
                        <p style={styles.HeroDescription}>10% relative lift in cohort that stayed on default frequency.</p>
                    </motion.div>
                </Col>
            </Row>

            <Row start="lg" style={{ marginTop: "72px" }}>
                <Col xs={12} lg={2} lgOffset={1}>
                    <motion.div>
                        <p style={styles.SectionHeader}>Reflection</p>
                    </motion.div>
                </Col>
                <Col xs={12} lg={6} lgOffset={2}>
                    <motion.div>
                    <p style={styles.HeroDescription}></p>
                        <p style={styles.HeroDescription}>To work in tandem with a team that not only respects, but deeply values both user testing and copy as a way to enhance a patients’ experience was fantastic. We were able to clearly test out our riskiest assumptions, and iterate towards a better path forward for our patients’ which offered them flexibility, but not at the cost of confusion, and I’m glad that I was able to take the lead on such a project that had a direct impact on the business metrics of the company.</p>
                        <p style={styles.HeroDescription}>Looking back, however, one thing I would have loved to engage in was deeper multi-variate testing. To de-risk our assumptions and move towards data informed design, it would have been great to test out our solution within the 2nd user test to a broader set of users.</p>
                        <p style={styles.HeroDescription}>In essence, it would have provided statistical significance in terms of feature usage, as well as making certain which solution is more successful with users in terms of retention.</p>
                        <p style={styles.HeroDescription}>Even though we had validated with users during user testing, that our final solution was matching a users’ mental model, the certainty of a multi-variate, or even two-variant test with a larger sample size would have provided more certain evidence that the solution we went with, was the right one. This is especially true as it pertains to “feature usage over time”, and whether there would be a significant delta between the two variants.</p>
                        <p style={styles.HeroDescription}></p>
                        <p style={styles.HeroDescription}></p>
                        <p style={styles.HeroDescription}></p>
                        <p style={styles.HeroDescription}></p>
                        <p style={styles.HeroDescription}></p>

                    </motion.div>
                </Col>
            </Row>



            <Row start="lg" style={{ marginTop: 32 }}>
                <Col xs={12} lg={5} lgOffset={1}>

                    <motion.div style={{ paddingBottom: 64 }}>
                        <motion.a initial="initial" whileHover="hover" variants={InlineLink} style={styles.SectionBlurb} href="/" onClick={()=> gaEventTracker('Homepage footer')}>Go back home</motion.a>

                    </motion.div>

                </Col>
            </Row>
        </Grid>



    )
}

export default CurologyCadence;