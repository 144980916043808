import * as React from "react"
import { render } from "react-dom"
import { motion } from "framer-motion"
import ImageCadence from '../Assets/Curology/CurologyHome.png'
import { Grid, Row, Col } from "react-flexbox-grid"

//will essentially import all project images to this component, and use them as different props for 
//our homepage when customizing the card. 



export function ProjectCard(props: any) {

    //props for a project card 

    //props for a project card 
    const {
        ProjectImage, TagWork, TagLocation, ProjTitle, ProjDescription, Theme, TagColor,
    } = props


    //add type annotation and nest constant styles
    //will probably have to changes this with interfaces?
    const styles = {


        //card container

        CardContainer: {
            display: "flex",
            alignItems: "space-between",
            height: 860,
            marginTop: '24px',
            width: "100%",
            borderRadius: 0,
            flexDirection: "column",
            justifyContent: "start",

        } as const,

        ActionContainer: {
            display: "flex",
            flexDirection: "row",
            justifyContent: "start",
            marginLeft: 24,
        } as const,

        //photo-container for project image

        /*TO-DO -> add way for project-image to take on max width and height of this container*/
        PhotoContainer: {
            display: "flex",
            width: "75%",
            minHeight: 372,
            maxHeight: 372,
            alignItems: "center",
            justifyContent: "center",
            overflow: "hidden",
            borderRadius: 4,
            margin: "24px 24px 24px 24px",
        },

        //style for action text that reveals itself on hover
        //we use as const after the style declaration to infer literal values on the whole object
        ActionText: {
            display: "inline-flex",
            flex: 0,
            minWidth: 120,
            overflow: "visible",
            fontFamily: `"IBM Plex Mono", monospace`,
            color: "#fff",
            textDecoration: "underline",
            fontSize: 20,
            letterSpacing: 0,
            lineHeight: 1.2,
            fontWeight: 700,
            fontStyle: "normal",
            backgroundColor: "backgroundColor: 'rgba(52, 52, 52, 0)",

        } as const,

        ChipWork: {
            boxSizing: "border-box",
            width: "30%",
           // maxWidth:"100%",
            height: 32,
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-evenly",
            alignItems: "center",
            padding: 8,
            overflow: "visible",
            backgroundColor: "#343434",
            borderRadius: 4,
            fontFamily: `"IBM Plex Mono", monospace`,
            color: TagColor,
            fontSize: "0.925em",
            letterSpacing: "0.00625em",
            lineHeight: 1.2,
            fontWeight: 700,
            fontStyle: "normal",
            textAlign: "center",
            marginBottom: 16,
        } as const,


        ChipLocation: {
            boxSizing: "border-box",
            width: "30%",
            height: 32,
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-evenly",
            alignItems: "center",
            padding: 8,
            overflow: "hidden",
            backgroundColor: Theme,
            borderRadius: 4,
            fontFamily: `"IBM Plex Mono", monospace`,
            color: 'rgba(255,255,255,0.9)',
            fontSize: "0.925em",
            letterSpacing: "0.00625em",
            lineHeight: 1.2,
            fontWeight: 700,
            fontStyle: "normal",
            textAlign: "center",
        } as const,

        ProjectTitle: {
            textDecoration: "none",
            fontFamily: `"IBM Plex Mono", monospace`,
            color: "#FFF",
            fontSize: "1.563em",
            letterSpacing: "0.01em",
            height: 48,
            width:"100%",
            lineHeight: 1.2,
            fontWeight: 700,
            textAlign: "left",
            backgroundColor: "rgba(0,0,0,0)",

        } as const,

        ProjectDescription: {
            width: "100%",
            overflow: "visible",
            fontFamily: `"Inter", sans-serif`,
            color: "#fff",
            fontSize: "1em",
            letterSpacing: "0.00625em",
            lineHeight: 1.5,
            fontWeight: 400,
            textAlign: "left",
            backgroundColor: "rgba(0,0,0,0)",
        } as const,

        TextContainer: {
            boxSizing: "border-box",
            width: "95%",
            height: 360,
            display: "flex",
            flexDirection: "column",
            justifyContent: "start",
            padding: "0px 0px 0px 24px",
            overflow: "visible",
        } as const,


        CTA: {
            display: "flex",
            bottom: 0,
            flexDirection: "row",
            justifyContent: "start",
            marginLeft: -124,
            flex: 0,
            width: 64,
            height: 64,
            overflow: "visible",
            backgroundColor: Theme,
            borderRadius: 4,
        } as const,


    }


    /*****************Animation Code***************/

    //animation easing constant

    const mainTransition = {
        ease: [0.22, 1, 0.36, 1],
        duration: 0.25,
    }

    const cardAnimation = {
        default: {
            scale: 1.0,
            backgroundColor: "#27292D",
        },
        hover: {
            scale: 1.0,
            backgroundColor: "#27292D",
//            borderStyle: "solid",
  //          borderColor: "white",
            transition: { delayChildren: 0.025, duration: 0.25 },
        }
    }

    const actionAnimation = {
        default: { x: 0 },
        hover: {
            x: '225%',
            y: 0,
            transition: { mainTransition },
            backgroundColor: "#27292D"
        },
    }

    const tagAnimationWork = {
        default: { backgroundColor: "#343434" },
        hover: { backgroundColor: "#343434", transition: { duration: 0.015 } },
    }


    //blinking animation for "Read More" text
    const actionTextAnimation = {
        default: { opacity: 0 },
        hover: { opacity: 1, transition: { duration: 1.25, loop: Infinity } },
    }

    /*****************Layout Code***************/



    return (
        <motion.div
            variants={cardAnimation}
            initial="default"
            whileHover="hover"
            style={styles.CardContainer}
        >
            <motion.div style={styles.PhotoContainer}>
                <img src={ProjectImage} style={{ width: "100%", maxWidth: "100%", maxHeight: "100%", backgroundColor: Theme }} alt="Curology case study" />
            </motion.div>
            <motion.div
                style={styles.TextContainer}
            >
                <motion.div style={styles.ChipWork} variants={tagAnimationWork}>{TagWork}</motion.div>
                <motion.div style={styles.ChipLocation} >{TagLocation}</motion.div>
                <motion.p style={styles.ProjectTitle} >{ProjTitle}</motion.p>
                <motion.p style={styles.ProjectDescription}>{ProjDescription}</motion.p>
            </motion.div>
            <motion.div style={styles.ActionContainer}>
            <motion.p style={styles.ActionText} variants={actionTextAnimation}>Read More</motion.p>

                <motion.div style={styles.CTA} variants={actionAnimation}>
                    <svg
                        style={{ margin: "16" }}
                        width="32"
                        height="32"
                        viewBox="0 0 32 32"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <mask
                            id="mask0"
                            mask-type="alpha"
                            maskUnits="userSpaceOnUse"
                            x="0"
                            y="0"
                            width="32"
                            height="32"
                        >
                            <path
                                d="M0 17.58L27.18 17.58L14.52 30.22L16.3 32L32 16.28L15.64 0.540001L13.9 2.34L27.16 15.08L0 15.08L0 17.58Z"
                                fill="white"
                            />
                        </mask>
                        <g mask="url(#mask0)">
                            <rect
                                x="32"
                                y="32"
                                width="32"
                                height="32"
                                transform="rotate(-180 32 32)"
                                fill="white"
                            />
                        </g>
                    </svg>
                </motion.div>



            </motion.div>


        </motion.div>




    );

}

ProjectCard.defaultProps = {
    Theme: "#332E54",
    TagColor: "#6FCF97",
    ProjTitle: "Designing a flexible way for patients to modify their subscription frequency.",
    ProjDescription: "Working cross-functionally to design a flexible way for patients to modify the frequency with which their personalized box arrives within the subscriptions surface, and lift long term retention for users not adhering to default patterns in cream usage.",
    TagWork: "Product Design",
    TagLocation: "Curology",
    ProjectImage: ImageCadence,
}

//have root element and render code 
const rootElement = document.getElementById("root")
render(<ProjectCard />, rootElement)