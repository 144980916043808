import * as React from "react"
import '../grid.css'
import { motion } from "framer-motion"

import { Link } from 'react-router-dom'
import { NavBar } from '../Components/NavBar'

import { Grid, Row, Col } from "react-flexbox-grid"
//adding support for IBM Plex Mono as a font.
//PagerDuty asset imports
import ImagePd from '../Assets/PagerDuty/PD-mock.png'

//image imports for PD case
import CurrentWorkflow from '../Assets/PagerDuty/PagerDuty-prev-workflow.png'
import NewWorkflow from '../Assets/PagerDuty/PagerDuty-new-workflow.png'
import InitialSketchV1 from '../Assets/PagerDuty/Sketch-1.jpg'
import InitialSketchV2 from '../Assets/PagerDuty/Sketch-2.jpg'
import SeperatedTableDesign from '../Assets/PagerDuty/PD-Design-Folder/Sol-B.png'
import TagBasedSearch from '../Assets/PagerDuty/PD-Design-Folder/Sol-C.png'
import SegmentedControlDesign from '../Assets/PagerDuty/PD-Design-Folder/Sol-E_users_contextswitch.png'
import DataTableA from '../Assets/PagerDuty/PD-Design-Folder/Data_table_a.png'
import DataTableB from '../Assets/PagerDuty/PD-Design-Folder/Big_data_table_b.png'
import DataTableC from '../Assets/PagerDuty/PD-Design-Folder/Data_table_c.png'
import InitialPrototype from '../Assets/PagerDuty/PD-Design-Folder/Iteration-1-prototype.mov'
import V2Prototype from '../Assets/PagerDuty/PD-Design-Folder/Iteration-2-prototype.mov'
import FinalPrototype from '../Assets/PagerDuty/PD-Design-Folder/Final-design-prototype.mov'
import useAnalyticsEventTracker from '../useAnalyticsEventTracker';


import "fontsource-jost"

//adding CSS styles inline, as we do
const styles = {
    Page: { backgroundColor: "#27292D" },

    TopTitle: {
        fontFamily: `"IBM Plex Mono", monospace`,
        color: "rgba(255, 255, 255, 0.9)",
        fontSize: "1.25em",
        letterSpacing: "0.0001em",
        lineHeight: 1.2,
        fontWeight: 500,
        fontStyle: "normal",

    } as const,

    HeroDescription: {
        fontFamily: `"Inter", sans-serif`,
        color: "rgba(255, 255, 255, 0.9)",
        fontSize: "1.25em",
        letterSpacing: "0.00625em",
        lineHeight: 1.65,

    } as const,

    HeroDescriptionBold: {
        fontFamily: `"Inter", sans-serif`,
        color: "rgba(255, 255, 255, 0.9)",
        fontSize: "1.25em",
        letterSpacing: "0.00625em",
        lineHeight: 1.65,
        fontWeight: 700,

    } as const,

    HeroContainer: {
        width: 424,
        height: 74,
        marginTop: 64,
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-evenly",
        alignItems: "center",
        overflow: "visible",
        background: "#27292D",
    } as const,


    Style: {
        marginTop: 72,
        width: "100%",
    } as const,

    // styles for text and images, etc. 
    PhotoContainer: {
        maxWidth: "100%",
        overflow: "hidden",
        borderRadius: 4,
        margin: "24px 24px 24px 24px",

    } as const,

    ProjectIntro: {
        fontFamily: `"IBM Plex Mono", monospace`,
        color: "rgba(255, 255, 255, 0.9)",
        fontSize: "1.563rem",
        letterSpacing: "0.00625em",
        lineHeight: 1.2,
        fontWeight: 700,
        alignText: "center",
        paddingLeft: 0,
        paddingBottom: 32,
        paddingTop: 16,
    } as const,

    ProjectHeaderContainer: {
        width: "100%",
        height: 64,
        paddingBottom: 48,
        alignItems: "center",
        margin: "24px 24px 24px 24px",

    } as const,

    OverviewChip: {
        boxSizing: "border-box",
        width: "40%",
        height: 24,
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-evenly",
        alignItems: "center",
        padding: 16,
        overflow: "hidden",
        backgroundColor: "#343434",
        borderRadius: 4,
        fontFamily: `"IBM Plex Mono", monospace`,
        color: "#FFF",
        fontSize: "0.9em",
        letterSpacing: "0.00625em",
        lineHeight: 1.2,
        fontWeight: 700,
        fontStyle: "normal",
        textAlign: "center",
        marginBottom: "16px",
    } as const,

    OverviewChipType: {
        boxSizing: "border-box",
        width: "40%",
        height: 24,
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-evenly",
        alignItems: "center",
        padding: 16,
        overflow: "hidden",
        backgroundColor: "#41BC00",
        borderRadius: 4,
        fontFamily: `"IBM Plex Mono", monospace`,
        color: "#FFF",
        fontSize: "0.9em",
        letterSpacing: "0.00625em",
        lineHeight: 1.2,
        fontWeight: 700,
        fontStyle: "normal",
        textAlign: "center",
        marginBottom: "16px",
    } as const,
    OverviewChipWrapper: {
        boxSizing: "border-box",
        width: "100%",
        height: 24,
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-evenly",
        alignItems: "left",
        padding: "16px 16px 16px 0px",
        overflow: "visible",
        backgroundColor: "rgba(255, 255, 255,0)",
        borderRadius: 4,
        fontFamily: `"Inter", sans-serif`,
        color: "#FFF",
        fontSize: 16,
        letterSpacing: "0.00625em",
        lineHeight: 1.2,
        fontWeight: 700,
        fontStyle: "normal",
        textAlign: "center",
        marginBottom: "16px",
    } as const,

    OverviewTitle: {
        fontFamily: `"IBM Plex Mono", monospace`,
        color: "rgba(255, 255, 255, 0.9)",
        fontSize: "1.563rem",
        letterSpacing: "0.00625em",
        lineHeight: 1.2,
        fontWeight: 700,

    } as const,

    OverviewText: {
        fontFamily: `"Inter", sans-serif`,
        color: "rgba(255, 255, 255, 0.9)",
        fontSize: "1.25rem",
        letterSpacing: "0.00625em",
        lineHeight: 1.2,
        fontWeight: 400,

    } as const,

    SectionHeader: {
        fontFamily: `"IBM Plex Mono", monospace`,
        color: "rgba(255, 255, 255, 0.9)",
        fontSize: "1.563rem",
        letterSpacing: "0.00625em",
        lineHeight: 1.2,
        fontWeight: 700,
        minWidth: "400px",
    },

    SectionBlurb: {
        fontFamily: `"IBM Plex Mono", monospace`,
        color: "rgba(255, 255, 255, 0.9)",
        fontSize: "1rem",
        letterSpacing: "0.00625em",
        lineHeight: 1.2,
        fontWeight: 400,
        minWidth: "340px",
    },

    SectionTitle: {
        fontFamily: `"IBM Plex Mono", monospace`,
        color: "rgba(255, 255, 255, 0.9)",
        fontSize: "1.25rem",
        letterSpacing: "0.00625em",
        lineHeight: 1.2,
        fontWeight: 700,

    },

    SectionText: {

    },

    PhotoContainerProject: {
        maxWidth: "100%",
        maxHeight: "900px",
        overflow: "visible",
        margin: "24px 24px 0px 0px"
    } as const,

    PhotoContainerTable: {
        maxWidth: "100%",
        maxHeight: "900px",
        overflow: "visible",
        margin: "24px 24px 0px 0px",
        background: "white"
    } as const,


    ImageCaption: {
        fontFamily: `"Inter", sans-serif`,
        color: "rgba(255, 255, 255, 0.6)",
        fontSize: "1.0rem",
        letterSpacing: "0.00625em",
        lineHeight: 1.2,
        fontWeight: 600,
    } as const,



}

//variants for inline links 

const InlineLink = {

    initial: {
        textDecoration: "none",

        opacity: "75%"
    },
    hover: {
        textDecoration: "underline",

        opacity: "100%"
    },


} as const


// variants for motion div (TITLE) -

const titleVariants = {
    initial: {
        opacity: 1,
        x: 0,
        fontSize: "3.815em",
    },
    in: {
        opacity: 1,
        x: 0,
        transition: {
            type: "ease",
            ease: [0.22, 1, 0.36, 1],
            duration: 0.75,
            x: { stiffness: 100, velocity: -100 },
        },
        fontSize: "1.563em",
    },
    out: {
        opacity: 1,
        x: "100vw",
    },
}



function PagerDuty(): JSX.Element {
    const gaEventTracker = useAnalyticsEventTracker('PagerDuty case study page')
    return (
        <Grid fluid>
            <Row top="lg" around="lg">
                <Col lg={5} lgOffset={1}>
                    <motion.div
                        style={styles.Style}
                        initial="initial"
                        animate="in"
                        exit="out"
                        variants={titleVariants}
                    >
                        <p style={styles.TopTitle}>Chaitanya Prashant</p>

                    </motion.div>

                </Col>
                <Col lg={1} lgOffset={2} xs={3} xsOffset={2} style={{ marginTop: 48 }}>
                    <Link to='/' onClick={()=> gaEventTracker('Home page top nav')}>
                        <NavBar
                            Active={false}
                        />
                    </Link>
                </Col>
                <Col lg={1} xs={4} style={{ marginTop: 48 }}>
                    <Link to='/Pages/About' onClick={()=> gaEventTracker('About page top nav')}>
                        <NavBar
                            Active={false}
                            Text="About Me"
                        />

                    </Link>
                </Col>
            </Row>


            <motion.div>

                <Row start="lg" style={{ marginTop: 64 }}>
                    <Col xs={10} md={6} mdOffset={3} lg={6} lgOffset={3}>
                        <motion.div style={styles.ProjectHeaderContainer}>
                            <motion.div style={{ width: "100%", textAlign: "center" }}>
                                <p style={styles.ProjectIntro}>PagerDuty Teams</p>
                            </motion.div>
                            <motion.div
                                style={styles.OverviewChipWrapper}
                            >
                                <motion.div
                                    style={styles.OverviewChip}
                                >Internship</motion.div>
                                <motion.div
                                    style={styles.OverviewChipType}
                                >Summer 2018</motion.div>
                            </motion.div>

                        </motion.div>

                    </Col>
                </Row>


                <Row start="lg" style={{ marginTop: 64 }}>
                    <Col xs={10} lg={6} lgOffset={3}>
                        <motion.div
                        >
                            <img style={styles.PhotoContainer} src={ImagePd} alt="Wizehire Dashboard" />
                        </motion.div>

                    </Col>
                </Row>
            </motion.div>




            <Row start="lg">
                <Col xs={10} xsOffset={1} lg={5} lgOffset={1}>
                    <motion.div>
                        <p style={styles.OverviewTitle}>Overview</p>
                        <p style={styles.OverviewText}>Redesigning PagerDuty's teams configuration feature to help administrators make triaged incident management decisions with more confidence.</p>
                    </motion.div>
                    <motion.div>
                        <p style={styles.OverviewTitle}>Role</p>
                        <p style={styles.OverviewText}>Designer working in a cross functional product team.</p>
                    </motion.div>
                    <motion.div>
                        <p style={styles.OverviewTitle}>Timeline</p>
                        <p style={styles.OverviewText}>Summer 2018 - 4 weeks</p>
                    </motion.div>

                </Col>
                <Col xs={10} xsOffset={1} lg={5} lgOffset={1}>
                    <motion.div>
                        <p style={styles.OverviewTitle}>Responsibilities</p>
                        <p style={styles.OverviewText}>Design research, interaction and visual design, and product thinking.</p>
                    </motion.div>
                    <motion.div>
                        <p style={styles.OverviewTitle}>Team</p>
                        <ul style={styles.OverviewText}>
                            <li>Chaitanya Prashant - Design</li>
                            <li>Elliott Onn - UX Design (manager)</li>
                            <li>Justin Lazaro - Product Owner</li>
                        </ul>
                    </motion.div>
                </Col>

            </Row>

            <Row start="lg" style={{ marginTop: "72px" }}>
                <Col xs={12} lg={2} lgOffset={1}>
                    <motion.div>
                        <p style={styles.SectionHeader}>Introduction</p>

                    </motion.div>
                </Col>
                <Col xs={12} lg={6} lgOffset={2}>
                    <motion.div>
                        <p style={styles.HeroDescription}>As a UX design intern at PagerDuty, I was tasked with designing a way to view administrator and team based data in a holistic way so that they can make Incident Management decisions.</p>
                        <p style={styles.HeroDescription}>I worked in close co-ordination with our team's PM, Justin, our embedded UX designer and mentor, Elliot, and two software engineers.</p>

                    </motion.div>
                </Col>
            </Row>
            <Row start="lg" style={{ marginTop: "72px" }}>
                <Col xs={12} lg={2} lgOffset={1}>
                    <motion.div>
                        <p style={styles.SectionHeader}>Problem</p>
                        <motion.div
                            style={styles.PhotoContainerProject}
                        >
                            <img style={styles.PhotoContainerProject} src={CurrentWorkflow} alt="CurrentWorkflow" />

                            <p style={styles.ImageCaption}>Current workflow</p>

                        </motion.div>
                    </motion.div>

                </Col>
                <Col xs={12} lg={6} lgOffset={2}>
                    <motion.div>
                        <p style={styles.HeroDescription}>Team administrators are unable to view and edit, at a macro level, what users and objects are part of a parent team’s sub teams, causing confusion in the incident management process.</p>
                        <p style={styles.HeroDescription}>This caused team managers and administrators to start configuring teams by Creating a team or subteam. -> going to an object tab (for example: escalation policies) -> configuring or adding that object to a specific team, or multiple teams from there.</p>
                        <p style={styles.HeroDescription}>While this process seemed to have more friction, it made sense from the user’s point of view because they didn’t have to go on each subteam and add it manually from there.</p>
                        <p style={styles.HeroDescription}><b>Feature usage: -15% over the last six months across enterprise accounts.</b></p>
                        <p style={styles.HeroDescription}>Did this mean that the teams setting feature was redundant?</p>
                        <p style={styles.HeroDescription}>Referring back to some previous user research notes,along with auditing the product and the workflow for administrators using teams and synthesizing this information, we realized that the user’s intent when using the teams setting was to quickly view, as well as add users, escalation policies and objects to different incident management teams, based on the parent team’s domain expertise which allows administrators to triage incident response, and manage incidents in a less stressful way.</p>
                        <p style={styles.HeroDescription}>We also used the Jobs to Be Done frameowork to reframe the problem to include the user's motivation, and it gave us as a team a clear design goal to work towards.</p>
                        <p style={styles.SectionTitle}>Design Goal</p>
                        <p style={styles.HeroDescription}><b>How might we surface relevant information at a high level for administrators and team managers so that they can make incident management decisions with confidence?</b></p>
                    </motion.div>
                </Col>
            </Row>

            <Row start="lg" style={{ marginTop: "72px" }}>
                <Col xs={12} lg={2} lgOffset={1}>
                    <motion.div>
                        <p style={styles.SectionHeader}>Hypothesis</p>
                        <motion.div
                            style={styles.PhotoContainerProject}
                        >
                            <img style={styles.PhotoContainerProject} src={NewWorkflow} alt="NewWorkflow" />

                            <p style={styles.ImageCaption}>Redesigned workflow</p>
                        </motion.div>
                    </motion.div>
                </Col>
                <Col xs={12} lg={6} lgOffset={2}>
                    <motion.div>
                        <p style={styles.HeroDescription}>A simplified workflow for viewing and configuring teams based objects, that lets administrators make incident management decisions with confidence.</p>
                        <p style={styles.HeroDescriptionBold}>Success metric</p>
                        <p style={styles.HeroDescription}>10% increase in feature usage for new users during multivariate testing.</p>
                        <p style={styles.HeroDescriptionBold}>Why this metric?</p>
                        <p style={styles.HeroDescription}>We observed through our analytics tool (Pendo), that while 80% of our users were landing on this feature, over time, they resorted to using the alternate workflow mentioned above (going through individual objects)</p>
                        <p style={styles.HeroDescription}>This would help us measure if our redesign effectively reduced the friction that our users faced during this process.</p>
                    </motion.div>
                </Col>
            </Row>

            <Row start="lg" style={{ marginTop: "72px" }}>
                <Col xs={12} lg={2} lgOffset={1}>
                    <motion.div>
                        <p style={styles.SectionHeader}>Initial Sketches</p>
                        <p style={styles.SectionBlurb}>I sketched out various ideas I had for a solution to the problem space, and narrowed down the concept to a few ideas after validating them with our PM and UX Designer.</p>
                    </motion.div>
                </Col>
                <Col xs={12} lg={6} lgOffset={2}>
                    <motion.div>
                        <Row>
                            <Col xs={12} lg={6}>
                                <img style={styles.PhotoContainerProject} src={InitialSketchV1} alt="InitialSketchV1" />

                            </Col>
                            <Col xs={12} lg={6}>
                                <img style={styles.PhotoContainerProject} src={InitialSketchV2} alt="InitialSketchV2" />
                            </Col>
                        </Row>
                    </motion.div>
                </Col>
            </Row>


            <Row start="lg" style={{ marginTop: "72px" }}>
                <Col xs={12} lg={2} lgOffset={1}>
                    <motion.div>
                        <p style={styles.SectionHeader}>Design Decisions</p>
                    </motion.div>
                </Col>
                <Col xs={12} lg={6} lgOffset={2}>
                    <motion.div>
                        <p style={styles.SectionTitle}>Representing teams within a single view</p>
                        <p style={styles.HeroDescription}>One of the main objectives of the design was to provide our user with a high level view of all the team related information and objects they could interact with.</p>
                        <p style={styles.HeroDescription}>This was to ensure that the information users were looking at was easily scannable and would help them make incident management decisions efficiently.</p>
                        <p style={styles.HeroDescription}>To this end, I made a few explorations that would hopefully aid us in achieving this goal.</p>

                        <motion.div
                            style={styles.PhotoContainerProject}
                        >
                            <img style={styles.PhotoContainerProject} src={SeperatedTableDesign} alt="SeperatedTableDesign" />

                            <p style={styles.ImageCaption}>Solution A - two separate data tables</p>
                        </motion.div>


                        <p style={styles.HeroDescription}>In this exploration, the explicit copy above the data tables show what the user is viewing at any given time.</p>
                        <p style={styles.HeroDescription}>However, I hypothesized that two different data tables on a single page increases cognitive load for the user, denying them the ability to quickly focus on one particular view between parent and sub team. This was something we definitely wanted to test.</p>

                        <motion.div
                            style={styles.PhotoContainerProject}
                        >
                            <img style={styles.PhotoContainerProject} src={TagBasedSearch} alt="TagBasedSearch" />

                            <p style={styles.ImageCaption}>Solution B - tag based search and filter</p>
                        </motion.div>

                        <p style={styles.HeroDescription}>Solution B presents a tag based filter solution, with search functions for subteams, object name, and role.</p>
                        <p style={styles.HeroDescription}>However, there were some important questions that needed to be answered.</p>
                        <p style={styles.HeroDescription}>Would the information be contextualized in the right way?</p>
                        <p style={styles.HeroDescription}>There was a definite tradeoff b/w granular info and control vs. speed, which was the main intent for simplifying the workflow anyways.</p>
                        <p style={styles.HeroDescription}>After touching base with engineering, a constraint was that we had never implemented tag based search and filter before, and engineers had other high priority tickets at the time, which led us to iterate on other concepts.</p>

                        <motion.div
                            style={styles.PhotoContainerProject}
                        >
                            <img style={styles.PhotoContainerProject} src={SegmentedControlDesign} alt="SegmentedControlDesign" />

                            <p style={styles.ImageCaption}>Solution C - segmented controls</p>
                        </motion.div>

                        <p style={styles.HeroDescription}>Solution C, however, leveraged segmented controls as a design pattern, where users could clearly change context in terms of viewing parent team based data or subteam based data.</p>
                        <p style={styles.HeroDescription}>Segmented controls allow for quick and easy context switching, where a user is able to focus on a particular view, either the parent team or the subteams within.</p>
                        <p style={styles.HeroDescription}>To implement, I audited best practice usage of segmented controls in enterprise apps such as Salesforce and ServiceNow to inform our design.</p>
                        <p style={styles.HeroDescription}>Keeping this in mind, we advanced with solution C as the best course of action for the team view, with the goal of testing out solution A for the case where the administrator would want to view both parent teams and sub-teams.</p>

                        <p style={styles.SectionTitle}>Surfacing data in an intuitive way</p>

                        <p style={styles.HeroDescription}>Another important design decision was to surface data to our users in a way that was scalable, and the goal was to help users to clearly see the team and sub team hierarchies involved.</p>

                        <motion.div
                            style={styles.PhotoContainerProject}
                        >
                            <img style={styles.PhotoContainerTable} src={DataTableA} alt="DataTableA" />

                            <p style={styles.ImageCaption}>Solution A - Data table with team role and subteam combined</p>
                        </motion.div>

                        <p style={styles.HeroDescription}>While this solution works well for a singular user on a team when they might have a defined role. However, we must consider the case where a user could be part of a subteam within a subteam (nested subteams). Would this cause information overload?</p>
                        <p style={styles.HeroDescription}>The main question here was whether this would scale for such a case, something that we’d only be able to test with real users.</p>


                        <motion.div
                            style={styles.PhotoContainerProject}
                        >
                            <img style={styles.PhotoContainerTable} src={DataTableB} alt="DataTableB" />

                            <p style={styles.ImageCaption}>Solution B - Data table with team role in a separate column</p>
                        </motion.div>

                        <p style={styles.HeroDescription}>Essentially, this solution aimed to abstract the two key data points(roles and subteams), with the design intent that it'd provide clear separation between a subteam and specific roles a user might have.</p>
                        <p style={styles.HeroDescription}>It made logical sense from an administrator standpoint, as it is even more explicit what user or object is in a subteam, guiding the user in their intent to select and view based on their subteam-based role, while being more scalable/consistent for other objects such as Escalation policies and integrations.</p>
                        <motion.div
                            style={styles.PhotoContainerProject}
                        >
                            <img style={styles.PhotoContainerTable} src={DataTableC} alt="DataTableC" />

                            <p style={styles.ImageCaption}>Solution C - data table with base and subteam roles in a single columns</p>
                        </motion.div>


                        <p style={styles.HeroDescription}>This allowed administrators to view users’ base roles along with team roles, but did this scale well with other objects (Escalation Policies, integrations) relevant to subteams?</p>
                        <p style={styles.HeroDescription}>After looking back at user interview notes, we realized that administrators weren’t looking at base roles when making decisions, but rather team specific roles to triage incident management.</p>
                        <p style={styles.HeroDescriptionBold}>We went with Solution B for our data tables as it was highly scalable, surfacing both object data and heirarchies in a way that is unobtrusive and explicit to the user, especially when tested against Solution A.</p>
                    </motion.div>
                </Col>
            </Row>

            <Row start="lg" style={{ marginTop: "72px" }}>
                <Col xs={12} lg={2} lgOffset={1}>
                    <motion.div>
                        <p style={styles.SectionHeader}>Initial concept</p>
                        <p style={styles.SectionBlurb}>PRO  <a style={{ padding: 4 }}>&#x2705;</a></p>
                        <p style={styles.HeroDescription}>Segmented control allowed for quick context switching</p>
                        <p style={styles.SectionBlurb}>CONS <a style={{ padding: 4 }}>&#x1F6D1;</a></p>
                        <p style={styles.HeroDescription}>Used data table in subteams view where subteam and role are combined, detracting from a focused experience</p>
                        <p style={styles.HeroDescription}>Potential information overload eg: one user on multiple sub teams with different roles</p>


                    </motion.div>
                </Col>
                <Col xs={12} lg={6} lgOffset={2}>
                    <motion.div>

                        <motion.div
                            style={styles.PhotoContainerProject}
                        >
                            <video style={styles.PhotoContainerProject} src={InitialPrototype} controls></video>                        </motion.div>
                    </motion.div>
                </Col>
            </Row>

            <Row start="lg" style={{ marginTop: "72px" }}>
                <Col xs={12} lg={2} lgOffset={1}>
                    <motion.div>
                        <p style={styles.SectionHeader}>User Testing</p>
                        <p style={styles.SectionBlurb}>Initial testing unraveled a few problems with our design</p>

                    </motion.div>
                </Col>
                <Col xs={12} lg={6} lgOffset={2}>
                    <motion.div>
                        <p style={styles.HeroDescription}>The two data tables in the “sub teams and parent team” view cause cognitive overload with admins not being able to focus on two tables at the same time, detracting from our user’s intent to make decisions quickly.</p>
                        <p style={styles.HeroDescription}>Lack of search bar in all sections left users confused, consistency issue which can potentially hinder experiences.</p>
                        <p style={styles.HeroDescription}>Information felt cluttered within data tables - “I’m looking at too much here, how am I supposed to parse through this?”- SRE at client company (Cisco).</p>
                        <p style={styles.HeroDescriptionBold}>How might we better surface information in data tables for the "Subteam and Parent team view?</p>
                    </motion.div>
                </Col>
            </Row>

            <Row start="lg" style={{ marginTop: "72px" }}>
                <Col xs={12} lg={2} lgOffset={1}>
                    <motion.div>
                        <p style={styles.SectionHeader}>Iteration 2</p>
                        <p style={styles.SectionBlurb}>PROS  <a style={{ padding: 4 }}>&#x2705;</a></p>
                        <p style={styles.HeroDescription}>Segmented control allowed for quick context switching</p>
                        <p style={styles.HeroDescription}>Combined parent team and subteam view is clear and scalable</p>
                        <p style={styles.HeroDescription}>Consistent search function across all segmented views</p>

                        <p style={styles.SectionBlurb}>CON <a style={{ padding: 4 }}>&#x1F6D1;</a></p>
                        <p style={styles.HeroDescription}>Visual differentiation of segmented controls not as accessible</p>


                    </motion.div>
                </Col>
                <Col xs={12} lg={6} lgOffset={2}>
                    <motion.div>

                        <motion.div
                            style={styles.PhotoContainerProject}
                        >
                            <video style={styles.PhotoContainerProject} src={V2Prototype} controls></video>                       
                             </motion.div>
                    </motion.div>
                </Col>
            </Row>

            <Row start="lg" style={{ marginTop: "72px" }}>
                <Col xs={12} lg={2} lgOffset={1}>
                    <motion.div>
                        <p style={styles.SectionHeader}>Final Design</p>
                        <p style={styles.SectionBlurb}>I iterated on the segmented control to be more accessible and minimize dissonance, and worked with our design systems lead Olivier to implement</p>
                        <p style={styles.HeroDescriptionBold}>Entry point - Teams configuration page</p>
                    </motion.div>
                </Col>
                <Col xs={12} lg={6} lgOffset={2}>
                    <motion.div>
                        <motion.div
                            style={styles.PhotoContainerProject}
                        >
                           <video style={styles.PhotoContainerProject} src={FinalPrototype} controls></video>
                        </motion.div>


                    </motion.div>
                </Col>
            </Row>


            <Row start="lg" style={{ marginTop: "72px" }}>
                <Col xs={12} lg={2} lgOffset={1}>
                    <motion.div>
                        <p style={styles.SectionHeader}>Impact</p>
                    </motion.div>
                </Col>
                <Col xs={12} lg={6} lgOffset={2}>
                    <motion.div>
                        <p style={styles.HeroDescription}>13% increase of feature usage against previous design over multivariate testing during beta launch.</p>
                        <p style={styles.HeroDescription}>Qualitative surveys validated improvement from previous design, and pitched design to our Product Manager, adopted by the team for product roadmap.</p>
                        <p style={styles.HeroDescriptionBold}>Update: Feature shipped in October</p>
                    </motion.div>
                </Col>
            </Row>

            <Row start="lg" style={{ marginTop: "72px" }}>
                <Col xs={12} lg={2} lgOffset={1}>
                    <motion.div>
                        <p style={styles.SectionHeader}>Lessons and next steps</p>
                    </motion.div>
                </Col>
                <Col xs={12} lg={6} lgOffset={2}>
                    <motion.div>
                        <p style={styles.HeroDescription}>Metrics are your ally in designing the right solution.</p>
                        <p style={styles.HeroDescription}>Design for the happy path first, before diving deeper into edge case scenarios. Especially coming in from a CS background, this was a struggle at first, but I realized that we need to be generative first before honing in and making corner case considerations.</p>
                        <p style={styles.HeroDescription}>Communication and collaboration is key. Aim to bring in stakeholders early on in the design process.</p>
                        <p style={styles.HeroDescriptionBold}>Potential next steps</p>
                        <p style={styles.HeroDescription}>How might we help existing users who left the feature for an alternate workflow come back? After discussing this with our UX design lead, a potential experiment proposed was to perhaps surface team based configuration on the home page, with the initial hypothesis that it would drive interaction.</p>


                    </motion.div>
                </Col>
            </Row>



            <Row start="lg" style={{ marginTop: 32 }}>
                <Col xs={12} lg={5} lgOffset={1}>

                    <motion.div style={{ paddingBottom: 64 }}>
                        <motion.a initial="initial" whileHover="hover" variants={InlineLink} style={styles.SectionBlurb} href="/" onClick={()=> gaEventTracker('Home page footer')}>Go back home</motion.a>

                    </motion.div>

                </Col>
            </Row>
        </Grid>



    )
}

export default PagerDuty;